import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  DirectionsRenderer,
  DirectionsService,
  DistanceMatrixService,
  Polyline,
  Polygon,
} from "@react-google-maps/api";
import { toast } from "react-toastify";
import { mapContainerStyle, routeColors, toastOptions } from "./utils";

import {
  fetchChildSvcs,
  fetchEduSvcs,
  fetchFoodSvcs,
  fetchSocSvcs,
  fetchJobs,
  setTransitRedux,
} from "./store/actions";
import EmployerList from "./components/EmployerList";
import Modal from "./components/Modal";
import Dropdown from "./components/Dropdown";
import Checkbox from "./components/Checkbox";
import JobCart from "./components/JobCart";
import AddressModal from "./components/AddressModal";
import Burger from "./components/Burger";
import Toast from "./components/Toast";
import UserIcon from "./components/UserIcon";
import UserInfo from "./components/UserInfo";
import wyandotteOutlinePath from "./utils/wyandotteOutline.json";

const polyline = require("@mapbox/polyline");

const libraries = ["places", "directions", "drawing"];
const center = {
  lat: 39.1,
  lng: -94.76,
};
const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

const tRoutes = [
  { route: 101, name: "State Ave" },
  { route: 102, name: "Central Ave - UGT" },
  { route: 103, name: "3rd - Fairfax" },
  { route: 104, name: "Argentine" },
  { route: 106, name: "Quindaro-Amazon" },
  { route: 107, name: "7th Street/Parallel" },
  { route: 113, name: "Leavenworth Road" },
  { route: 115, name: "Kansas Avenue" },
  { route: 116, name: "West Parallel" },
  { route: 118, name: "18th Street" },
  { route: 402, name: "Johnson-Quivira" },
  { route: 595, name: "Gardner-OP Express" },
];

const App = () => {
  const dispatch = useDispatch();
  const {
    eduSvcs,
    foodSvcs,
    childSvcs,
    socSvcs,
    jobs,
    initialTransit,
  } = useSelector((state) => state);

  const [employers, setEmployers] = useState([]);
  const [homeMarker, setHomeMarker] = useState();
  const [homeAddress, setHomeAddress] = useState("");
  const [selectedBiz, setSelectedBiz] = useState(null);
  const [selectedSvc, setSelectedSvc] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [businessAddress, setBusinessAddress] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [directions, setDirections] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [business, setBusiness] = useState();
  const [findTimes, setFindTimes] = useState(false);
  const [visible, setVisible] = useState({ all: true });
  const [times, setTimes] = useState({});
  const [jobCart, setJobCart] = useState([]);
  const [transit, setTransit] = useState(initialTransit);
  const [renderRoutes, setRenderRoutes] = useState(false);
  const [visibleList, setVisibleList] = useState(false);
  const [open, setOpen] = useState(true);
  const [transitType, setTransitType] = useState("DRIVING");
  const [error, setError] = useState("");

  useEffect(() => {
    dispatch(fetchEduSvcs());
    dispatch(fetchFoodSvcs());
    dispatch(fetchSocSvcs());
    dispatch(fetchChildSvcs());
    dispatch(fetchJobs());
    dispatch(setTransitRedux());
    setTransit(initialTransit);
  }, []);

  useEffect(() => {
    if (visible.all) {
      setTransit(initialTransit);
    } else if (!visible.all) {
      setTransit([]);
    }
  }, [visible.publictransit, visible.all]);

  useEffect(() => {
    let arr = [];
    jobCart.forEach((j, i) => {
      if (j.valid) {
        if (times[i].status === "ZERO_RESULTS") {
          j["commuteLength"] = {
            duration: {
              text: "No public transit available to employer",
              value: 100000000000,
            },
          };
        } else {
          j["commuteLength"] = times[i];
        }
      } else {
        j["commuteLength"] = {
          duration: {
            text:
              "Address not validated. Please contact employer to get address.",
            value: 1000000000000,
          },
        };
      }
      arr.push(j);
    });
    arr.sort(
      (a, b) => a.commuteLength.duration.value - b.commuteLength.duration.value
    );
    setJobCart(arr);
  }, [times]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  if (loadError) return "Error loading map";
  if (!isLoaded) return "Loading map";

  const selectJob = (job) => {
    if (jobCart.length >= 25) {
      toast.error(
        "You can only have up to 25 jobs in your list.",
        toastOptions
      );
    } else if (jobCart.includes(job)) {
      toast.error(
        "This job is already in your list. Please select another.",
        toastOptions
      );
    } else if (!job.valid) {
      toast.error(
        "This employer may not have an accurate address. A transportation route will not be calculated for this employer."
      );
      setJobCart([...jobCart, job]);
      setAddresses([...addresses, job.address]);
    } else {
      setJobCart([...jobCart, job]);
      setAddresses([...addresses, job.address]);
    }
  };

  const removeJob = (job) => {
    setJobCart(jobCart.filter((j) => j.id !== job.id));
    setDirections(
      directions.filter((d) => d.request.destination.query !== job.address)
    );
    setAddresses(addresses.filter((a) => a !== job.address));
  };

  const distanceRes = (res) => {
    setFindTimes(false);
    setTimes(res.rows[0].elements);
    // setTimes(res);
  };

  const routeRes = (res) => {
    if (res.status === "OK") {
      // console.log(polyline.decode(res.routes[0].overview_polyline));
      setDirections([...directions, res]);
      setIsFetching(false);
    }
  };

  const filterMarker = (e) => {
    setVisible({ ...visible, [e.target.value]: e.target.checked });
    console.log(visible);
  };

  const filterTransit = (e) => {
    setVisible({ ...visible, publictransit: e.target.checked, all: true });
  };

  const filterRoute = (e) => {
    if (visible.all === false) {
      if (e.target.checked) {
        let addedTransit = initialTransit.filter(
          (t) => t.name === e.target.value
        );
        setTransit(transit.concat(addedTransit));
      } else if (!e.target.checked) {
        setTransit(transit.filter((t) => t.name !== e.target.value));
      }
    }
  };

  const handleAllRoutes = (e) => {
    setVisible({ ...visible, [e.target.value]: e.target.checked });
  };

  return (
    <div className="App">
      <header>
        <Burger open={open} setOpen={setOpen} />
        <div className="header-copy">
          <h1>
            <span id="header-bold">WYCO</span>WORKS
          </h1>
          <h4>Connecting you to resources and jobs in the Dotte</h4>
        </div>
        <UserIcon />
      </header>
      <div className="wrapper">
        <section id="menu" className={!open ? "menu" : "menu menu_open"}>
          <Dropdown
            dropdownLabel="Available Jobs"
            setVisible={setVisible}
            visible={visible}
          >
            <EmployerList
              employers={employers}
              setSelectedBiz={setSelectedBiz}
              setBusiness={setBusiness}
              setViewModal={setViewModal}
              homeMarker={homeMarker}
              setEmployers={setEmployers}
              jobs={jobs}
            />
          </Dropdown>
          <Dropdown dropdownLabel="Show Resources">
            <Checkbox onChange={filterMarker} value="employers">
              Employers
            </Checkbox>
            <Checkbox onChange={filterMarker} value="foodbanks">
              Food Banks
            </Checkbox>
            <Checkbox onChange={filterMarker} value="childcare">
              Child Care
            </Checkbox>
            <Checkbox onChange={filterMarker} value="educationservices">
              Education/Training Services
            </Checkbox>
            <Checkbox onChange={filterMarker} value="socialservices">
              Social Services
            </Checkbox>
            <Checkbox onChange={filterTransit} value="publictransit">
              Public Transit
            </Checkbox>
          </Dropdown>
          <Dropdown dropdownLabel="User Info">
            <UserInfo
              homeAddress={homeAddress}
              setHomeAddress={setHomeAddress}
              setTransitType={setTransitType}
              toast={toast}
              toastOptions={toastOptions}
              setHomeMarker={setHomeMarker}
            />
          </Dropdown>
          <Dropdown dropdownLabel={`View Your Jobs (${jobCart.length})`}>
            <JobCart
              jobCart={jobCart}
              removeJob={removeJob}
              homeAddress={homeAddress}
              visibleList={visibleList}
              setFindTimes={setFindTimes}
              setJobCart={setJobCart}
              setIsFetching={setIsFetching}
              setDirections={setDirections}
              setRenderRoutes={setRenderRoutes}
              setTimes={setTimes}
              setAddresses={setAddresses}
              setVisibleList={setVisibleList}
              setSelectedBiz={setSelectedBiz}
              setBusiness={setBusiness}
              setViewModal={setViewModal}
              setTransitType={setTransitType}
              error={error}
              setError={setError}
            />
          </Dropdown>
          {visible.publictransit && (
            <Dropdown dropdownLabel="Transit">
              {tRoutes.map((tr, i) => {
                return (
                  <Checkbox
                    key={i}
                    value={tr.route}
                    onChange={filterRoute}
                    style={{ color: routeColors[tr.route.toString()] }}
                  >
                    {`${tr.route.toString()} (${tr.name})`}
                  </Checkbox>
                );
              })}
              <Checkbox
                value="all"
                onChange={handleAllRoutes}
                defaultChecked="true"
              >
                {" "}
                All{" "}
              </Checkbox>
            </Dropdown>
          )}
        </section>
        <section className="map">
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={12}
            center={center}
            options={options}
          >
            <Polygon
              paths={wyandotteOutlinePath}
              options={{
                fillOpacity: 0,
                strokeColor: "#4D975B",
                strokeWeight: 2,
              }}
            />
            {visible.publictransit &&
              transit.map((t, i) => {
                return (
                  <Polyline
                    key={i}
                    path={t.coords}
                    options={{
                      strokeColor: routeColors[t.name],
                      fillColor: routeColors[t.name],
                      opacity: 0.5,
                    }}
                  ></Polyline>
                );
              })}
            {/* {visibleList &&
              jobCart.map((j) => {
                return (
                  <Marker
                    key={j.id}
                    position={{ lat: j.location.lat, lng: j.location.lng }}
                    onClick={() => {
                      setSelectedBiz(j);
                      setBusiness(j);
                    }}
                    zIndex={1000}
                  ></Marker>
                );
              })} */}
            {!visibleList &&
              employers
                .filter((e) => e.valid)
                .map((e) => {
                  return (
                    <Marker
                      key={e._id}
                      position={{ lat: e.location.lat, lng: e.location.lng }}
                      onClick={() => {
                        setSelectedBiz(e);
                        setBusiness(e);
                      }}
                      visible={visible.employers ? true : false}
                    ></Marker>
                  );
                })}
            {childSvcs.map((c) => {
              return (
                <Marker
                  key={c._id}
                  position={{ lat: c.location.lat, lng: c.location.lng }}
                  onClick={() => {
                    setSelectedSvc(c);
                  }}
                  icon={{
                    url: "./icons/children.png",
                    scaledSize: new window.google.maps.Size(30, 30),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(15, 15),
                  }}
                  visible={visible.childcare ? true : false}
                ></Marker>
              );
            })}
            {eduSvcs.map((e) => {
              return (
                <Marker
                  key={e._id}
                  position={
                    e.location && { lat: e.location.lat, lng: e.location.lng }
                  }
                  onClick={() => {
                    setSelectedSvc(e);
                  }}
                  icon={{
                    url: "./icons/training.png",
                    scaledSize: new window.google.maps.Size(30, 30),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(15, 15),
                  }}
                  visible={visible.educationservices ? true : false}
                ></Marker>
              );
            })}
            {foodSvcs.map((f) => {
              return (
                <Marker
                  key={f._id}
                  position={{ lat: f.location.lat, lng: f.location.lng }}
                  onClick={() => {
                    setSelectedSvc(f);
                  }}
                  icon={{
                    url: "./icons/bread.png",
                    scaledSize: new window.google.maps.Size(30, 30),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(15, 15),
                  }}
                  visible={visible.foodbanks ? true : false}
                ></Marker>
              );
            })}
            {socSvcs.map((s) => {
              return (
                <Marker
                  key={s._id}
                  position={{ lat: +s.location.lat, lng: +s.location.lng }}
                  onClick={() => {
                    setSelectedSvc(s);
                  }}
                  icon={{
                    url: "./icons/handshake.png",
                    scaledSize: new window.google.maps.Size(40, 25),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(15, 15),
                  }}
                  visible={visible.socialservices ? true : false}
                ></Marker>
              );
            })}
            {homeMarker && (
              <Marker
                key={homeMarker}
                icon={{
                  url: "./icons/house-with-chimney.png",
                  scaledSize: new window.google.maps.Size(30, 30),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(15, 15),
                }}
                position={{ lat: homeMarker.lat, lng: homeMarker.lng }}
              ></Marker>
            )}
            {selectedBiz && selectedBiz.valid ? (
              <InfoWindow
                key={selectedBiz.id}
                position={{
                  lat: selectedBiz.location.lat,
                  lng: selectedBiz.location.lng,
                }}
                onCloseClick={() => {
                  setSelectedBiz(null);
                }}
              >
                <div>
                  <h3>{selectedBiz.name}</h3>
                  <p>{selectedBiz.address}</p>
                  {selectedBiz.website && <p>{selectedBiz.website}</p>}
                  <p>{selectedBiz.phone}</p>
                  <p>
                    Interested in this job? Click{" "}
                    <span
                      className="click"
                      onClick={() => {
                        setViewModal(true);
                      }}
                    >
                      here
                    </span>{" "}
                    to find out more.
                  </p>
                </div>
              </InfoWindow>
            ) : null}
            {selectedSvc ? (
              <InfoWindow
                key={selectedSvc.id}
                position={{
                  lat: +selectedSvc.location.lat,
                  lng: +selectedSvc.location.lng,
                }}
                onCloseClick={() => {
                  setSelectedSvc(null);
                }}
              >
                <div>
                  <h3>{selectedSvc.name}</h3>
                  <p>{selectedSvc.addr}</p>
                  <p>{selectedSvc.phone}</p>
                  <p>{selectedSvc.desc}</p>
                  <a
                    href={selectedSvc.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedSvc.url}
                  </a>
                </div>
              </InfoWindow>
            ) : null}
            {homeAddress && businessAddress && isFetching && (
              <DirectionsService
                options={{
                  destination: businessAddress,
                  origin: homeAddress,
                  travelMode: transitType,
                }}
                callback={routeRes}
              ></DirectionsService>
            )}
            {directions !== null &&
              renderRoutes &&
              directions.map((d) => {
                return (
                  <DirectionsRenderer
                    options={{ directions: d }}
                  ></DirectionsRenderer>
                );
              })}
            {findTimes && (
              <DistanceMatrixService
                options={{
                  origins: [homeAddress],
                  destinations: addresses,
                  travelMode: transitType,
                }}
                callback={distanceRes}
              ></DistanceMatrixService>
            )}
          </GoogleMap>
          <div className="modal_wrapper">
            {viewModal && (
              <Modal
                className="btn"
                selectedBiz={business}
                setViewModal={setViewModal}
                setSelectedBiz={setSelectedBiz}
                setBusinessAddress={setBusinessAddress}
                setIsFetching={setIsFetching}
                setDirections={setDirections}
                setFindTimes={setFindTimes}
                selectJob={selectJob}
                visibleList={visibleList}
              />
            )}
          </div>
        </section>
      </div>
      <AddressModal
        homeAddress={homeAddress}
        setHomeMarker={setHomeMarker}
        setHomeAddress={setHomeAddress}
        toast={toast}
        toastOptions={toastOptions}
        setTransitType={setTransitType}
      />
      <Toast error={error} />
    </div>
  );
};

export default App;

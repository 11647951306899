import React, { useState } from "react";

const Dropdown = (props) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
    // Able to display job markers if open, but would have to remove the checkbox for employers in Show Resources tab, which will effect mobile functionality
    // if (props.dropdownLabel === "Available Jobs") {
    //   if (!props.visible.employers) {
    //     props.setVisible({...props.visible, employers: true})
    //   } else {
    //     props.setVisible({...props.visible, employers: false})
    //   }
    // }
  };

  return (
    <div className={`dropdown ${open ? "dropdown--open" : ""}`}>
      <button className="dropdown__label" onClick={handleClick}>
        <span>{props.dropdownLabel}</span>
        <span className="dropdown__arrow" />
      </button>
      <div className={`dropdown__selection ${!open ? "hidden" : ""}`}>
        {props.children}
      </div>
    </div>
  );
};

export default Dropdown;

import React, { useState, useEffect } from "react";
import { includesAddress } from "../utils";

const Employer = ({ employer, setBusiness, setSelectedBiz, setViewModal }) => {
  const [isShown, setIsShown] = useState(false);
  const [isNotValid, setIsNotValid] = useState(false);

  useEffect(() => {
    if (includesAddress(employer.address)) {
      setIsNotValid(true);
    }
  }, []);

  return (
    <div
      className="job"
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      onClick={() => {
        setSelectedBiz(employer);
        setBusiness(employer);
        setViewModal(true);
      }}
    >
      <div className="job_wrapper">
        <h4 className="job_bizname">{employer.name}</h4>
        {isNotValid && (
          <img className="job_invalid" src="../icons/invalid-address.png" alt="Job not on map" />
        )}
      </div>
    </div>
  );
};

export default Employer;

export const transitRoutes = [
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "101-166",
      route_short_name: "101",
      route_long_name: "State Ave",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.83095, 39.13011],
        [-94.83055, 39.13011],
        [-94.83019, 39.13012],
        [-94.83018, 39.12983],
        [-94.8302, 39.12939],
        [-94.83022, 39.12925],
        [-94.83022, 39.12864],
        [-94.83021, 39.12724],
        [-94.83018, 39.1269],
        [-94.83004, 39.12648],
        [-94.82982, 39.12601],
        [-94.8297, 39.12583],
        [-94.82951, 39.1256],
        [-94.82932, 39.12541],
        [-94.82885, 39.125],
        [-94.82822, 39.12465],
        [-94.82793, 39.12453],
        [-94.82713, 39.1243],
        [-94.82534, 39.12391],
        [-94.825, 39.12381],
        [-94.82449, 39.12362],
        [-94.82385, 39.12329],
        [-94.82323, 39.1229],
        [-94.82285, 39.1226],
        [-94.822, 39.12175],
        [-94.82141, 39.12113],
        [-94.82119, 39.12086],
        [-94.82098, 39.12067],
        [-94.82069, 39.12049],
        [-94.82039, 39.12034],
        [-94.81963, 39.12003],
        [-94.81937, 39.11989],
        [-94.81917, 39.11974],
        [-94.819, 39.11957],
        [-94.81884, 39.11937],
        [-94.81873, 39.11909],
        [-94.81871, 39.11883],
        [-94.81872, 39.11872],
        [-94.81878, 39.11847],
        [-94.81908, 39.11762],
        [-94.81934, 39.11697],
        [-94.81933, 39.11688],
        [-94.81947, 39.11664],
        [-94.81874, 39.11643],
        [-94.81792, 39.11627],
        [-94.81743, 39.11625],
        [-94.81621, 39.11623],
        [-94.81459, 39.11626],
        [-94.81177, 39.11628],
        [-94.80899, 39.11627],
        [-94.80755, 39.1163],
        [-94.7993, 39.11633],
        [-94.77877, 39.11627],
        [-94.77251, 39.11629],
        [-94.76658, 39.11628],
        [-94.76397, 39.1163],
        [-94.76262, 39.11629],
        [-94.75752, 39.11634],
        [-94.75191, 39.1165],
        [-94.74869, 39.11658],
        [-94.747, 39.1166],
        [-94.74699, 39.1169],
        [-94.74706, 39.11923],
        [-94.74709, 39.12009],
        [-94.74712, 39.12021],
        [-94.74717, 39.12031],
        [-94.74728, 39.1204],
        [-94.74742, 39.1205],
        [-94.74756, 39.12055],
        [-94.74772, 39.12059],
        [-94.74851, 39.12059],
        [-94.74853, 39.12085],
        [-94.74917, 39.12089],
        [-94.74917, 39.12055],
        [-94.74867, 39.12056],
        [-94.74862, 39.12058],
        [-94.74796, 39.1206],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "101-166",
      route_short_name: "101",
      route_long_name: "State Ave",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.74699, 39.1169],
        [-94.74699, 39.11674],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "101-166",
      route_short_name: "101",
      route_long_name: "State Ave",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.747, 39.1166],
        [-94.74439, 39.1166],
        [-94.73721, 39.11671],
        [-94.73236, 39.11669],
        [-94.72909, 39.11665],
        [-94.727, 39.11667],
        [-94.72448, 39.11667],
        [-94.71996, 39.11663],
        [-94.71373, 39.11662],
        [-94.70711, 39.11658],
        [-94.70283, 39.11658],
        [-94.70052, 39.11655],
        [-94.69287, 39.11653],
        [-94.69121, 39.11652],
        [-94.68652, 39.11656],
        [-94.68653, 39.11424],
        [-94.68562, 39.11422],
        [-94.68569, 39.11306],
        [-94.68654, 39.11309],
        [-94.68653, 39.11424],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "101-166",
      route_short_name: "101",
      route_long_name: "State Ave",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.68652, 39.11564],
        [-94.68652, 39.11656],
        [-94.68403, 39.11656],
        [-94.68329, 39.11659],
        [-94.68166, 39.11662],
        [-94.6789, 39.11663],
        [-94.67598, 39.11669],
        [-94.67543, 39.11669],
        [-94.67367, 39.11664],
        [-94.67002, 39.11669],
        [-94.66928, 39.11672],
        [-94.66921, 39.11676],
        [-94.6669, 39.11675],
        [-94.65517, 39.11679],
        [-94.65459, 39.11676],
        [-94.65233, 39.11656],
        [-94.65211, 39.11619],
        [-94.65173, 39.11566],
        [-94.65168, 39.11557],
        [-94.65167, 39.11553],
        [-94.64934, 39.11553],
        [-94.64801, 39.11551],
        [-94.62884, 39.11554],
        [-94.6268, 39.11551],
        [-94.62648, 39.11552],
        [-94.62596, 39.11558],
        [-94.62545, 39.11553],
        [-94.62174, 39.11554],
        [-94.62167, 39.11551],
        [-94.62112, 39.1155],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "101-166",
      route_short_name: "101",
      route_long_name: "State Ave",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.62112, 39.1155],
        [-94.61932, 39.11549],
        [-94.61909, 39.11547],
        [-94.61892, 39.11541],
        [-94.61882, 39.11535],
        [-94.6188, 39.11531],
        [-94.61876, 39.11457],
        [-94.61854, 39.11456],
        [-94.61822, 39.11451],
        [-94.61806, 39.11447],
        [-94.61778, 39.11437],
        [-94.61772, 39.11429],
        [-94.61758, 39.11421],
        [-94.61742, 39.11409],
        [-94.61717, 39.11384],
        [-94.61711, 39.11374],
        [-94.61706, 39.11356],
        [-94.61706, 39.11338],
        [-94.6171, 39.11323],
        [-94.61714, 39.11314],
        [-94.61723, 39.11304],
        [-94.61786, 39.1123],
        [-94.61789, 39.1122],
        [-94.61786, 39.11211],
        [-94.61776, 39.11203],
        [-94.61766, 39.11198],
        [-94.61742, 39.1119],
        [-94.61675, 39.11156],
        [-94.61547, 39.11086],
        [-94.61449, 39.11039],
        [-94.61432, 39.11026],
        [-94.61415, 39.11004],
        [-94.60924, 39.10301],
        [-94.60906, 39.10273],
        [-94.60855, 39.10203],
        [-94.60778, 39.10083],
        [-94.60715, 39.09989],
        [-94.60692, 39.0996],
        [-94.60669, 39.09944],
        [-94.60649, 39.09938],
        [-94.60635, 39.09936],
        [-94.60618, 39.09935],
        [-94.60594, 39.09939],
        [-94.60505, 39.0997],
        [-94.60378, 39.10008],
        [-94.60227, 39.10061],
        [-94.60215, 39.1006],
        [-94.60188, 39.10065],
        [-94.59872, 39.10055],
        [-94.59831, 39.10051],
        [-94.59791, 39.10045],
        [-94.59767, 39.10043],
        [-94.59558, 39.10036],
        [-94.5941, 39.10029],
        [-94.59312, 39.10026],
        [-94.59308, 39.10026],
        [-94.59299, 39.1003],
        [-94.59166, 39.10025],
        [-94.59005, 39.10017],
        [-94.58629, 39.10005],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "101-166",
      route_short_name: "101",
      route_long_name: "State Ave",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.58629, 39.10005],
        [-94.58558, 39.10002],
        [-94.58562, 39.09874],
        [-94.58706, 39.0988],
        [-94.58854, 39.09879],
        [-94.58966, 39.09888],
        [-94.59009, 39.09889],
        [-94.59005, 39.10017],
        [-94.59166, 39.10025],
        [-94.59299, 39.1003],
        [-94.59308, 39.10034],
        [-94.59788, 39.10052],
        [-94.59839, 39.1006],
        [-94.59872, 39.10063],
        [-94.60096, 39.1007],
        [-94.60181, 39.10072],
        [-94.60196, 39.10072],
        [-94.60218, 39.10069],
        [-94.60227, 39.10061],
        [-94.60239, 39.10055],
        [-94.60378, 39.10008],
        [-94.60505, 39.0997],
        [-94.60594, 39.09939],
        [-94.60618, 39.09935],
        [-94.60635, 39.09936],
        [-94.60649, 39.09938],
        [-94.60669, 39.09944],
        [-94.60692, 39.0996],
        [-94.60715, 39.09989],
        [-94.60778, 39.10083],
        [-94.60855, 39.10203],
        [-94.60906, 39.10273],
        [-94.60924, 39.10301],
        [-94.61415, 39.11004],
        [-94.61432, 39.11026],
        [-94.61449, 39.11039],
        [-94.61547, 39.11086],
        [-94.61675, 39.11156],
        [-94.61747, 39.11192],
        [-94.6176, 39.11207],
        [-94.61763, 39.11217],
        [-94.61762, 39.11223],
        [-94.61756, 39.11239],
        [-94.61686, 39.11332],
        [-94.61681, 39.11348],
        [-94.61693, 39.11349],
        [-94.61706, 39.11356],
        [-94.61711, 39.11374],
        [-94.61717, 39.11384],
        [-94.61742, 39.11409],
        [-94.61758, 39.11421],
        [-94.61772, 39.11429],
        [-94.61778, 39.11437],
        [-94.61806, 39.11447],
        [-94.61822, 39.11451],
        [-94.61864, 39.11457],
        [-94.62139, 39.11457],
        [-94.62138, 39.1155],
        [-94.62167, 39.11551],
        [-94.62174, 39.11554],
        [-94.62545, 39.11553],
        [-94.62596, 39.11558],
        [-94.62648, 39.11552],
        [-94.6268, 39.11551],
        [-94.62884, 39.11554],
        [-94.64801, 39.11551],
        [-94.64934, 39.11553],
        [-94.65167, 39.11553],
        [-94.65168, 39.11557],
        [-94.65173, 39.11566],
        [-94.65211, 39.11619],
        [-94.65233, 39.11656],
        [-94.65459, 39.11676],
        [-94.65517, 39.11679],
        [-94.6669, 39.11675],
        [-94.66921, 39.11676],
        [-94.66928, 39.11679],
        [-94.67054, 39.1168],
        [-94.67425, 39.11675],
        [-94.67709, 39.11679],
        [-94.68165, 39.1167],
        [-94.68367, 39.11672],
        [-94.68652, 39.11668],
        [-94.68654, 39.11309],
        [-94.68569, 39.11306],
        [-94.68562, 39.11422],
        [-94.68653, 39.11424],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "101-166",
      route_short_name: "101",
      route_long_name: "State Ave",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.68652, 39.11564],
        [-94.68652, 39.11656],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "101-166",
      route_short_name: "101",
      route_long_name: "State Ave",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.68652, 39.11668],
        [-94.68988, 39.11664],
        [-94.69288, 39.11664],
        [-94.71161, 39.11674],
        [-94.71726, 39.11674],
        [-94.7368, 39.11683],
        [-94.73991, 39.11683],
        [-94.74699, 39.11674],
        [-94.74699, 39.1169],
        [-94.74706, 39.11923],
        [-94.74709, 39.12009],
        [-94.74712, 39.12021],
        [-94.74717, 39.12031],
        [-94.74728, 39.1204],
        [-94.74742, 39.1205],
        [-94.74756, 39.12055],
        [-94.74772, 39.12059],
        [-94.74851, 39.12059],
        [-94.74853, 39.12085],
        [-94.74917, 39.12089],
        [-94.74917, 39.12055],
        [-94.74867, 39.12056],
        [-94.74862, 39.12058],
        [-94.74796, 39.1206],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "101-166",
      route_short_name: "101",
      route_long_name: "State Ave",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.74699, 39.1169],
        [-94.74699, 39.11674],
        [-94.74853, 39.11671],
        [-94.75581, 39.11652],
        [-94.75887, 39.11648],
        [-94.76078, 39.11644],
        [-94.77571, 39.1164],
        [-94.7993, 39.11645],
        [-94.80453, 39.11643],
        [-94.80687, 39.11643],
        [-94.80917, 39.11639],
        [-94.81221, 39.1164],
        [-94.8132, 39.11638],
        [-94.81673, 39.11636],
        [-94.81748, 39.11638],
        [-94.81787, 39.11641],
        [-94.81821, 39.11646],
        [-94.81835, 39.11649],
        [-94.8188, 39.11668],
        [-94.81894, 39.1168],
        [-94.819, 39.1169],
        [-94.81903, 39.117],
        [-94.81904, 39.11711],
        [-94.81902, 39.1174],
        [-94.81884, 39.1178],
        [-94.81856, 39.11854],
        [-94.81853, 39.1187],
        [-94.81852, 39.11894],
        [-94.81855, 39.11918],
        [-94.81859, 39.11928],
        [-94.81864, 39.11938],
        [-94.81883, 39.11965],
        [-94.81905, 39.11986],
        [-94.81928, 39.12002],
        [-94.81957, 39.12017],
        [-94.82029, 39.12047],
        [-94.82058, 39.12062],
        [-94.82082, 39.12078],
        [-94.82104, 39.12096],
        [-94.82124, 39.12118],
        [-94.82265, 39.12263],
        [-94.82315, 39.123],
        [-94.82397, 39.12351],
        [-94.82443, 39.12373],
        [-94.82502, 39.12394],
        [-94.82532, 39.12402],
        [-94.8271, 39.12445],
        [-94.82751, 39.12456],
        [-94.82785, 39.12467],
        [-94.82848, 39.12497],
        [-94.82871, 39.12512],
        [-94.82896, 39.1253],
        [-94.82935, 39.12567],
        [-94.82953, 39.12589],
        [-94.82976, 39.12629],
        [-94.82993, 39.12671],
        [-94.82999, 39.12692],
        [-94.83001, 39.12708],
        [-94.83004, 39.12849],
        [-94.83022, 39.12849],
        [-94.83022, 39.12864],
        [-94.83155, 39.12864],
        [-94.83154, 39.12991],
        [-94.83152, 39.13005],
        [-94.83148, 39.13013],
        [-94.83141, 39.13022],
        [-94.83132, 39.13018],
        [-94.83109, 39.13011],
        [-94.83095, 39.13011],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "102k-89",
      route_short_name: "102",
      route_long_name: "Central Ave - UGT",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.68567, 39.11337],
        [-94.68569, 39.11306],
        [-94.68654, 39.11309],
        [-94.68652, 39.11656],
        [-94.68403, 39.11656],
        [-94.68329, 39.11659],
        [-94.68166, 39.11662],
        [-94.6789, 39.11663],
        [-94.67598, 39.11669],
        [-94.67543, 39.11669],
        [-94.67367, 39.11664],
        [-94.67002, 39.11669],
        [-94.66928, 39.11672],
        [-94.66921, 39.11676],
        [-94.6669, 39.11675],
        [-94.66151, 39.11677],
        [-94.6615, 39.11618],
        [-94.66152, 39.11553],
        [-94.66155, 39.11548],
        [-94.66157, 39.11531],
        [-94.6616, 39.11476],
        [-94.66159, 39.11459],
        [-94.66151, 39.1144],
        [-94.6614, 39.11425],
        [-94.6612, 39.11408],
        [-94.6607, 39.11381],
        [-94.66065, 39.1138],
        [-94.66017, 39.11349],
        [-94.65889, 39.11246],
        [-94.65876, 39.11232],
        [-94.65867, 39.11214],
        [-94.65862, 39.11195],
        [-94.65861, 39.11066],
        [-94.65858, 39.11036],
        [-94.65855, 39.11025],
        [-94.65843, 39.11004],
        [-94.65834, 39.10991],
        [-94.65779, 39.10931],
        [-94.65749, 39.10904],
        [-94.65726, 39.1089],
        [-94.65686, 39.10872],
        [-94.64943, 39.10598],
        [-94.64941, 39.10408],
        [-94.64942, 39.10227],
        [-94.6494, 39.10031],
        [-94.64941, 39.09984],
        [-94.64935, 39.09975],
        [-94.64934, 39.09865],
        [-94.64831, 39.09865],
        [-94.64823, 39.09847],
        [-94.64787, 39.09851],
        [-94.64792, 39.09956],
        [-94.64879, 39.09944],
        [-94.64928, 39.09943],
        [-94.64935, 39.09945],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "102k-89",
      route_short_name: "102",
      route_long_name: "Central Ave - UGT",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.64942, 39.10178],
        [-94.64942, 39.10227],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "102k-89",
      route_short_name: "102",
      route_long_name: "Central Ave - UGT",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.64942, 39.10544],
        [-94.64943, 39.10598],
        [-94.64593, 39.10466],
        [-94.64246, 39.10341],
        [-94.63776, 39.10163],
        [-94.63562, 39.10086],
        [-94.63514, 39.10071],
        [-94.63463, 39.1005],
        [-94.63428, 39.10041],
        [-94.63185, 39.10039],
        [-94.63154, 39.1004],
        [-94.63133, 39.10046],
        [-94.63097, 39.10064],
        [-94.62898, 39.10175],
        [-94.62828, 39.10216],
        [-94.62739, 39.10267],
        [-94.62719, 39.10274],
        [-94.62464, 39.10274],
        [-94.62339, 39.10272],
        [-94.6234, 39.1032],
        [-94.6234, 39.10414],
        [-94.62342, 39.10428],
        [-94.62351, 39.10453],
        [-94.62355, 39.1048],
        [-94.6236, 39.10496],
        [-94.62376, 39.10535],
        [-94.62387, 39.10556],
        [-94.62384, 39.10568],
        [-94.62385, 39.10727],
        [-94.62388, 39.10815],
        [-94.62386, 39.10989],
        [-94.62395, 39.11131],
        [-94.62396, 39.11205],
        [-94.62399, 39.11234],
        [-94.62402, 39.11238],
        [-94.62403, 39.11252],
        [-94.62405, 39.11263],
        [-94.62409, 39.11271],
        [-94.62439, 39.1127],
        [-94.62473, 39.11274],
        [-94.62608, 39.11274],
        [-94.62651, 39.11273],
        [-94.6268, 39.1127],
        [-94.62679, 39.11487],
        [-94.62637, 39.11517],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "102k-89",
      route_short_name: "102",
      route_long_name: "Central Ave - UGT",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.62637, 39.11517],
        [-94.62635, 39.11519],
        [-94.62638, 39.11553],
        [-94.62596, 39.11558],
        [-94.62556, 39.11553],
        [-94.62404, 39.11553],
        [-94.62407, 39.11456],
        [-94.62407, 39.11333],
        [-94.6241, 39.11313],
        [-94.62413, 39.11304],
        [-94.62418, 39.11297],
        [-94.62403, 39.11282],
        [-94.62398, 39.11272],
        [-94.62396, 39.11262],
        [-94.62396, 39.11238],
        [-94.62399, 39.11234],
        [-94.62396, 39.11205],
        [-94.62395, 39.11131],
        [-94.62386, 39.10989],
        [-94.62388, 39.10815],
        [-94.62385, 39.10727],
        [-94.62384, 39.10568],
        [-94.62387, 39.10556],
        [-94.62376, 39.10535],
        [-94.6236, 39.10496],
        [-94.62355, 39.1048],
        [-94.62351, 39.10453],
        [-94.62342, 39.10428],
        [-94.6234, 39.10414],
        [-94.6234, 39.1032],
        [-94.62339, 39.10272],
        [-94.62464, 39.10274],
        [-94.62719, 39.10274],
        [-94.62739, 39.10267],
        [-94.62828, 39.10216],
        [-94.62898, 39.10175],
        [-94.63097, 39.10064],
        [-94.63133, 39.10046],
        [-94.63154, 39.1004],
        [-94.63185, 39.10039],
        [-94.63418, 39.10041],
        [-94.63432, 39.10042],
        [-94.63463, 39.1005],
        [-94.63514, 39.10071],
        [-94.63562, 39.10086],
        [-94.63776, 39.10163],
        [-94.64126, 39.10295],
        [-94.64125, 39.10305],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "102k-89",
      route_short_name: "102",
      route_long_name: "Central Ave - UGT",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.64126, 39.10295],
        [-94.64361, 39.10383],
        [-94.64593, 39.10466],
        [-94.64943, 39.10598],
        [-94.64941, 39.10408],
        [-94.64942, 39.10227],
        [-94.6494, 39.10031],
        [-94.64941, 39.09984],
        [-94.64935, 39.09975],
        [-94.64934, 39.09865],
        [-94.64831, 39.09865],
        [-94.64823, 39.09847],
        [-94.64787, 39.09851],
        [-94.64792, 39.09956],
        [-94.64879, 39.09944],
        [-94.64928, 39.09943],
        [-94.64935, 39.09945],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "102k-89",
      route_short_name: "102",
      route_long_name: "Central Ave - UGT",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.64942, 39.10178],
        [-94.64942, 39.10227],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "102k-89",
      route_short_name: "102",
      route_long_name: "Central Ave - UGT",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.64942, 39.10544],
        [-94.64943, 39.10598],
        [-94.65686, 39.10872],
        [-94.65706, 39.1088],
        [-94.65744, 39.10899],
        [-94.65764, 39.10917],
        [-94.65834, 39.10991],
        [-94.65843, 39.11004],
        [-94.65855, 39.11025],
        [-94.65858, 39.11036],
        [-94.65861, 39.11066],
        [-94.65862, 39.11195],
        [-94.65867, 39.11214],
        [-94.65876, 39.11232],
        [-94.65889, 39.11246],
        [-94.66017, 39.11349],
        [-94.66116, 39.11416],
        [-94.66135, 39.11435],
        [-94.66146, 39.11451],
        [-94.66151, 39.11467],
        [-94.66148, 39.11548],
        [-94.66152, 39.11553],
        [-94.6615, 39.11618],
        [-94.66151, 39.11677],
        [-94.66742, 39.11675],
        [-94.66921, 39.11676],
        [-94.66928, 39.11679],
        [-94.67054, 39.1168],
        [-94.67425, 39.11675],
        [-94.67709, 39.11679],
        [-94.68165, 39.1167],
        [-94.68367, 39.11672],
        [-94.68652, 39.11668],
        [-94.68653, 39.11424],
        [-94.68562, 39.11422],
        [-94.68567, 39.11337],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "103k-89",
      route_short_name: "103",
      route_long_name: "3rd - Fairfax",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.58011, 39.11051],
        [-94.58215, 39.11009],
        [-94.58655, 39.10922],
        [-94.5883, 39.10888],
        [-94.5885, 39.10886],
        [-94.5885, 39.10876],
        [-94.58852, 39.10872],
        [-94.58853, 39.10868],
        [-94.58853, 39.10834],
        [-94.59066, 39.10841],
        [-94.5907, 39.10836],
        [-94.59076, 39.10813],
        [-94.59085, 39.10802],
        [-94.59184, 39.10711],
        [-94.59232, 39.10669],
        [-94.59272, 39.10626],
        [-94.59307, 39.10581],
        [-94.5933, 39.10549],
        [-94.59351, 39.10507],
        [-94.59397, 39.10434],
        [-94.59443, 39.10453],
        [-94.59475, 39.1046],
        [-94.59487, 39.10461],
        [-94.59501, 39.10459],
        [-94.59522, 39.10456],
        [-94.59546, 39.10449],
        [-94.59615, 39.10423],
        [-94.59701, 39.10394],
        [-94.59709, 39.10389],
        [-94.59725, 39.10387],
        [-94.5975, 39.10387],
        [-94.59913, 39.10391],
        [-94.60128, 39.10399],
        [-94.60156, 39.10406],
        [-94.60195, 39.10423],
        [-94.60223, 39.10428],
        [-94.60241, 39.1043],
        [-94.60714, 39.10442],
        [-94.6089, 39.10448],
        [-94.60977, 39.10454],
        [-94.61032, 39.10454],
        [-94.61415, 39.11004],
        [-94.61432, 39.11026],
        [-94.61449, 39.11039],
        [-94.61547, 39.11086],
        [-94.61675, 39.11156],
        [-94.61747, 39.11192],
        [-94.6176, 39.11207],
        [-94.61763, 39.11217],
        [-94.61762, 39.11223],
        [-94.61756, 39.11239],
        [-94.61686, 39.11332],
        [-94.61681, 39.11348],
        [-94.61693, 39.11349],
        [-94.61706, 39.11356],
        [-94.61711, 39.11374],
        [-94.61717, 39.11384],
        [-94.61742, 39.11409],
        [-94.61758, 39.11421],
        [-94.61772, 39.11429],
        [-94.61778, 39.11437],
        [-94.61806, 39.11447],
        [-94.61822, 39.11451],
        [-94.61864, 39.11457],
        [-94.62407, 39.11456],
        [-94.62407, 39.11361],
        [-94.6268, 39.11366],
        [-94.62679, 39.11487],
        [-94.62637, 39.11517],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "103k-89",
      route_short_name: "103",
      route_long_name: "3rd - Fairfax",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.62637, 39.11517],
        [-94.62635, 39.11519],
        [-94.62638, 39.11553],
        [-94.62596, 39.11558],
        [-94.62556, 39.11553],
        [-94.62404, 39.11553],
        [-94.62174, 39.11554],
        [-94.62167, 39.11557],
        [-94.62139, 39.11558],
        [-94.62138, 39.1186],
        [-94.6214, 39.11952],
        [-94.6214, 39.12141],
        [-94.62139, 39.12235],
        [-94.62141, 39.12299],
        [-94.62141, 39.12422],
        [-94.61913, 39.1242],
        [-94.61609, 39.12422],
        [-94.61609, 39.12721],
        [-94.61614, 39.13033],
        [-94.61929, 39.13031],
        [-94.62143, 39.13031],
        [-94.62161, 39.13032],
        [-94.62275, 39.13062],
        [-94.62499, 39.13128],
        [-94.62629, 39.13164],
        [-94.62641, 39.13174],
        [-94.62644, 39.13181],
        [-94.62641, 39.13201],
        [-94.62454, 39.13404],
        [-94.62427, 39.13439],
        [-94.62416, 39.13455],
        [-94.62239, 39.1385],
        [-94.62223, 39.13892],
        [-94.62216, 39.13917],
        [-94.62212, 39.1394],
        [-94.62209, 39.13967],
        [-94.62204, 39.14067],
        [-94.62203, 39.14144],
        [-94.62204, 39.14293],
        [-94.61273, 39.143],
        [-94.61182, 39.14296],
        [-94.61075, 39.14296],
        [-94.61068, 39.14656],
        [-94.61265, 39.14658],
        [-94.62041, 39.14656],
        [-94.62041, 39.14667],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "103k-89",
      route_short_name: "103",
      route_long_name: "3rd - Fairfax",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.62041, 39.14656],
        [-94.62739, 39.1466],
        [-94.62751, 39.14666],
        [-94.62761, 39.14666],
        [-94.62921, 39.14667],
        [-94.62928, 39.14666],
        [-94.62938, 39.14662],
        [-94.63145, 39.14665],
        [-94.63156, 39.14661],
        [-94.63168, 39.14655],
        [-94.63311, 39.14562],
        [-94.63361, 39.14532],
        [-94.63398, 39.14543],
        [-94.63467, 39.14585],
        [-94.63558, 39.14617],
        [-94.63692, 39.1466],
        [-94.63711, 39.14667],
        [-94.63784, 39.14701],
        [-94.63822, 39.14636],
        [-94.63832, 39.14642],
        [-94.63911, 39.1468],
        [-94.63959, 39.14701],
        [-94.63963, 39.1469],
        [-94.6397, 39.14666],
        [-94.63971, 39.14603],
        [-94.63975, 39.14559],
        [-94.63987, 39.14495],
        [-94.64001, 39.14439],
        [-94.64014, 39.14363],
        [-94.64013, 39.1435],
        [-94.64009, 39.14339],
        [-94.64, 39.14321],
        [-94.6398, 39.14301],
        [-94.63961, 39.1429],
        [-94.63946, 39.14287],
        [-94.63934, 39.14286],
        [-94.6392, 39.14287],
        [-94.63912, 39.14291],
        [-94.63892, 39.14308],
        [-94.63877, 39.14326],
        [-94.63872, 39.144],
        [-94.63884, 39.14443],
        [-94.63882, 39.14493],
        [-94.63874, 39.14524],
        [-94.63856, 39.14561],
        [-94.6382, 39.14625],
        [-94.6382, 39.14633],
        [-94.63822, 39.14636],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "104-166",
      route_short_name: "104",
      route_long_name: "Argentine",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.67488, 39.06729],
        [-94.67476, 39.06729],
        [-94.67476, 39.06785],
        [-94.67483, 39.07049],
        [-94.67483, 39.07232],
        [-94.67485, 39.07317],
        [-94.6772, 39.07314],
        [-94.67718, 39.07431],
        [-94.67719, 39.07511],
        [-94.67029, 39.07509],
        [-94.66588, 39.07513],
        [-94.65755, 39.07512],
        [-94.65732, 39.07508],
        [-94.65716, 39.07501],
        [-94.65705, 39.07489],
        [-94.657, 39.07478],
        [-94.65698, 39.07457],
        [-94.65698, 39.07392],
        [-94.65702, 39.07313],
        [-94.65706, 39.07305],
        [-94.65685, 39.07295],
        [-94.65676, 39.07292],
        [-94.65648, 39.07289],
        [-94.65183, 39.07288],
        [-94.65179, 39.07196],
        [-94.6518, 39.07152],
        [-94.65083, 39.07153],
        [-94.64919, 39.0715],
        [-94.64144, 39.07145],
        [-94.64035, 39.07146],
        [-94.64029, 39.07148],
        [-94.64024, 39.07152],
        [-94.64024, 39.07224],
        [-94.63982, 39.07495],
        [-94.63979, 39.07525],
        [-94.63979, 39.07538],
        [-94.63987, 39.07613],
        [-94.63995, 39.08419],
        [-94.63527, 39.08422],
        [-94.63535, 39.09202],
        [-94.63541, 39.09591],
        [-94.6354, 39.09626],
        [-94.63544, 39.10037],
        [-94.63548, 39.10225],
        [-94.63547, 39.10367],
        [-94.63548, 39.10455],
        [-94.63548, 39.10679],
        [-94.63551, 39.10891],
        [-94.63548, 39.10982],
        [-94.6354, 39.11079],
        [-94.63534, 39.1114],
        [-94.63528, 39.11172],
        [-94.63523, 39.11174],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "104-166",
      route_short_name: "104",
      route_long_name: "Argentine",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.63528, 39.11172],
        [-94.63515, 39.1124],
        [-94.63512, 39.11305],
        [-94.63513, 39.11455],
        [-94.63515, 39.11553],
        [-94.62961, 39.11553],
        [-94.62962, 39.11459],
        [-94.62679, 39.11459],
        [-94.62679, 39.11487],
        [-94.62637, 39.11517],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "104-166",
      route_short_name: "104",
      route_long_name: "Argentine",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.62637, 39.11517],
        [-94.62635, 39.11519],
        [-94.62638, 39.11553],
        [-94.62596, 39.11558],
        [-94.62556, 39.11553],
        [-94.62404, 39.11553],
        [-94.62407, 39.11456],
        [-94.62407, 39.11361],
        [-94.6268, 39.11366],
        [-94.62679, 39.11459],
        [-94.62962, 39.11459],
        [-94.62961, 39.11553],
        [-94.63515, 39.11553],
        [-94.63511, 39.11362],
        [-94.63513, 39.11257],
        [-94.63518, 39.11223],
        [-94.63534, 39.1114],
        [-94.6354, 39.11079],
        [-94.63548, 39.10982],
        [-94.63551, 39.10891],
        [-94.63548, 39.10679],
        [-94.63548, 39.10455],
        [-94.63547, 39.10367],
        [-94.63548, 39.10225],
        [-94.63544, 39.10037],
        [-94.6354, 39.09626],
        [-94.63541, 39.09591],
        [-94.63535, 39.09202],
        [-94.63529, 39.08484],
        [-94.63929, 39.08273],
        [-94.63993, 39.08241],
        [-94.63987, 39.07613],
        [-94.63979, 39.07538],
        [-94.63979, 39.07525],
        [-94.63982, 39.07495],
        [-94.64024, 39.07224],
        [-94.64025, 39.07182],
        [-94.64023, 39.0716],
        [-94.64024, 39.07152],
        [-94.64026, 39.0715],
        [-94.64035, 39.07146],
        [-94.64144, 39.07145],
        [-94.64919, 39.0715],
        [-94.65083, 39.07153],
        [-94.6518, 39.07152],
        [-94.65179, 39.07196],
        [-94.65183, 39.07288],
        [-94.65648, 39.07289],
        [-94.65676, 39.07292],
        [-94.65685, 39.07295],
        [-94.65706, 39.07305],
        [-94.65702, 39.07313],
        [-94.65698, 39.07392],
        [-94.65698, 39.07457],
        [-94.657, 39.07478],
        [-94.65705, 39.07489],
        [-94.65716, 39.07501],
        [-94.65732, 39.07508],
        [-94.65755, 39.07512],
        [-94.66588, 39.07513],
        [-94.67029, 39.07509],
        [-94.67719, 39.07511],
        [-94.67718, 39.07431],
        [-94.6772, 39.07314],
        [-94.67485, 39.07317],
        [-94.67483, 39.07232],
        [-94.67483, 39.07049],
        [-94.67476, 39.06785],
        [-94.67476, 39.06729],
        [-94.67517, 39.06729],
        [-94.67519, 39.06749],
        [-94.67598, 39.06749],
        [-94.67599, 39.06729],
        [-94.67488, 39.06729],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "106-166",
      route_short_name: "106",
      route_long_name: "Quindaro-Amazon",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.73848, 39.09995],
        [-94.73939, 39.09997],
        [-94.7394, 39.10033],
        [-94.73939, 39.10064],
        [-94.73936, 39.10097],
        [-94.73932, 39.10112],
        [-94.73924, 39.10127],
        [-94.73904, 39.10158],
        [-94.73872, 39.10145],
        [-94.73842, 39.10139],
        [-94.73809, 39.10138],
        [-94.73765, 39.10143],
        [-94.7375, 39.10148],
        [-94.73721, 39.1016],
        [-94.73633, 39.10211],
        [-94.73627, 39.10219],
        [-94.73614, 39.10224],
        [-94.73603, 39.10226],
        [-94.73588, 39.10226],
        [-94.73514, 39.10225],
        [-94.73526, 39.10195],
        [-94.73538, 39.10175],
        [-94.73548, 39.10164],
        [-94.73555, 39.10159],
        [-94.73565, 39.10154],
        [-94.73584, 39.1015],
        [-94.73601, 39.1015],
        [-94.73613, 39.10154],
        [-94.73653, 39.10174],
        [-94.73726, 39.10238],
        [-94.73749, 39.10263],
        [-94.73782, 39.10304],
        [-94.73808, 39.10341],
        [-94.73819, 39.10361],
        [-94.7384, 39.10403],
        [-94.73863, 39.1046],
        [-94.73906, 39.10591],
        [-94.73926, 39.10645],
        [-94.73985, 39.10824],
        [-94.73993, 39.10895],
        [-94.73993, 39.1091],
        [-94.73989, 39.10928],
        [-94.7398, 39.10943],
        [-94.73967, 39.10954],
        [-94.7395, 39.10963],
        [-94.7393, 39.10968],
        [-94.7391, 39.10967],
        [-94.73897, 39.10965],
        [-94.73873, 39.10956],
        [-94.73834, 39.10932],
        [-94.73809, 39.10904],
        [-94.73699, 39.10745],
        [-94.73683, 39.10715],
        [-94.73672, 39.10682],
        [-94.73651, 39.10558],
        [-94.73645, 39.10538],
        [-94.73634, 39.10523],
        [-94.73619, 39.10511],
        [-94.73601, 39.10502],
        [-94.7358, 39.10498],
        [-94.73559, 39.10498],
        [-94.73538, 39.10502],
        [-94.73526, 39.10507],
        [-94.73474, 39.10538],
        [-94.73433, 39.10559],
        [-94.73412, 39.10568],
        [-94.73369, 39.10582],
        [-94.73324, 39.1059],
        [-94.73263, 39.10594],
        [-94.73211, 39.10601],
        [-94.73026, 39.10596],
        [-94.73012, 39.10597],
        [-94.72722, 39.10592],
        [-94.72469, 39.10582],
        [-94.72243, 39.10568],
        [-94.71475, 39.10512],
        [-94.7131, 39.10504],
        [-94.71258, 39.10503],
        [-94.71213, 39.10504],
        [-94.70932, 39.10523],
        [-94.70495, 39.10571],
        [-94.70237, 39.10595],
        [-94.70117, 39.106],
        [-94.6999, 39.106],
        [-94.69823, 39.10594],
        [-94.69691, 39.10582],
        [-94.69271, 39.10534],
        [-94.69118, 39.10522],
        [-94.68964, 39.10517],
        [-94.68783, 39.10517],
        [-94.68707, 39.10515],
        [-94.68515, 39.10514],
        [-94.68444, 39.10513],
        [-94.68394, 39.10509],
        [-94.68346, 39.10512],
        [-94.68305, 39.10509],
        [-94.68217, 39.10507],
        [-94.68172, 39.1051],
        [-94.68149, 39.10513],
        [-94.68127, 39.10519],
        [-94.68084, 39.10532],
        [-94.68064, 39.1054],
        [-94.68033, 39.10555],
        [-94.68007, 39.10575],
        [-94.67987, 39.10596],
        [-94.67969, 39.10624],
        [-94.67954, 39.10654],
        [-94.67946, 39.10686],
        [-94.67943, 39.10702],
        [-94.67944, 39.10735],
        [-94.6795, 39.10766],
        [-94.67962, 39.10794],
        [-94.6797, 39.10806],
        [-94.68008, 39.10858],
        [-94.68132, 39.10961],
        [-94.68161, 39.10991],
        [-94.68181, 39.11017],
        [-94.68195, 39.11039],
        [-94.68209, 39.11064],
        [-94.68223, 39.11098],
        [-94.68232, 39.11127],
        [-94.68237, 39.11149],
        [-94.6824, 39.11189],
        [-94.68236, 39.11234],
        [-94.68226, 39.11299],
        [-94.68215, 39.11328],
        [-94.68196, 39.11407],
        [-94.6819, 39.11423],
        [-94.68174, 39.11454],
        [-94.68151, 39.11483],
        [-94.68123, 39.11509],
        [-94.68071, 39.11548],
        [-94.68051, 39.11567],
        [-94.68044, 39.11577],
        [-94.68036, 39.11592],
        [-94.6803, 39.11618],
        [-94.68028, 39.11655],
        [-94.68029, 39.11662],
        [-94.68032, 39.11668],
        [-94.68036, 39.11673],
        [-94.68165, 39.1167],
        [-94.68367, 39.11672],
        [-94.68652, 39.11668],
        [-94.68653, 39.11424],
        [-94.68562, 39.11422],
        [-94.68569, 39.11306],
        [-94.68654, 39.11309],
        [-94.68652, 39.11515],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "106-166",
      route_short_name: "106",
      route_long_name: "Quindaro-Amazon",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.68652, 39.116],
        [-94.68652, 39.11656],
        [-94.6842, 39.11656],
        [-94.68377, 39.1165],
        [-94.68357, 39.11644],
        [-94.68339, 39.11633],
        [-94.68326, 39.1162],
        [-94.68312, 39.116],
        [-94.68285, 39.11553],
        [-94.68269, 39.11519],
        [-94.68258, 39.11489],
        [-94.6825, 39.11446],
        [-94.68247, 39.11408],
        [-94.68248, 39.1133],
        [-94.6826, 39.11248],
        [-94.68264, 39.11198],
        [-94.68263, 39.11169],
        [-94.6826, 39.1114],
        [-94.68256, 39.1112],
        [-94.68249, 39.11091],
        [-94.68241, 39.11069],
        [-94.6823, 39.11045],
        [-94.68211, 39.11012],
        [-94.68124, 39.10897],
        [-94.68101, 39.10859],
        [-94.68081, 39.10816],
        [-94.68069, 39.1078],
        [-94.68063, 39.10749],
        [-94.68058, 39.10704],
        [-94.68058, 39.10662],
        [-94.6805, 39.10627],
        [-94.68047, 39.10587],
        [-94.68042, 39.1055],
        [-94.68037, 39.10536],
        [-94.68026, 39.10518],
        [-94.68013, 39.10501],
        [-94.67995, 39.10482],
        [-94.67973, 39.10465],
        [-94.67951, 39.10452],
        [-94.67913, 39.10437],
        [-94.67619, 39.1035],
        [-94.67575, 39.10329],
        [-94.67543, 39.10316],
        [-94.67509, 39.10301],
        [-94.67435, 39.10263],
        [-94.67312, 39.10199],
        [-94.67247, 39.10161],
        [-94.67182, 39.10129],
        [-94.67137, 39.10104],
        [-94.67098, 39.10084],
        [-94.66993, 39.10023],
        [-94.66912, 39.09983],
        [-94.66826, 39.09944],
        [-94.66744, 39.09913],
        [-94.66651, 39.09882],
        [-94.66607, 39.09869],
        [-94.66501, 39.0984],
        [-94.66439, 39.09827],
        [-94.66024, 39.09727],
        [-94.65914, 39.09706],
        [-94.65808, 39.09692],
        [-94.65701, 39.09682],
        [-94.65593, 39.09678],
        [-94.65481, 39.09675],
        [-94.65337, 39.09669],
        [-94.65277, 39.09664],
        [-94.6523, 39.0966],
        [-94.65139, 39.09647],
        [-94.65094, 39.09639],
        [-94.65009, 39.0962],
        [-94.64858, 39.0958],
        [-94.64771, 39.09549],
        [-94.64705, 39.09523],
        [-94.64621, 39.09486],
        [-94.64384, 39.09359],
        [-94.64301, 39.09319],
        [-94.64232, 39.09295],
        [-94.64207, 39.09289],
        [-94.64166, 39.0928],
        [-94.64123, 39.09273],
        [-94.64081, 39.09267],
        [-94.64015, 39.0926],
        [-94.63792, 39.09228],
        [-94.63635, 39.09211],
        [-94.63475, 39.09195],
        [-94.63417, 39.09182],
        [-94.63245, 39.09161],
        [-94.63153, 39.09153],
        [-94.63071, 39.09151],
        [-94.63, 39.09157],
        [-94.6297, 39.09161],
        [-94.62915, 39.09171],
        [-94.62846, 39.0919],
        [-94.62798, 39.09207],
        [-94.62759, 39.09224],
        [-94.62725, 39.0924],
        [-94.62675, 39.09272],
        [-94.62643, 39.09295],
        [-94.62614, 39.09319],
        [-94.62579, 39.09354],
        [-94.62545, 39.09397],
        [-94.62519, 39.09436],
        [-94.62468, 39.0952],
        [-94.62192, 39.10045],
        [-94.62179, 39.10075],
        [-94.62161, 39.10137],
        [-94.62157, 39.10171],
        [-94.6215, 39.10459],
        [-94.62139, 39.10644],
        [-94.62129, 39.10698],
        [-94.62108, 39.10757],
        [-94.62072, 39.10825],
        [-94.61953, 39.10999],
        [-94.61913, 39.11062],
        [-94.61873, 39.11126],
        [-94.61771, 39.11307],
        [-94.6176, 39.11323],
        [-94.61742, 39.11343],
        [-94.61721, 39.11358],
        [-94.61704, 39.11366],
        [-94.61673, 39.11374],
        [-94.6166, 39.11375],
        [-94.61628, 39.11374],
        [-94.61603, 39.1137],
        [-94.61525, 39.11332],
        [-94.61365, 39.11234],
        [-94.61064, 39.11017],
        [-94.61024, 39.1099],
        [-94.60968, 39.10959],
        [-94.60943, 39.10948],
        [-94.60889, 39.10927],
        [-94.60823, 39.10904],
        [-94.60388, 39.1076],
        [-94.60346, 39.10747],
        [-94.60283, 39.1073],
        [-94.60225, 39.1072],
        [-94.60151, 39.10714],
        [-94.59674, 39.10698],
        [-94.59582, 39.10681],
        [-94.59536, 39.10668],
        [-94.59488, 39.10649],
        [-94.59457, 39.10632],
        [-94.59439, 39.1062],
        [-94.59409, 39.10596],
        [-94.5939, 39.10576],
        [-94.59375, 39.10556],
        [-94.59362, 39.10529],
        [-94.59353, 39.10504],
        [-94.59348, 39.1048],
        [-94.59347, 39.10462],
        [-94.59352, 39.10393],
        [-94.59382, 39.10278],
        [-94.59398, 39.10226],
        [-94.59413, 39.10197],
        [-94.5942, 39.10177],
        [-94.5943, 39.10137],
        [-94.59433, 39.10096],
        [-94.59431, 39.10068],
        [-94.59426, 39.10038],
        [-94.59308, 39.10034],
        [-94.59299, 39.1003],
        [-94.59166, 39.10025],
        [-94.59005, 39.10017],
        [-94.58629, 39.10005],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "106-166",
      route_short_name: "106",
      route_long_name: "Quindaro-Amazon",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.68652, 39.11564],
        [-94.68652, 39.116],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "106-166",
      route_short_name: "106",
      route_long_name: "Quindaro-Amazon",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.6842, 39.11656],
        [-94.68166, 39.11662],
        [-94.6789, 39.11663],
        [-94.67598, 39.11669],
        [-94.67543, 39.11669],
        [-94.67382, 39.11664],
        [-94.67322, 39.11665],
        [-94.67325, 39.11721],
        [-94.67325, 39.11806],
        [-94.67332, 39.11816],
        [-94.67332, 39.11819],
        [-94.67332, 39.12037],
        [-94.6733, 39.12335],
        [-94.67332, 39.12374],
        [-94.67339, 39.12393],
        [-94.67346, 39.12404],
        [-94.67361, 39.1242],
        [-94.6737, 39.12426],
        [-94.67392, 39.12438],
        [-94.67414, 39.12446],
        [-94.67495, 39.12472],
        [-94.67489, 39.12489],
        [-94.6745, 39.12621],
        [-94.67446, 39.12632],
        [-94.67418, 39.12681],
        [-94.67403, 39.12712],
        [-94.67376, 39.12792],
        [-94.67365, 39.12832],
        [-94.66887, 39.1283],
        [-94.66876, 39.13356],
        [-94.66871, 39.13434],
        [-94.66868, 39.13731],
        [-94.66859, 39.1422],
        [-94.66856, 39.14293],
        [-94.66846, 39.14293],
        [-94.66845, 39.1435],
        [-94.66847, 39.14446],
        [-94.66843, 39.14456],
        [-94.65958, 39.14452],
        [-94.65915, 39.14451],
        [-94.65912, 39.14369],
        [-94.65912, 39.14006],
        [-94.65564, 39.14009],
        [-94.65544, 39.14008],
        [-94.65529, 39.14005],
        [-94.6551, 39.13995],
        [-94.65484, 39.1397],
        [-94.65465, 39.13958],
        [-94.65431, 39.13947],
        [-94.65387, 39.13935],
        [-94.65127, 39.13869],
        [-94.65044, 39.13851],
        [-94.64822, 39.13796],
        [-94.64636, 39.13749],
        [-94.64301, 39.1367],
        [-94.64193, 39.13646],
        [-94.64079, 39.13616],
        [-94.63916, 39.13576],
        [-94.63869, 39.13563],
        [-94.63603, 39.13498],
        [-94.63501, 39.13474],
        [-94.63115, 39.13377],
        [-94.63093, 39.1337],
        [-94.63066, 39.13356],
        [-94.62907, 39.13254],
        [-94.62884, 39.13242],
        [-94.62852, 39.13229],
        [-94.627, 39.13184],
        [-94.62693, 39.13177],
        [-94.62683, 39.13147],
        [-94.62684, 39.12927],
        [-94.62681, 39.12546],
        [-94.62683, 39.12517],
        [-94.62683, 39.12371],
        [-94.62681, 39.12197],
        [-94.6268, 39.11752],
        [-94.62682, 39.11653],
        [-94.62679, 39.11459],
        [-94.6268, 39.11366],
        [-94.62407, 39.11361],
        [-94.62407, 39.11456],
        [-94.62404, 39.11553],
        [-94.62174, 39.11554],
        [-94.62167, 39.11557],
        [-94.62139, 39.11558],
        [-94.62138, 39.11656],
        [-94.61911, 39.11655],
        [-94.61901, 39.11656],
        [-94.61885, 39.1166],
        [-94.6188, 39.11664],
        [-94.61875, 39.11672],
        [-94.61873, 39.1168],
        [-94.61873, 39.11703],
        [-94.61875, 39.11819],
        [-94.61874, 39.11848],
        [-94.61853, 39.11847],
        [-94.61836, 39.11844],
        [-94.61812, 39.11836],
        [-94.61789, 39.11824],
        [-94.61769, 39.11809],
        [-94.6176, 39.11799],
        [-94.61754, 39.1179],
        [-94.61747, 39.11776],
        [-94.61745, 39.11756],
        [-94.61745, 39.11739],
        [-94.61762, 39.11617],
        [-94.61764, 39.11569],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "106-166",
      route_short_name: "106",
      route_long_name: "Quindaro-Amazon",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.61764, 39.11569],
        [-94.61762, 39.11563],
        [-94.61759, 39.11559],
        [-94.61756, 39.11556],
        [-94.61746, 39.11553],
        [-94.61669, 39.11553],
        [-94.61607, 39.11556],
        [-94.61608, 39.1155],
        [-94.61612, 39.11543],
        [-94.61613, 39.11539],
        [-94.61618, 39.11474],
        [-94.61626, 39.11456],
        [-94.61632, 39.11447],
        [-94.61648, 39.11432],
        [-94.61668, 39.11419],
        [-94.61728, 39.11396],
        [-94.61717, 39.11384],
        [-94.61709, 39.11369],
        [-94.61705, 39.1135],
        [-94.61707, 39.11332],
        [-94.61714, 39.11314],
        [-94.61786, 39.1123],
        [-94.61789, 39.1122],
        [-94.61786, 39.11211],
        [-94.61776, 39.11203],
        [-94.61766, 39.11198],
        [-94.61742, 39.1119],
        [-94.61675, 39.11156],
        [-94.61547, 39.11086],
        [-94.61449, 39.11039],
        [-94.61432, 39.11026],
        [-94.61415, 39.11004],
        [-94.60924, 39.10301],
        [-94.60906, 39.10273],
        [-94.60855, 39.10203],
        [-94.60778, 39.10083],
        [-94.60715, 39.09989],
        [-94.60692, 39.0996],
        [-94.60679, 39.0995],
        [-94.60663, 39.09942],
        [-94.60643, 39.09936],
        [-94.60618, 39.09935],
        [-94.60594, 39.09939],
        [-94.60505, 39.0997],
        [-94.60378, 39.10008],
        [-94.60227, 39.10061],
        [-94.60215, 39.1006],
        [-94.60188, 39.10065],
        [-94.59872, 39.10055],
        [-94.59831, 39.10051],
        [-94.59791, 39.10045],
        [-94.59767, 39.10043],
        [-94.59558, 39.10036],
        [-94.5941, 39.10029],
        [-94.59312, 39.10026],
        [-94.59308, 39.10026],
        [-94.59299, 39.1003],
        [-94.59227, 39.10027],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "106-166",
      route_short_name: "106",
      route_long_name: "Quindaro-Amazon",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.59005, 39.10017],
        [-94.5886, 39.10012],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "106-166",
      route_short_name: "106",
      route_long_name: "Quindaro-Amazon",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.59431, 39.10068],
        [-94.59428, 39.10048],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "106-166",
      route_short_name: "106",
      route_long_name: "Quindaro-Amazon",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.58629, 39.10005],
        [-94.58558, 39.10002],
        [-94.58553, 39.1009],
        [-94.59, 39.10106],
        [-94.59073, 39.10106],
        [-94.59113, 39.10109],
        [-94.59118, 39.10023],
        [-94.59299, 39.1003],
        [-94.59308, 39.10034],
        [-94.59346, 39.10035],
        [-94.59369, 39.10094],
        [-94.59375, 39.10115],
        [-94.59382, 39.10149],
        [-94.59382, 39.10184],
        [-94.59375, 39.10219],
        [-94.59364, 39.10255],
        [-94.59361, 39.10274],
        [-94.59323, 39.1037],
        [-94.59315, 39.10426],
        [-94.59316, 39.1046],
        [-94.5932, 39.10488],
        [-94.59332, 39.10519],
        [-94.59343, 39.10538],
        [-94.59352, 39.10552],
        [-94.5937, 39.10573],
        [-94.59391, 39.10594],
        [-94.59476, 39.10666],
        [-94.5952, 39.10697],
        [-94.59552, 39.10714],
        [-94.59585, 39.10726],
        [-94.59619, 39.10733],
        [-94.59638, 39.10735],
        [-94.59689, 39.10736],
        [-94.59814, 39.10727],
        [-94.59936, 39.10726],
        [-94.59985, 39.10722],
        [-94.60158, 39.10728],
        [-94.60214, 39.10734],
        [-94.60269, 39.10743],
        [-94.60318, 39.10754],
        [-94.60359, 39.10766],
        [-94.6083, 39.10922],
        [-94.60871, 39.10936],
        [-94.60929, 39.1096],
        [-94.60986, 39.1099],
        [-94.61036, 39.11023],
        [-94.61264, 39.11204],
        [-94.613, 39.1123],
        [-94.61374, 39.11275],
        [-94.61474, 39.11334],
        [-94.6156, 39.1138],
        [-94.61585, 39.1139],
        [-94.61603, 39.11395],
        [-94.61626, 39.11398],
        [-94.61653, 39.11397],
        [-94.6169, 39.11388],
        [-94.6172, 39.11376],
        [-94.61734, 39.11369],
        [-94.6176, 39.11351],
        [-94.61782, 39.1133],
        [-94.61801, 39.11303],
        [-94.61883, 39.11145],
        [-94.6192, 39.11083],
        [-94.61959, 39.1103],
        [-94.62077, 39.10895],
        [-94.62096, 39.10871],
        [-94.62116, 39.1084],
        [-94.6213, 39.10814],
        [-94.62148, 39.10772],
        [-94.62154, 39.10751],
        [-94.62164, 39.10693],
        [-94.62168, 39.105],
        [-94.6217, 39.10325],
        [-94.62175, 39.10283],
        [-94.62177, 39.10255],
        [-94.62175, 39.10168],
        [-94.62181, 39.10126],
        [-94.62191, 39.10086],
        [-94.62201, 39.1006],
        [-94.62252, 39.0996],
        [-94.62279, 39.0991],
        [-94.62322, 39.09825],
        [-94.62344, 39.09789],
        [-94.6243, 39.09629],
        [-94.62482, 39.09548],
        [-94.62506, 39.0952],
        [-94.62534, 39.09491],
        [-94.62617, 39.09419],
        [-94.62648, 39.09384],
        [-94.62662, 39.09372],
        [-94.6274, 39.09308],
        [-94.62783, 39.09275],
        [-94.6281, 39.0926],
        [-94.62883, 39.09226],
        [-94.62928, 39.0921],
        [-94.62981, 39.09197],
        [-94.63057, 39.09185],
        [-94.63094, 39.09183],
        [-94.63154, 39.09184],
        [-94.63251, 39.09193],
        [-94.63361, 39.092],
        [-94.63459, 39.09209],
        [-94.63665, 39.09225],
        [-94.63747, 39.09234],
        [-94.63869, 39.0925],
        [-94.63956, 39.09263],
        [-94.64136, 39.09296],
        [-94.64193, 39.0931],
        [-94.6425, 39.09328],
        [-94.64284, 39.0934],
        [-94.64337, 39.09363],
        [-94.644, 39.09395],
        [-94.64575, 39.09487],
        [-94.64656, 39.09526],
        [-94.64701, 39.09546],
        [-94.64742, 39.09563],
        [-94.64816, 39.09589],
        [-94.64849, 39.09597],
        [-94.64879, 39.09607],
        [-94.64929, 39.09621],
        [-94.65092, 39.09658],
        [-94.65222, 39.09677],
        [-94.65373, 39.09689],
        [-94.65616, 39.09696],
        [-94.65737, 39.09703],
        [-94.6588, 39.09721],
        [-94.65945, 39.09733],
        [-94.6606, 39.09757],
        [-94.66163, 39.09781],
        [-94.66207, 39.098],
        [-94.66251, 39.09813],
        [-94.66589, 39.09901],
        [-94.66707, 39.0994],
        [-94.66829, 39.09988],
        [-94.66912, 39.10026],
        [-94.66987, 39.10066],
        [-94.67068, 39.10113],
        [-94.67167, 39.10173],
        [-94.67239, 39.10212],
        [-94.67323, 39.1027],
        [-94.67363, 39.10302],
        [-94.67472, 39.10404],
        [-94.67483, 39.10417],
        [-94.67612, 39.10532],
        [-94.67747, 39.10644],
        [-94.67882, 39.1076],
        [-94.67919, 39.10783],
        [-94.68123, 39.10953],
        [-94.6814, 39.10969],
        [-94.68161, 39.10991],
        [-94.68181, 39.11017],
        [-94.68195, 39.11039],
        [-94.68215, 39.11077],
        [-94.68229, 39.11117],
        [-94.68237, 39.11149],
        [-94.6824, 39.11176],
        [-94.68238, 39.11217],
        [-94.68226, 39.11299],
        [-94.68215, 39.11328],
        [-94.68196, 39.11407],
        [-94.6819, 39.11423],
        [-94.68174, 39.11454],
        [-94.68163, 39.11469],
        [-94.68138, 39.11496],
        [-94.68123, 39.11509],
        [-94.68071, 39.11548],
        [-94.68051, 39.11567],
        [-94.6804, 39.11585],
        [-94.68032, 39.11605],
        [-94.68029, 39.11625],
        [-94.68028, 39.11643],
        [-94.68029, 39.11662],
        [-94.68036, 39.11673],
        [-94.68165, 39.1167],
        [-94.68367, 39.11672],
        [-94.68652, 39.11668],
        [-94.68653, 39.11424],
        [-94.68562, 39.11422],
        [-94.68569, 39.11306],
        [-94.68654, 39.11309],
        [-94.68653, 39.11424],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "106-166",
      route_short_name: "106",
      route_long_name: "Quindaro-Amazon",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.68652, 39.11481],
        [-94.68652, 39.11515],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "106-166",
      route_short_name: "106",
      route_long_name: "Quindaro-Amazon",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.68652, 39.11656],
        [-94.6842, 39.11656],
        [-94.68383, 39.11651],
        [-94.68367, 39.11647],
        [-94.68345, 39.11637],
        [-94.68336, 39.1163],
        [-94.68326, 39.1162],
        [-94.68312, 39.116],
        [-94.68285, 39.11553],
        [-94.68269, 39.11519],
        [-94.68258, 39.11489],
        [-94.68252, 39.1146],
        [-94.68247, 39.11408],
        [-94.68248, 39.1133],
        [-94.6826, 39.11248],
        [-94.68264, 39.11198],
        [-94.68263, 39.11169],
        [-94.68256, 39.1112],
        [-94.68245, 39.11079],
        [-94.68221, 39.11028],
        [-94.68211, 39.11012],
        [-94.68186, 39.10977],
        [-94.68168, 39.10944],
        [-94.68141, 39.10906],
        [-94.68132, 39.10892],
        [-94.68123, 39.10872],
        [-94.68107, 39.10829],
        [-94.68103, 39.10805],
        [-94.68101, 39.10783],
        [-94.68102, 39.10741],
        [-94.68105, 39.10721],
        [-94.68118, 39.10674],
        [-94.68128, 39.10654],
        [-94.68144, 39.10634],
        [-94.68176, 39.10603],
        [-94.68207, 39.10583],
        [-94.68232, 39.10571],
        [-94.68304, 39.10552],
        [-94.68428, 39.10532],
        [-94.68661, 39.10531],
        [-94.6902, 39.10533],
        [-94.69116, 39.10537],
        [-94.69238, 39.10546],
        [-94.69642, 39.10593],
        [-94.69776, 39.10605],
        [-94.69863, 39.10611],
        [-94.69956, 39.10615],
        [-94.70096, 39.10615],
        [-94.70278, 39.10607],
        [-94.70372, 39.106],
        [-94.70751, 39.10557],
        [-94.7097, 39.10534],
        [-94.71217, 39.10519],
        [-94.71273, 39.10518],
        [-94.71469, 39.10528],
        [-94.72232, 39.10583],
        [-94.72547, 39.10601],
        [-94.72741, 39.10608],
        [-94.73345, 39.10618],
        [-94.73391, 39.10625],
        [-94.73428, 39.10627],
        [-94.73462, 39.10631],
        [-94.73524, 39.10643],
        [-94.7358, 39.1066],
        [-94.73601, 39.10668],
        [-94.7363, 39.10684],
        [-94.73658, 39.10707],
        [-94.73679, 39.10731],
        [-94.73713, 39.10781],
        [-94.73793, 39.10903],
        [-94.73832, 39.10942],
        [-94.73853, 39.10956],
        [-94.73875, 39.10968],
        [-94.73913, 39.10982],
        [-94.73953, 39.10989],
        [-94.7418, 39.10999],
        [-94.74199, 39.11003],
        [-94.7422, 39.11012],
        [-94.74239, 39.11031],
        [-94.74245, 39.11045],
        [-94.74245, 39.11063],
        [-94.74242, 39.11075],
        [-94.74231, 39.1109],
        [-94.74214, 39.11103],
        [-94.74201, 39.11108],
        [-94.74178, 39.11112],
        [-94.74152, 39.1111],
        [-94.74136, 39.11104],
        [-94.74121, 39.11096],
        [-94.7411, 39.11086],
        [-94.74102, 39.11075],
        [-94.74071, 39.11019],
        [-94.73992, 39.10788],
        [-94.7398, 39.10749],
        [-94.73926, 39.1059],
        [-94.73904, 39.10529],
        [-94.73874, 39.1044],
        [-94.73857, 39.104],
        [-94.73832, 39.10352],
        [-94.73794, 39.10292],
        [-94.73743, 39.10233],
        [-94.7368, 39.10175],
        [-94.7365, 39.10151],
        [-94.73579, 39.10105],
        [-94.73524, 39.10076],
        [-94.73461, 39.10048],
        [-94.73491, 39.1002],
        [-94.73517, 39.09988],
        [-94.73527, 39.09991],
        [-94.7355, 39.09994],
        [-94.73579, 39.09996],
        [-94.73848, 39.09995],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "106-166",
      route_short_name: "106",
      route_long_name: "Quindaro-Amazon",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.58558, 39.10002],
        [-94.58562, 39.09874],
        [-94.58706, 39.0988],
        [-94.58854, 39.09879],
        [-94.58966, 39.09888],
        [-94.59009, 39.09889],
        [-94.59005, 39.10017],
        [-94.59118, 39.10023],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "106-166",
      route_short_name: "106",
      route_long_name: "Quindaro-Amazon",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.59227, 39.10027],
        [-94.59299, 39.1003],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "106-166",
      route_short_name: "106",
      route_long_name: "Quindaro-Amazon",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.59346, 39.10035],
        [-94.59788, 39.10052],
        [-94.59839, 39.1006],
        [-94.59872, 39.10063],
        [-94.60145, 39.10072],
        [-94.60196, 39.10072],
        [-94.60218, 39.10069],
        [-94.60227, 39.10061],
        [-94.60239, 39.10055],
        [-94.60378, 39.10008],
        [-94.60505, 39.0997],
        [-94.60594, 39.09939],
        [-94.60618, 39.09935],
        [-94.60644, 39.09936],
        [-94.60669, 39.09944],
        [-94.60688, 39.09956],
        [-94.60696, 39.09965],
        [-94.60715, 39.09989],
        [-94.60778, 39.10083],
        [-94.60855, 39.10203],
        [-94.60906, 39.10273],
        [-94.60924, 39.10301],
        [-94.61415, 39.11004],
        [-94.61432, 39.11026],
        [-94.61449, 39.11039],
        [-94.61547, 39.11086],
        [-94.61675, 39.11156],
        [-94.61747, 39.11192],
        [-94.6176, 39.11207],
        [-94.61763, 39.11217],
        [-94.61762, 39.11223],
        [-94.61756, 39.11239],
        [-94.61624, 39.11411],
        [-94.61615, 39.11426],
        [-94.61606, 39.11448],
        [-94.61604, 39.11465],
        [-94.61603, 39.11541],
        [-94.61603, 39.11544],
        [-94.61608, 39.1155],
        [-94.61607, 39.11652],
        [-94.61609, 39.11755],
        [-94.6161, 39.11854],
        [-94.6164, 39.11857],
        [-94.61689, 39.11857],
        [-94.61748, 39.11848],
        [-94.61759, 39.11847],
        [-94.61782, 39.1185],
        [-94.61812, 39.11856],
        [-94.6183, 39.11856],
        [-94.61851, 39.1186],
        [-94.61873, 39.11861],
        [-94.61875, 39.11819],
        [-94.61873, 39.11703],
        [-94.61873, 39.1168],
        [-94.61875, 39.11672],
        [-94.6188, 39.11664],
        [-94.61885, 39.1166],
        [-94.61901, 39.11656],
        [-94.61911, 39.11655],
        [-94.62138, 39.11656],
        [-94.62139, 39.11558],
        [-94.62167, 39.11557],
        [-94.62174, 39.11554],
        [-94.62404, 39.11553],
        [-94.62407, 39.11456],
        [-94.62407, 39.11361],
        [-94.6268, 39.11366],
        [-94.62679, 39.11459],
        [-94.62682, 39.11653],
        [-94.6268, 39.11752],
        [-94.62681, 39.12197],
        [-94.62683, 39.12371],
        [-94.62683, 39.12517],
        [-94.62681, 39.12546],
        [-94.62683, 39.12668],
        [-94.62683, 39.13101],
        [-94.62682, 39.1315],
        [-94.62675, 39.13164],
        [-94.62668, 39.13175],
        [-94.62852, 39.13229],
        [-94.62884, 39.13242],
        [-94.62907, 39.13254],
        [-94.63066, 39.13356],
        [-94.63093, 39.1337],
        [-94.63115, 39.13377],
        [-94.63501, 39.13474],
        [-94.63603, 39.13498],
        [-94.63869, 39.13563],
        [-94.63916, 39.13576],
        [-94.64079, 39.13616],
        [-94.64193, 39.13646],
        [-94.64301, 39.1367],
        [-94.64636, 39.13749],
        [-94.64822, 39.13796],
        [-94.65044, 39.13851],
        [-94.65127, 39.13869],
        [-94.65387, 39.13935],
        [-94.65431, 39.13947],
        [-94.65465, 39.13958],
        [-94.65484, 39.1397],
        [-94.6551, 39.13995],
        [-94.65529, 39.14005],
        [-94.65544, 39.14008],
        [-94.65564, 39.14009],
        [-94.65912, 39.14006],
        [-94.65912, 39.14292],
        [-94.66856, 39.14293],
        [-94.66861, 39.14126],
        [-94.66868, 39.13731],
        [-94.66871, 39.13434],
        [-94.66876, 39.13356],
        [-94.66881, 39.13079],
        [-94.66884, 39.13022],
        [-94.66886, 39.12844],
        [-94.67363, 39.12845],
        [-94.67365, 39.12832],
        [-94.67371, 39.12807],
        [-94.67403, 39.12712],
        [-94.67418, 39.12681],
        [-94.67446, 39.12632],
        [-94.6745, 39.12621],
        [-94.67489, 39.12489],
        [-94.67495, 39.12472],
        [-94.67414, 39.12446],
        [-94.67392, 39.12438],
        [-94.6737, 39.12426],
        [-94.67361, 39.1242],
        [-94.67346, 39.12404],
        [-94.67339, 39.12393],
        [-94.67332, 39.12374],
        [-94.6733, 39.12335],
        [-94.67332, 39.12015],
        [-94.67331, 39.11879],
        [-94.67332, 39.11816],
        [-94.67338, 39.11806],
        [-94.67338, 39.11676],
        [-94.67425, 39.11675],
        [-94.67709, 39.11679],
        [-94.68036, 39.11673],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "106-166",
      route_short_name: "106",
      route_long_name: "Quindaro-Amazon",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.68652, 39.11656],
        [-94.68652, 39.116],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "106-166",
      route_short_name: "106",
      route_long_name: "Quindaro-Amazon",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.68654, 39.11309],
        [-94.68653, 39.11424],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "106-166",
      route_short_name: "106",
      route_long_name: "Quindaro-Amazon",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.68652, 39.11564],
        [-94.68652, 39.116],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "106-166",
      route_short_name: "106",
      route_long_name: "Quindaro-Amazon",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.73579, 39.10105],
        [-94.73562, 39.10096],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "106-166",
      route_short_name: "106",
      route_long_name: "Quindaro-Amazon",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.73848, 39.09995],
        [-94.73939, 39.09997],
        [-94.7394, 39.10033],
        [-94.73939, 39.10064],
        [-94.73936, 39.10097],
        [-94.73932, 39.10112],
        [-94.73924, 39.10127],
        [-94.73904, 39.10158],
        [-94.73872, 39.10145],
        [-94.73842, 39.10139],
        [-94.73809, 39.10138],
        [-94.73765, 39.10143],
        [-94.7375, 39.10148],
        [-94.73721, 39.1016],
        [-94.73633, 39.10211],
        [-94.73627, 39.10219],
        [-94.73614, 39.10224],
        [-94.73603, 39.10226],
        [-94.73588, 39.10226],
        [-94.73514, 39.10225],
        [-94.73526, 39.10195],
        [-94.73538, 39.10175],
        [-94.73548, 39.10164],
        [-94.73555, 39.10159],
        [-94.73565, 39.10154],
        [-94.73584, 39.1015],
        [-94.73601, 39.1015],
        [-94.73613, 39.10154],
        [-94.73653, 39.10174],
        [-94.73726, 39.10238],
        [-94.73749, 39.10263],
        [-94.73782, 39.10304],
        [-94.73808, 39.10341],
        [-94.73819, 39.10361],
        [-94.7384, 39.10403],
        [-94.73863, 39.1046],
        [-94.73906, 39.10591],
        [-94.73926, 39.10645],
        [-94.73985, 39.10824],
        [-94.73993, 39.10895],
        [-94.73993, 39.1091],
        [-94.73989, 39.10928],
        [-94.7398, 39.10943],
        [-94.73967, 39.10954],
        [-94.7395, 39.10963],
        [-94.7393, 39.10968],
        [-94.7391, 39.10967],
        [-94.73897, 39.10965],
        [-94.73873, 39.10956],
        [-94.73834, 39.10932],
        [-94.73809, 39.10904],
        [-94.73699, 39.10745],
        [-94.73683, 39.10715],
        [-94.73672, 39.10682],
        [-94.73651, 39.10558],
        [-94.73645, 39.10538],
        [-94.73634, 39.10523],
        [-94.73619, 39.10511],
        [-94.73601, 39.10502],
        [-94.7358, 39.10498],
        [-94.7357, 39.10497],
        [-94.73549, 39.10499],
        [-94.73526, 39.10507],
        [-94.73474, 39.10538],
        [-94.73433, 39.10559],
        [-94.73412, 39.10568],
        [-94.73369, 39.10582],
        [-94.73324, 39.1059],
        [-94.73263, 39.10594],
        [-94.73211, 39.10601],
        [-94.73026, 39.10596],
        [-94.73012, 39.10597],
        [-94.72722, 39.10592],
        [-94.72469, 39.10582],
        [-94.72243, 39.10568],
        [-94.71475, 39.10512],
        [-94.71285, 39.10503],
        [-94.71213, 39.10504],
        [-94.70932, 39.10523],
        [-94.70495, 39.10571],
        [-94.70237, 39.10595],
        [-94.70057, 39.106],
        [-94.6999, 39.106],
        [-94.69878, 39.10597],
        [-94.69761, 39.10589],
        [-94.69691, 39.10582],
        [-94.69271, 39.10534],
        [-94.69118, 39.10522],
        [-94.68964, 39.10517],
        [-94.68783, 39.10517],
        [-94.68707, 39.10515],
        [-94.68515, 39.10514],
        [-94.68444, 39.10513],
        [-94.68394, 39.10509],
        [-94.68346, 39.10512],
        [-94.68305, 39.10509],
        [-94.68217, 39.10507],
        [-94.68172, 39.1051],
        [-94.68149, 39.10513],
        [-94.68127, 39.10519],
        [-94.68084, 39.10532],
        [-94.68042, 39.1055],
        [-94.68007, 39.10575],
        [-94.67987, 39.10596],
        [-94.67969, 39.10624],
        [-94.67954, 39.10654],
        [-94.67946, 39.10686],
        [-94.67943, 39.10719],
        [-94.67946, 39.10751],
        [-94.6795, 39.10766],
        [-94.67962, 39.10794],
        [-94.6797, 39.10806],
        [-94.68008, 39.10858],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "106-166",
      route_short_name: "106",
      route_long_name: "Quindaro-Amazon",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.58726, 39.0988],
        [-94.58774, 39.0988],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "107-166",
      route_short_name: "107",
      route_long_name: "7th Street/Parallel",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.61374, 39.05025],
        [-94.61525, 39.05026],
        [-94.61775, 39.0503],
        [-94.61837, 39.05029],
        [-94.61888, 39.05025],
        [-94.62089, 39.04996],
        [-94.62128, 39.04994],
        [-94.62128, 39.04931],
        [-94.62129, 39.04779],
        [-94.62124, 39.04407],
        [-94.61539, 39.04406],
        [-94.61423, 39.0441],
        [-94.61193, 39.04408],
        [-94.61193, 39.04535],
        [-94.61184, 39.04906],
        [-94.61187, 39.05138],
        [-94.61183, 39.05503],
        [-94.61184, 39.05599],
        [-94.6118, 39.05605],
        [-94.61175, 39.05788],
        [-94.6118, 39.0581],
        [-94.61192, 39.05833],
        [-94.61274, 39.05943],
        [-94.61287, 39.05966],
        [-94.61295, 39.05992],
        [-94.61296, 39.06045],
        [-94.61294, 39.06126],
        [-94.61291, 39.06326],
        [-94.61285, 39.0645],
        [-94.61292, 39.06624],
        [-94.61295, 39.06654],
        [-94.61303, 39.06689],
        [-94.61315, 39.0672],
        [-94.61331, 39.06749],
        [-94.61349, 39.0677],
        [-94.61379, 39.06794],
        [-94.61847, 39.07082],
        [-94.61906, 39.07112],
        [-94.62125, 39.07195],
        [-94.62163, 39.07211],
        [-94.62278, 39.07264],
        [-94.62297, 39.07275],
        [-94.62322, 39.07293],
        [-94.6235, 39.07316],
        [-94.62365, 39.07332],
        [-94.62382, 39.07354],
        [-94.62395, 39.07377],
        [-94.62436, 39.07475],
        [-94.62458, 39.07526],
        [-94.62535, 39.07685],
        [-94.62546, 39.07718],
        [-94.6255, 39.07742],
        [-94.62555, 39.07792],
        [-94.62559, 39.07797],
        [-94.62569, 39.08047],
        [-94.6257, 39.08088],
        [-94.62565, 39.08094],
        [-94.62569, 39.08279],
        [-94.62577, 39.08526],
        [-94.62583, 39.08637],
        [-94.62587, 39.08762],
        [-94.62591, 39.08952],
        [-94.62597, 39.09044],
        [-94.626, 39.09119],
        [-94.62604, 39.09328],
        [-94.62603, 39.09359],
        [-94.62604, 39.09456],
        [-94.62601, 39.09521],
        [-94.62609, 39.09706],
        [-94.62614, 39.09713],
        [-94.62613, 39.09901],
        [-94.62617, 39.10098],
        [-94.62623, 39.10237],
        [-94.62622, 39.1029],
        [-94.62625, 39.10309],
        [-94.62628, 39.10319],
        [-94.62666, 39.10375],
        [-94.62669, 39.10382],
        [-94.62673, 39.10405],
        [-94.62676, 39.10452],
        [-94.6268, 39.1063],
        [-94.62679, 39.10723],
        [-94.6268, 39.10813],
        [-94.62679, 39.10986],
        [-94.62681, 39.11116],
        [-94.62679, 39.11459],
        [-94.6268, 39.11542],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "107-166",
      route_short_name: "107",
      route_long_name: "7th Street/Parallel",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.6268, 39.11542],
        [-94.62682, 39.11653],
        [-94.62404, 39.11654],
        [-94.62404, 39.11553],
        [-94.62407, 39.11456],
        [-94.62407, 39.11361],
        [-94.6268, 39.11366],
        [-94.62681, 39.11116],
        [-94.62679, 39.10986],
        [-94.6268, 39.10813],
        [-94.62679, 39.10723],
        [-94.6268, 39.1063],
        [-94.62676, 39.10452],
        [-94.62673, 39.10405],
        [-94.62669, 39.10382],
        [-94.62666, 39.10375],
        [-94.62628, 39.10319],
        [-94.62625, 39.10309],
        [-94.62622, 39.1029],
        [-94.62623, 39.10237],
        [-94.62617, 39.10098],
        [-94.62613, 39.09819],
        [-94.62614, 39.09713],
        [-94.62619, 39.09706],
        [-94.62623, 39.09538],
        [-94.62618, 39.09423],
        [-94.62614, 39.09374],
        [-94.62614, 39.09334],
        [-94.6261, 39.0908],
        [-94.62605, 39.08913],
        [-94.62601, 39.08762],
        [-94.62594, 39.08644],
        [-94.62581, 39.0824],
        [-94.62575, 39.08095],
        [-94.6257, 39.08088],
        [-94.62569, 39.08071],
        [-94.6269, 39.08069],
        [-94.62683, 39.07913],
        [-94.62576, 39.07917],
        [-94.6258, 39.08057],
        [-94.62569, 39.08071],
        [-94.62559, 39.07797],
        [-94.62562, 39.07791],
        [-94.62559, 39.07731],
        [-94.62552, 39.07696],
        [-94.62535, 39.07652],
        [-94.62473, 39.07523],
        [-94.6245, 39.07471],
        [-94.62424, 39.07409],
        [-94.62407, 39.0737],
        [-94.62394, 39.07347],
        [-94.62383, 39.07333],
        [-94.62372, 39.07321],
        [-94.62342, 39.07292],
        [-94.62307, 39.07267],
        [-94.62255, 39.07239],
        [-94.62131, 39.0719],
        [-94.6205, 39.07155],
        [-94.61946, 39.07115],
        [-94.61873, 39.07081],
        [-94.61829, 39.07058],
        [-94.61758, 39.07012],
        [-94.61586, 39.06905],
        [-94.61382, 39.06775],
        [-94.61366, 39.06761],
        [-94.61338, 39.06727],
        [-94.61322, 39.06699],
        [-94.6131, 39.06669],
        [-94.61305, 39.06643],
        [-94.61302, 39.06612],
        [-94.613, 39.0656],
        [-94.613, 39.06437],
        [-94.61304, 39.06366],
        [-94.61303, 39.06258],
        [-94.61304, 39.06183],
        [-94.61311, 39.06036],
        [-94.61311, 39.06006],
        [-94.6131, 39.05991],
        [-94.61303, 39.05965],
        [-94.61291, 39.05942],
        [-94.61217, 39.05845],
        [-94.612, 39.05818],
        [-94.61191, 39.05795],
        [-94.6119, 39.05784],
        [-94.61188, 39.0569],
        [-94.61189, 39.05619],
        [-94.61189, 39.05605],
        [-94.61184, 39.05599],
        [-94.61183, 39.05503],
        [-94.61187, 39.05138],
        [-94.61185, 39.05025],
        [-94.61199, 39.05025],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "113k-89",
      route_short_name: "113",
      route_long_name: "Leavenworth Road",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.83095, 39.13011],
        [-94.83055, 39.13011],
        [-94.83019, 39.13012],
        [-94.83018, 39.12983],
        [-94.8302, 39.12939],
        [-94.83022, 39.12925],
        [-94.83022, 39.12864],
        [-94.83188, 39.12864],
        [-94.83381, 39.1287],
        [-94.83507, 39.1287],
        [-94.83506, 39.1291],
        [-94.83503, 39.12926],
        [-94.83499, 39.12939],
        [-94.83491, 39.12955],
        [-94.83481, 39.12973],
        [-94.83442, 39.13018],
        [-94.83373, 39.13102],
        [-94.83227, 39.1327],
        [-94.83221, 39.13277],
        [-94.8322, 39.13287],
        [-94.83207, 39.13306],
        [-94.83185, 39.13348],
        [-94.8317, 39.13387],
        [-94.83163, 39.13414],
        [-94.83158, 39.13445],
        [-94.83155, 39.13485],
        [-94.83154, 39.1359],
        [-94.82995, 39.13591],
        [-94.82727, 39.13588],
        [-94.82667, 39.13586],
        [-94.82534, 39.13578],
        [-94.82388, 39.1358],
        [-94.82291, 39.13577],
        [-94.82059, 39.13579],
        [-94.81864, 39.13574],
        [-94.81665, 39.13576],
        [-94.81665, 39.13609],
        [-94.81667, 39.13639],
        [-94.81671, 39.13655],
        [-94.81676, 39.13671],
        [-94.81686, 39.13691],
        [-94.81697, 39.13707],
        [-94.81718, 39.13729],
        [-94.81786, 39.13785],
        [-94.81799, 39.13803],
        [-94.81808, 39.1382],
        [-94.81815, 39.1384],
        [-94.81817, 39.13857],
        [-94.81815, 39.13876],
        [-94.81807, 39.13896],
        [-94.818, 39.13908],
        [-94.81789, 39.13923],
        [-94.81774, 39.13937],
        [-94.81648, 39.14039],
        [-94.81618, 39.14061],
        [-94.81514, 39.14112],
        [-94.81404, 39.14174],
        [-94.81389, 39.14185],
        [-94.81368, 39.14208],
        [-94.81354, 39.14234],
        [-94.81348, 39.14259],
        [-94.81347, 39.14276],
        [-94.81347, 39.14302],
        [-94.8112, 39.14303],
        [-94.80829, 39.14312],
        [-94.80582, 39.14317],
        [-94.79954, 39.14318],
        [-94.79944, 39.14322],
        [-94.79839, 39.1432],
        [-94.79638, 39.14323],
        [-94.79441, 39.14316],
        [-94.79224, 39.14325],
        [-94.78799, 39.14309],
        [-94.78692, 39.1431],
        [-94.78612, 39.14313],
        [-94.78465, 39.14311],
        [-94.7836, 39.14313],
        [-94.78305, 39.14311],
        [-94.78106, 39.14309],
        [-94.77943, 39.14312],
        [-94.77802, 39.1431],
        [-94.77628, 39.14311],
        [-94.77215, 39.14309],
        [-94.76164, 39.1431],
        [-94.76027, 39.14308],
        [-94.7524, 39.14306],
        [-94.74724, 39.1431],
        [-94.74546, 39.14313],
        [-94.7422, 39.14314],
        [-94.7398, 39.14311],
        [-94.73523, 39.14309],
        [-94.73286, 39.14307],
        [-94.72452, 39.14308],
        [-94.72204, 39.14307],
        [-94.71985, 39.14311],
        [-94.71795, 39.14311],
        [-94.71664, 39.14309],
        [-94.71656, 39.14306],
        [-94.71543, 39.14304],
        [-94.71382, 39.14304],
        [-94.71374, 39.14307],
        [-94.71168, 39.14303],
        [-94.71054, 39.14303],
        [-94.70004, 39.14293],
        [-94.69653, 39.1429],
        [-94.69293, 39.1429],
        [-94.69081, 39.14288],
        [-94.67956, 39.14292],
        [-94.6756, 39.14291],
        [-94.67323, 39.14293],
        [-94.67336, 39.13569],
        [-94.67644, 39.13567],
        [-94.67729, 39.13568],
        [-94.67762, 39.1357],
        [-94.67789, 39.13576],
        [-94.67795, 39.13584],
        [-94.67798, 39.13603],
        [-94.67803, 39.13618],
        [-94.67815, 39.13631],
        [-94.67824, 39.13635],
        [-94.67837, 39.13637],
        [-94.68021, 39.13637],
        [-94.68179, 39.13636],
        [-94.68201, 39.13635],
        [-94.68242, 39.13626],
        [-94.68289, 39.13612],
        [-94.68329, 39.13604],
        [-94.68673, 39.13602],
        [-94.68686, 39.136],
        [-94.68706, 39.13594],
        [-94.68725, 39.13581],
        [-94.68735, 39.1357],
        [-94.68737, 39.13565],
        [-94.68739, 39.1355],
        [-94.68739, 39.13444],
        [-94.68742, 39.13309],
        [-94.68744, 39.13015],
        [-94.68746, 39.12936],
        [-94.68743, 39.12928],
        [-94.68734, 39.12922],
        [-94.68714, 39.1292],
        [-94.68708, 39.12917],
        [-94.68691, 39.12915],
        [-94.68679, 39.1291],
        [-94.68667, 39.12902],
        [-94.68657, 39.12889],
        [-94.68654, 39.12879],
        [-94.68653, 39.12868],
        [-94.68651, 39.12733],
        [-94.68647, 39.12728],
        [-94.68652, 39.11515],
        [-94.68653, 39.11424],
        [-94.68562, 39.11422],
        [-94.68567, 39.11337],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "113k-89",
      route_short_name: "113",
      route_long_name: "Leavenworth Road",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.68567, 39.11337],
        [-94.68569, 39.11306],
        [-94.68654, 39.11309],
        [-94.68652, 39.11515],
        [-94.68647, 39.12728],
        [-94.68644, 39.12733],
        [-94.68642, 39.12755],
        [-94.68639, 39.12874],
        [-94.68642, 39.12891],
        [-94.68645, 39.12898],
        [-94.6865, 39.12904],
        [-94.68663, 39.12914],
        [-94.68679, 39.1292],
        [-94.68696, 39.12923],
        [-94.68708, 39.12923],
        [-94.68714, 39.1292],
        [-94.68734, 39.12922],
        [-94.68743, 39.12928],
        [-94.68746, 39.12936],
        [-94.68744, 39.13015],
        [-94.68742, 39.13309],
        [-94.68739, 39.13444],
        [-94.68739, 39.1355],
        [-94.68737, 39.13565],
        [-94.68735, 39.1357],
        [-94.68725, 39.13581],
        [-94.68706, 39.13594],
        [-94.68686, 39.136],
        [-94.68673, 39.13602],
        [-94.68329, 39.13604],
        [-94.68289, 39.13612],
        [-94.68242, 39.13626],
        [-94.68201, 39.13635],
        [-94.68179, 39.13636],
        [-94.68021, 39.13637],
        [-94.67837, 39.13637],
        [-94.67824, 39.13635],
        [-94.67815, 39.13631],
        [-94.67803, 39.13618],
        [-94.67798, 39.13603],
        [-94.67795, 39.13584],
        [-94.67789, 39.13576],
        [-94.67762, 39.1357],
        [-94.67729, 39.13568],
        [-94.67644, 39.13567],
        [-94.67336, 39.13569],
        [-94.67323, 39.14293],
        [-94.6756, 39.14291],
        [-94.67956, 39.14292],
        [-94.69081, 39.14288],
        [-94.69293, 39.1429],
        [-94.69653, 39.1429],
        [-94.7021, 39.14295],
        [-94.7021, 39.14304],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "113k-89",
      route_short_name: "113",
      route_long_name: "Leavenworth Road",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.7021, 39.14295],
        [-94.71054, 39.14303],
        [-94.71168, 39.14303],
        [-94.71374, 39.14307],
        [-94.71382, 39.14311],
        [-94.71389, 39.14311],
        [-94.71531, 39.14313],
        [-94.71656, 39.14312],
        [-94.71664, 39.14309],
        [-94.71795, 39.14311],
        [-94.71985, 39.14311],
        [-94.72204, 39.14307],
        [-94.72452, 39.14308],
        [-94.73286, 39.14307],
        [-94.73523, 39.14309],
        [-94.7398, 39.14311],
        [-94.7422, 39.14314],
        [-94.74546, 39.14313],
        [-94.74724, 39.1431],
        [-94.7524, 39.14306],
        [-94.76027, 39.14308],
        [-94.76164, 39.1431],
        [-94.77215, 39.14309],
        [-94.77628, 39.14311],
        [-94.77802, 39.1431],
        [-94.77943, 39.14312],
        [-94.78106, 39.14309],
        [-94.78305, 39.14311],
        [-94.7836, 39.14313],
        [-94.78465, 39.14311],
        [-94.78612, 39.14313],
        [-94.78692, 39.1431],
        [-94.78799, 39.14309],
        [-94.79202, 39.14325],
        [-94.79322, 39.14321],
        [-94.79441, 39.14316],
        [-94.79638, 39.14323],
        [-94.79839, 39.1432],
        [-94.79944, 39.14322],
        [-94.79953, 39.14326],
        [-94.80056, 39.14332],
        [-94.8018, 39.14333],
        [-94.80583, 39.14333],
        [-94.80821, 39.14328],
        [-94.80961, 39.14323],
        [-94.81263, 39.14315],
        [-94.81507, 39.14314],
        [-94.81559, 39.14312],
        [-94.81646, 39.14302],
        [-94.81714, 39.14297],
        [-94.81776, 39.14296],
        [-94.81869, 39.143],
        [-94.82101, 39.14315],
        [-94.82274, 39.14323],
        [-94.82338, 39.14322],
        [-94.82392, 39.14323],
        [-94.82483, 39.1432],
        [-94.8256, 39.14319],
        [-94.82565, 39.14319],
        [-94.82571, 39.14316],
        [-94.82633, 39.14315],
        [-94.83071, 39.14316],
        [-94.83092, 39.14286],
        [-94.83112, 39.14251],
        [-94.83124, 39.14224],
        [-94.83134, 39.14193],
        [-94.83142, 39.14155],
        [-94.83145, 39.14129],
        [-94.83148, 39.14055],
        [-94.83151, 39.13711],
        [-94.83155, 39.13485],
        [-94.83158, 39.13445],
        [-94.83163, 39.13414],
        [-94.8317, 39.13387],
        [-94.83185, 39.13348],
        [-94.83198, 39.13322],
        [-94.83216, 39.13292],
        [-94.8322, 39.13287],
        [-94.83231, 39.13283],
        [-94.83262, 39.13252],
        [-94.83292, 39.13216],
        [-94.83324, 39.13183],
        [-94.83313, 39.13177],
        [-94.83266, 39.13158],
        [-94.83235, 39.13148],
        [-94.8321, 39.13146],
        [-94.83157, 39.13146],
        [-94.83153, 39.13137],
        [-94.83151, 39.13117],
        [-94.83152, 39.13064],
        [-94.83149, 39.13032],
        [-94.83141, 39.13022],
        [-94.83132, 39.13018],
        [-94.83109, 39.13011],
        [-94.83095, 39.13011],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "115k-89",
      route_short_name: "115",
      route_long_name: "Kansas Avenue",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.74301, 39.10482],
        [-94.74313, 39.10484],
        [-94.74427, 39.10493],
        [-94.74561, 39.10494],
        [-94.74562, 39.10394],
        [-94.74565, 39.10266],
        [-94.74563, 39.10105],
        [-94.74569, 39.09944],
        [-94.74575, 39.09235],
        [-94.74574, 39.0919],
        [-94.74566, 39.09092],
        [-94.74566, 39.08939],
        [-94.74569, 39.08754],
        [-94.74225, 39.08744],
        [-94.73707, 39.08746],
        [-94.73697, 39.08745],
        [-94.73681, 39.0874],
        [-94.73663, 39.08727],
        [-94.73592, 39.08753],
        [-94.7355, 39.08773],
        [-94.73186, 39.08998],
        [-94.73172, 39.08987],
        [-94.72802, 39.09217],
        [-94.72802, 39.09171],
        [-94.72805, 39.08951],
        [-94.72805, 39.08761],
        [-94.71975, 39.0878],
        [-94.71938, 39.08786],
        [-94.71902, 39.08796],
        [-94.71867, 39.08814],
        [-94.7185, 39.08826],
        [-94.7184, 39.08835],
        [-94.71818, 39.08861],
        [-94.71804, 39.08881],
        [-94.7178, 39.08922],
        [-94.71728, 39.09004],
        [-94.71681, 39.0907],
        [-94.71523, 39.09278],
        [-94.71477, 39.09345],
        [-94.71467, 39.09363],
        [-94.7146, 39.09381],
        [-94.71426, 39.09485],
        [-94.71407, 39.09501],
        [-94.71398, 39.09505],
        [-94.71389, 39.09508],
        [-94.71374, 39.09509],
        [-94.71356, 39.09507],
        [-94.71266, 39.09468],
        [-94.71191, 39.09432],
        [-94.70942, 39.09302],
        [-94.70679, 39.0917],
        [-94.70614, 39.09136],
        [-94.70524, 39.09092],
        [-94.70523, 39.08941],
        [-94.70519, 39.08766],
        [-94.70519, 39.08691],
        [-94.70525, 39.08576],
        [-94.70525, 39.08497],
        [-94.70521, 39.08325],
        [-94.70522, 39.0824],
        [-94.70427, 39.0824],
        [-94.70429, 39.08312],
        [-94.70521, 39.08313],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "115k-89",
      route_short_name: "115",
      route_long_name: "Kansas Avenue",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.70524, 39.0902],
        [-94.70523, 39.09023],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "115k-89",
      route_short_name: "115",
      route_long_name: "Kansas Avenue",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.70524, 39.09092],
        [-94.7052, 39.09481],
        [-94.7052, 39.09486],
        [-94.70517, 39.0949],
        [-94.70513, 39.09493],
        [-94.70506, 39.09494],
        [-94.69739, 39.09493],
        [-94.68666, 39.09496],
        [-94.67721, 39.09503],
        [-94.6772, 39.09413],
        [-94.67723, 39.09244],
        [-94.67719, 39.09054],
        [-94.67721, 39.08873],
        [-94.67676, 39.08874],
        [-94.6765, 39.08873],
        [-94.67627, 39.08869],
        [-94.67607, 39.08861],
        [-94.67553, 39.08835],
        [-94.67517, 39.08821],
        [-94.6749, 39.08811],
        [-94.6746, 39.08804],
        [-94.67413, 39.08795],
        [-94.67413, 39.08806],
        [-94.67405, 39.08816],
        [-94.67403, 39.08917],
        [-94.67405, 39.09132],
        [-94.67411, 39.09156],
        [-94.67416, 39.09168],
        [-94.67429, 39.09189],
        [-94.67438, 39.09199],
        [-94.6746, 39.09216],
        [-94.67485, 39.09229],
        [-94.67513, 39.09238],
        [-94.67544, 39.09243],
        [-94.67593, 39.09245],
        [-94.6785, 39.09243],
        [-94.67888, 39.09247],
        [-94.67921, 39.09255],
        [-94.67937, 39.0926],
        [-94.67965, 39.09275],
        [-94.67979, 39.09284],
        [-94.68002, 39.09304],
        [-94.68021, 39.09326],
        [-94.68028, 39.09338],
        [-94.6804, 39.09364],
        [-94.68044, 39.0938],
        [-94.6805, 39.0941],
        [-94.68051, 39.09552],
        [-94.6805, 39.09579],
        [-94.68045, 39.09633],
        [-94.67943, 39.10103],
        [-94.6792, 39.10183],
        [-94.67893, 39.10252],
        [-94.67813, 39.10394],
        [-94.67795, 39.10433],
        [-94.67783, 39.10482],
        [-94.6778, 39.10523],
        [-94.67783, 39.10565],
        [-94.67791, 39.10605],
        [-94.67801, 39.10634],
        [-94.67815, 39.10664],
        [-94.67838, 39.10701],
        [-94.6787, 39.10738],
        [-94.67919, 39.10783],
        [-94.68083, 39.10921],
        [-94.68115, 39.10946],
        [-94.6814, 39.10969],
        [-94.68171, 39.11003],
        [-94.68195, 39.11039],
        [-94.68215, 39.11077],
        [-94.68223, 39.11098],
        [-94.68232, 39.11127],
        [-94.68237, 39.11149],
        [-94.6824, 39.11176],
        [-94.6824, 39.11201],
        [-94.68236, 39.11234],
        [-94.68226, 39.11299],
        [-94.68215, 39.11328],
        [-94.68196, 39.11407],
        [-94.6819, 39.11423],
        [-94.68174, 39.11454],
        [-94.68151, 39.11483],
        [-94.68123, 39.11509],
        [-94.68071, 39.11548],
        [-94.68051, 39.11567],
        [-94.68044, 39.11577],
        [-94.68036, 39.11592],
        [-94.6803, 39.11618],
        [-94.68028, 39.11643],
        [-94.6803, 39.11665],
        [-94.68036, 39.11673],
        [-94.68165, 39.1167],
        [-94.68367, 39.11672],
        [-94.68652, 39.11668],
        [-94.68654, 39.11309],
        [-94.68569, 39.11306],
        [-94.68566, 39.11358],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "115k-89",
      route_short_name: "115",
      route_long_name: "Kansas Avenue",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.68566, 39.11358],
        [-94.68562, 39.11422],
        [-94.68653, 39.11424],
        [-94.68652, 39.11656],
        [-94.6842, 39.11656],
        [-94.68383, 39.11651],
        [-94.68367, 39.11647],
        [-94.6835, 39.1164],
        [-94.68336, 39.1163],
        [-94.68326, 39.1162],
        [-94.68312, 39.116],
        [-94.68276, 39.11534],
        [-94.68269, 39.11519],
        [-94.68258, 39.11489],
        [-94.68252, 39.1146],
        [-94.68247, 39.11408],
        [-94.68248, 39.1133],
        [-94.6826, 39.11248],
        [-94.68264, 39.11198],
        [-94.68262, 39.11153],
        [-94.68253, 39.11106],
        [-94.68241, 39.11069],
        [-94.68221, 39.11028],
        [-94.68206, 39.11004],
        [-94.68186, 39.10977],
        [-94.68158, 39.10944],
        [-94.68113, 39.1088],
        [-94.6809, 39.10838],
        [-94.68074, 39.10794],
        [-94.68063, 39.10749],
        [-94.68058, 39.10704],
        [-94.68058, 39.10575],
        [-94.68053, 39.10536],
        [-94.68049, 39.10514],
        [-94.68041, 39.10489],
        [-94.67999, 39.10379],
        [-94.67981, 39.10318],
        [-94.67971, 39.10256],
        [-94.67966, 39.10191],
        [-94.6797, 39.10134],
        [-94.67984, 39.10065],
        [-94.68063, 39.0971],
        [-94.6807, 39.09667],
        [-94.68075, 39.09618],
        [-94.68078, 39.09567],
        [-94.68075, 39.09404],
        [-94.68087, 39.09352],
        [-94.68097, 39.09328],
        [-94.68104, 39.09315],
        [-94.68122, 39.09292],
        [-94.68145, 39.09271],
        [-94.68172, 39.09254],
        [-94.68187, 39.09246],
        [-94.68219, 39.09236],
        [-94.68253, 39.09229],
        [-94.68292, 39.09226],
        [-94.68525, 39.09226],
        [-94.68555, 39.09221],
        [-94.68569, 39.09217],
        [-94.68583, 39.09212],
        [-94.68608, 39.09199],
        [-94.6863, 39.09182],
        [-94.68647, 39.09162],
        [-94.68658, 39.0914],
        [-94.68664, 39.09119],
        [-94.68667, 39.091],
        [-94.68665, 39.09007],
        [-94.68666, 39.08951],
        [-94.68209, 39.08955],
        [-94.68045, 39.08954],
        [-94.6772, 39.08955],
        [-94.67719, 39.09054],
        [-94.67723, 39.09244],
        [-94.6772, 39.09413],
        [-94.67721, 39.09503],
        [-94.68666, 39.09496],
        [-94.69739, 39.09493],
        [-94.7051, 39.09493],
        [-94.70517, 39.0949],
        [-94.7052, 39.09486],
        [-94.70521, 39.09453],
        [-94.70522, 39.09181],
        [-94.70524, 39.0902],
        [-94.70519, 39.08732],
        [-94.70519, 39.08691],
        [-94.70525, 39.08576],
        [-94.70525, 39.08497],
        [-94.70521, 39.08325],
        [-94.70522, 39.0824],
        [-94.70427, 39.0824],
        [-94.70429, 39.08312],
        [-94.70521, 39.08313],
        [-94.70521, 39.08325],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "115k-89",
      route_short_name: "115",
      route_long_name: "Kansas Avenue",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.70524, 39.0902],
        [-94.70523, 39.09023],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "115k-89",
      route_short_name: "115",
      route_long_name: "Kansas Avenue",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.70523, 39.09105],
        [-94.70523, 39.09111],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "115k-89",
      route_short_name: "115",
      route_long_name: "Kansas Avenue",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.70524, 39.09092],
        [-94.70614, 39.09136],
        [-94.70679, 39.0917],
        [-94.70942, 39.09302],
        [-94.71191, 39.09432],
        [-94.71266, 39.09468],
        [-94.71356, 39.09507],
        [-94.71374, 39.09509],
        [-94.71389, 39.09508],
        [-94.71398, 39.09505],
        [-94.71407, 39.09501],
        [-94.71426, 39.09485],
        [-94.7146, 39.09381],
        [-94.71467, 39.09363],
        [-94.71477, 39.09345],
        [-94.71523, 39.09278],
        [-94.71681, 39.0907],
        [-94.71728, 39.09004],
        [-94.7178, 39.08922],
        [-94.71804, 39.08881],
        [-94.71818, 39.08861],
        [-94.7184, 39.08835],
        [-94.7185, 39.08826],
        [-94.71867, 39.08814],
        [-94.71902, 39.08796],
        [-94.71938, 39.08786],
        [-94.71975, 39.0878],
        [-94.72805, 39.08761],
        [-94.72805, 39.08951],
        [-94.72802, 39.09171],
        [-94.72802, 39.09217],
        [-94.73172, 39.08987],
        [-94.73186, 39.08998],
        [-94.73274, 39.08944],
        [-94.73314, 39.08975],
        [-94.73411, 39.08911],
        [-94.73375, 39.08881],
        [-94.7355, 39.08773],
        [-94.73592, 39.08753],
        [-94.73663, 39.08727],
        [-94.73681, 39.0874],
        [-94.73697, 39.08745],
        [-94.73707, 39.08746],
        [-94.74225, 39.08744],
        [-94.74569, 39.08754],
        [-94.74566, 39.08939],
        [-94.74566, 39.09092],
        [-94.74574, 39.0919],
        [-94.74575, 39.09235],
        [-94.74569, 39.09944],
        [-94.74563, 39.10105],
        [-94.74565, 39.10221],
        [-94.7428, 39.10225],
        [-94.74174, 39.10224],
        [-94.74171, 39.10302],
        [-94.74172, 39.10326],
        [-94.74174, 39.10343],
        [-94.74181, 39.10365],
        [-94.74195, 39.10387],
        [-94.74205, 39.10401],
        [-94.7426, 39.10466],
        [-94.74276, 39.10474],
        [-94.74301, 39.10482],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "116k-89",
      route_short_name: "116",
      route_long_name: "West Parallel",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.83095, 39.13011],
        [-94.83055, 39.13011],
        [-94.83019, 39.13012],
        [-94.83018, 39.12983],
        [-94.8302, 39.12939],
        [-94.83022, 39.12925],
        [-94.83022, 39.12864],
        [-94.83188, 39.12864],
        [-94.83381, 39.1287],
        [-94.83507, 39.1287],
        [-94.83509, 39.12847],
        [-94.83517, 39.12834],
        [-94.83516, 39.12556],
        [-94.83443, 39.12552],
        [-94.83417, 39.12554],
        [-94.83364, 39.12567],
        [-94.83324, 39.12573],
        [-94.83294, 39.12576],
        [-94.83222, 39.12573],
        [-94.83178, 39.12577],
        [-94.83128, 39.12587],
        [-94.82999, 39.12635],
        [-94.82982, 39.12601],
        [-94.8297, 39.12583],
        [-94.82951, 39.1256],
        [-94.82932, 39.12541],
        [-94.82885, 39.125],
        [-94.82822, 39.12465],
        [-94.82793, 39.12453],
        [-94.82713, 39.1243],
        [-94.82534, 39.12391],
        [-94.825, 39.12381],
        [-94.82449, 39.12362],
        [-94.82385, 39.12329],
        [-94.82323, 39.1229],
        [-94.82285, 39.1226],
        [-94.822, 39.12175],
        [-94.82141, 39.12113],
        [-94.81726, 39.12241],
        [-94.81633, 39.12265],
        [-94.81559, 39.12281],
        [-94.81533, 39.12285],
        [-94.81306, 39.12334],
        [-94.81278, 39.12336],
        [-94.81254, 39.12335],
        [-94.81217, 39.12327],
        [-94.81194, 39.12317],
        [-94.81153, 39.12291],
        [-94.8113, 39.12273],
        [-94.81117, 39.12258],
        [-94.81089, 39.12274],
        [-94.81068, 39.12283],
        [-94.81041, 39.12291],
        [-94.80938, 39.12313],
        [-94.80888, 39.12327],
        [-94.80867, 39.12335],
        [-94.80856, 39.1234],
        [-94.80821, 39.12364],
        [-94.80801, 39.12383],
        [-94.80785, 39.12403],
        [-94.80777, 39.12415],
        [-94.80766, 39.12441],
        [-94.80761, 39.12471],
        [-94.80764, 39.125],
        [-94.80775, 39.12536],
        [-94.80784, 39.12577],
        [-94.80801, 39.12672],
        [-94.80813, 39.12798],
        [-94.80816, 39.12855],
        [-94.80728, 39.12854],
        [-94.80608, 39.12857],
        [-94.80599, 39.12861],
        [-94.80382, 39.12861],
        [-94.80104, 39.1286],
        [-94.79904, 39.12862],
        [-94.79617, 39.1286],
        [-94.78849, 39.1286],
        [-94.78851, 39.12537],
        [-94.7876, 39.12534],
        [-94.78611, 39.12535],
        [-94.78612, 39.12602],
        [-94.78612, 39.12617],
        [-94.78608, 39.12626],
        [-94.78596, 39.12624],
        [-94.78581, 39.1263],
        [-94.78578, 39.12636],
        [-94.78578, 39.12642],
        [-94.78491, 39.12644],
        [-94.78453, 39.12649],
        [-94.78436, 39.12658],
        [-94.78425, 39.12671],
        [-94.7842, 39.12674],
        [-94.78416, 39.12684],
        [-94.78418, 39.12747],
        [-94.78416, 39.12859],
        [-94.78339, 39.12859],
        [-94.7833, 39.12855],
        [-94.78019, 39.12852],
        [-94.7763, 39.12858],
        [-94.7732, 39.12857],
        [-94.76993, 39.12852],
        [-94.76991, 39.12769],
        [-94.76985, 39.12761],
        [-94.76984, 39.12713],
        [-94.76981, 39.12548],
        [-94.76984, 39.1241],
        [-94.76899, 39.12408],
        [-94.76865, 39.12409],
        [-94.76857, 39.12413],
        [-94.76851, 39.12421],
        [-94.76849, 39.12436],
        [-94.76852, 39.12454],
        [-94.76855, 39.1246],
        [-94.7686, 39.12462],
        [-94.76868, 39.12463],
        [-94.76982, 39.12462],
        [-94.76981, 39.12548],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "116k-89",
      route_short_name: "116",
      route_long_name: "West Parallel",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.76984, 39.12694],
        [-94.76984, 39.12741],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "116k-89",
      route_short_name: "116",
      route_long_name: "West Parallel",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.76985, 39.12761],
        [-94.76981, 39.12768],
        [-94.76982, 39.12852],
        [-94.76798, 39.12851],
        [-94.76723, 39.12852],
        [-94.76642, 39.12851],
        [-94.75597, 39.12851],
        [-94.75364, 39.12848],
        [-94.7482, 39.12847],
        [-94.7482, 39.12828],
        [-94.74821, 39.12816],
        [-94.74834, 39.1279],
        [-94.74849, 39.12771],
        [-94.74891, 39.12732],
        [-94.74902, 39.12717],
        [-94.7491, 39.127],
        [-94.74913, 39.12681],
        [-94.74914, 39.12593],
        [-94.74911, 39.12432],
        [-94.74907, 39.12427],
        [-94.74908, 39.12409],
        [-94.7491, 39.12403],
        [-94.74918, 39.12392],
        [-94.74943, 39.12376],
        [-94.74951, 39.12368],
        [-94.74954, 39.12363],
        [-94.75025, 39.12155],
        [-94.75026, 39.12146],
        [-94.75026, 39.1208],
        [-94.7502, 39.12072],
        [-94.75008, 39.12064],
        [-94.74989, 39.12061],
        [-94.74917, 39.1206],
        [-94.74917, 39.12055],
        [-94.74867, 39.12056],
        [-94.74862, 39.12058],
        [-94.74851, 39.12059],
        [-94.74853, 39.12085],
        [-94.74917, 39.12089],
        [-94.74917, 39.1206],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "116k-89",
      route_short_name: "116",
      route_long_name: "West Parallel",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.74911, 39.12432],
        [-94.74912, 39.12487],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "116k-89",
      route_short_name: "116",
      route_long_name: "West Parallel",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.7482, 39.12847],
        [-94.73391, 39.12846],
        [-94.73073, 39.12849],
        [-94.72387, 39.12848],
        [-94.71704, 39.12849],
        [-94.69487, 39.12837],
        [-94.69266, 39.12838],
        [-94.69065, 39.12845],
        [-94.68455, 39.12844],
        [-94.6808, 39.12846],
        [-94.67927, 39.12841],
        [-94.67807, 39.1284],
        [-94.67766, 39.12834],
        [-94.67757, 39.12831],
        [-94.67748, 39.12823],
        [-94.67744, 39.1281],
        [-94.67741, 39.12725],
        [-94.67733, 39.12589],
        [-94.67737, 39.12542],
        [-94.67749, 39.12441],
        [-94.67756, 39.12395],
        [-94.67763, 39.12364],
        [-94.67785, 39.123],
        [-94.67803, 39.1226],
        [-94.67818, 39.12231],
        [-94.67847, 39.12185],
        [-94.67876, 39.12148],
        [-94.68038, 39.1197],
        [-94.68083, 39.11932],
        [-94.68125, 39.11893],
        [-94.68158, 39.11868],
        [-94.68191, 39.11846],
        [-94.68292, 39.11789],
        [-94.68312, 39.11777],
        [-94.68327, 39.11764],
        [-94.6834, 39.11748],
        [-94.68345, 39.11737],
        [-94.68348, 39.11727],
        [-94.68352, 39.11699],
        [-94.68356, 39.11688],
        [-94.68361, 39.11682],
        [-94.68371, 39.11677],
        [-94.68386, 39.11672],
        [-94.68652, 39.11668],
        [-94.68654, 39.11309],
        [-94.68569, 39.11306],
        [-94.68566, 39.11358],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "116k-89",
      route_short_name: "116",
      route_long_name: "West Parallel",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.68566, 39.11358],
        [-94.68562, 39.11422],
        [-94.68653, 39.11424],
        [-94.68652, 39.11656],
        [-94.68403, 39.11656],
        [-94.68329, 39.11659],
        [-94.68166, 39.11662],
        [-94.68029, 39.11662],
        [-94.68036, 39.11673],
        [-94.67927, 39.11675],
        [-94.67957, 39.1168],
        [-94.68002, 39.11696],
        [-94.68022, 39.1171],
        [-94.68039, 39.11727],
        [-94.68051, 39.11747],
        [-94.68061, 39.11772],
        [-94.68064, 39.11789],
        [-94.68068, 39.11822],
        [-94.68065, 39.11856],
        [-94.68056, 39.11889],
        [-94.68049, 39.11905],
        [-94.68011, 39.11969],
        [-94.67915, 39.12073],
        [-94.67858, 39.12137],
        [-94.6784, 39.12161],
        [-94.67808, 39.12206],
        [-94.67795, 39.1223],
        [-94.67766, 39.1229],
        [-94.67751, 39.12331],
        [-94.67735, 39.12391],
        [-94.67722, 39.12469],
        [-94.67711, 39.12566],
        [-94.67702, 39.12589],
        [-94.67686, 39.12665],
        [-94.67655, 39.12765],
        [-94.67651, 39.12788],
        [-94.6765, 39.12797],
        [-94.67652, 39.12812],
        [-94.67661, 39.12824],
        [-94.67674, 39.12834],
        [-94.67716, 39.12853],
        [-94.67872, 39.12852],
        [-94.68053, 39.12857],
        [-94.68455, 39.12856],
        [-94.68653, 39.12858],
        [-94.68706, 39.12865],
        [-94.68899, 39.12896],
        [-94.68951, 39.129],
        [-94.68973, 39.129],
        [-94.69018, 39.12897],
        [-94.69062, 39.1289],
        [-94.69151, 39.12863],
        [-94.69193, 39.12855],
        [-94.69228, 39.12852],
        [-94.69372, 39.1285],
        [-94.69744, 39.1285],
        [-94.70217, 39.12854],
        [-94.70413, 39.12854],
        [-94.70601, 39.12856],
        [-94.70726, 39.12854],
        [-94.71284, 39.1286],
        [-94.71651, 39.12861],
        [-94.71819, 39.12861],
        [-94.71956, 39.12862],
        [-94.72303, 39.1286],
        [-94.73164, 39.12862],
        [-94.73391, 39.12857],
        [-94.73556, 39.12859],
        [-94.73645, 39.12858],
        [-94.74233, 39.1286],
        [-94.7482, 39.1286],
        [-94.74821, 39.1282],
        [-94.74823, 39.12812],
        [-94.74829, 39.12798],
        [-94.74849, 39.12771],
        [-94.74891, 39.12732],
        [-94.74902, 39.12717],
        [-94.7491, 39.127],
        [-94.74913, 39.12681],
        [-94.74914, 39.12593],
        [-94.74911, 39.12432],
        [-94.74907, 39.12427],
        [-94.74908, 39.12409],
        [-94.7491, 39.12403],
        [-94.74918, 39.12392],
        [-94.74943, 39.12376],
        [-94.74951, 39.12368],
        [-94.74954, 39.12363],
        [-94.75025, 39.12155],
        [-94.75026, 39.12146],
        [-94.75026, 39.1208],
        [-94.7502, 39.12072],
        [-94.75008, 39.12064],
        [-94.74989, 39.12061],
        [-94.74867, 39.1206],
        [-94.74862, 39.12058],
        [-94.74851, 39.12059],
        [-94.74853, 39.12085],
        [-94.74917, 39.12089],
        [-94.74917, 39.1206],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "116k-89",
      route_short_name: "116",
      route_long_name: "West Parallel",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.74911, 39.12432],
        [-94.74912, 39.12487],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "116k-89",
      route_short_name: "116",
      route_long_name: "West Parallel",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.7482, 39.1286],
        [-94.75245, 39.12859],
        [-94.75707, 39.12864],
        [-94.76886, 39.12863],
        [-94.76983, 39.12865],
        [-94.76981, 39.12768],
        [-94.76985, 39.12761],
        [-94.76981, 39.12548],
        [-94.76984, 39.1241],
        [-94.76899, 39.12408],
        [-94.76865, 39.12409],
        [-94.76857, 39.12413],
        [-94.76851, 39.12421],
        [-94.76849, 39.12436],
        [-94.7685, 39.1245],
        [-94.76853, 39.12457],
        [-94.7686, 39.12462],
        [-94.76868, 39.12463],
        [-94.76982, 39.12462],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "116k-89",
      route_short_name: "116",
      route_long_name: "West Parallel",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.76984, 39.12694],
        [-94.76984, 39.12741],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "116k-89",
      route_short_name: "116",
      route_long_name: "West Parallel",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.76983, 39.12865],
        [-94.77091, 39.12865],
        [-94.7732, 39.12869],
        [-94.77629, 39.12871],
        [-94.77912, 39.12865],
        [-94.78253, 39.12864],
        [-94.7833, 39.12863],
        [-94.78339, 39.12859],
        [-94.78416, 39.12859],
        [-94.78418, 39.12747],
        [-94.78416, 39.12684],
        [-94.7842, 39.12674],
        [-94.78425, 39.12671],
        [-94.78436, 39.12658],
        [-94.78453, 39.12649],
        [-94.78491, 39.12644],
        [-94.78578, 39.12642],
        [-94.78578, 39.12636],
        [-94.78581, 39.1263],
        [-94.78596, 39.12624],
        [-94.78608, 39.12626],
        [-94.78612, 39.12617],
        [-94.78612, 39.12602],
        [-94.78611, 39.12535],
        [-94.7876, 39.12534],
        [-94.78851, 39.12537],
        [-94.78849, 39.1286],
        [-94.79617, 39.1286],
        [-94.79904, 39.12862],
        [-94.80104, 39.1286],
        [-94.80242, 39.12861],
        [-94.80599, 39.12861],
        [-94.80608, 39.12865],
        [-94.80816, 39.12867],
        [-94.80816, 39.12855],
        [-94.8083, 39.12855],
        [-94.8083, 39.12805],
        [-94.80825, 39.1274],
        [-94.80814, 39.12648],
        [-94.808, 39.12574],
        [-94.80784, 39.12509],
        [-94.80781, 39.1249],
        [-94.80781, 39.1247],
        [-94.80786, 39.1244],
        [-94.80793, 39.12423],
        [-94.80806, 39.124],
        [-94.80822, 39.12383],
        [-94.80853, 39.1236],
        [-94.80893, 39.1234],
        [-94.80907, 39.12336],
        [-94.81001, 39.12315],
        [-94.81051, 39.12307],
        [-94.81094, 39.12292],
        [-94.81111, 39.12284],
        [-94.8113, 39.12273],
        [-94.81153, 39.12291],
        [-94.81176, 39.12307],
        [-94.81194, 39.12317],
        [-94.81217, 39.12327],
        [-94.81254, 39.12335],
        [-94.81278, 39.12336],
        [-94.81306, 39.12334],
        [-94.81533, 39.12285],
        [-94.81559, 39.12281],
        [-94.81633, 39.12265],
        [-94.81726, 39.12241],
        [-94.82124, 39.12118],
        [-94.82265, 39.12263],
        [-94.82315, 39.123],
        [-94.82378, 39.1234],
        [-94.82397, 39.12351],
        [-94.82428, 39.12367],
        [-94.82502, 39.12394],
        [-94.82751, 39.12456],
        [-94.82785, 39.12467],
        [-94.82813, 39.1248],
        [-94.82848, 39.12497],
        [-94.82871, 39.12512],
        [-94.82896, 39.1253],
        [-94.82918, 39.12551],
        [-94.82935, 39.12567],
        [-94.82953, 39.12589],
        [-94.82963, 39.12606],
        [-94.82981, 39.12641],
        [-94.83128, 39.12587],
        [-94.83178, 39.12577],
        [-94.83222, 39.12573],
        [-94.83294, 39.12576],
        [-94.83324, 39.12573],
        [-94.83364, 39.12567],
        [-94.83417, 39.12554],
        [-94.83443, 39.12552],
        [-94.83516, 39.12556],
        [-94.83517, 39.12834],
        [-94.83509, 39.12847],
        [-94.83506, 39.1291],
        [-94.83499, 39.12939],
        [-94.83481, 39.12973],
        [-94.83442, 39.13018],
        [-94.83373, 39.13102],
        [-94.83308, 39.13175],
        [-94.83266, 39.13158],
        [-94.83235, 39.13148],
        [-94.8321, 39.13146],
        [-94.83157, 39.13146],
        [-94.83153, 39.13137],
        [-94.83151, 39.13117],
        [-94.83152, 39.13064],
        [-94.83149, 39.13032],
        [-94.83141, 39.13022],
        [-94.83132, 39.13018],
        [-94.83109, 39.13011],
        [-94.83095, 39.13011],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "118k-89",
      route_short_name: "118",
      route_long_name: "18th Street",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.6511, 39.13866],
        [-94.65044, 39.13851],
        [-94.64926, 39.13821],
        [-94.64961, 39.13737],
        [-94.64992, 39.13654],
        [-94.65098, 39.13682],
        [-94.65132, 39.13689],
        [-94.6514, 39.13409],
        [-94.65137, 39.13385],
        [-94.65134, 39.13377],
        [-94.65126, 39.13363],
        [-94.65112, 39.13349],
        [-94.65046, 39.133],
        [-94.65038, 39.13291],
        [-94.65024, 39.13271],
        [-94.65016, 39.13254],
        [-94.6502, 39.13071],
        [-94.6502, 39.13064],
        [-94.65025, 39.13058],
        [-94.65026, 39.13043],
        [-94.65023, 39.13012],
        [-94.65018, 39.1299],
        [-94.64984, 39.12901],
        [-94.64942, 39.12778],
        [-94.64939, 39.1277],
        [-94.64932, 39.12762],
        [-94.64929, 39.12734],
        [-94.64927, 39.12677],
        [-94.64928, 39.12614],
        [-94.64929, 39.12407],
        [-94.64928, 39.12232],
        [-94.6493, 39.12167],
        [-94.64938, 39.12134],
        [-94.6494, 39.12117],
        [-94.6494, 39.11801],
        [-94.64943, 39.11695],
        [-94.64941, 39.11634],
        [-94.64934, 39.11553],
        [-94.64933, 39.11454],
        [-94.64937, 39.10827],
        [-94.64939, 39.1068],
        [-94.64943, 39.10598],
        [-94.64941, 39.10408],
        [-94.64942, 39.10227],
        [-94.6494, 39.10031],
        [-94.64941, 39.09984],
        [-94.64948, 39.09975],
        [-94.64949, 39.0996],
        [-94.64948, 39.09774],
        [-94.64944, 39.09625],
        [-94.64945, 39.09435],
        [-94.64944, 39.09405],
        [-94.6494, 39.08785],
        [-94.64939, 39.08698],
        [-94.6494, 39.08458],
        [-94.64937, 39.08173],
        [-94.64938, 39.08053],
        [-94.6494, 39.07997],
        [-94.64944, 39.07946],
        [-94.64957, 39.07858],
        [-94.65062, 39.0733],
        [-94.65072, 39.07259],
        [-94.65079, 39.0717],
        [-94.65083, 39.07153],
        [-94.65085, 39.07139],
        [-94.65085, 39.07106],
        [-94.65088, 39.07081],
        [-94.65095, 39.07068],
        [-94.65108, 39.07056],
        [-94.65123, 39.07048],
        [-94.65135, 39.07048],
        [-94.65144, 39.0705],
        [-94.6516, 39.07059],
        [-94.6517, 39.07073],
        [-94.65181, 39.07091],
        [-94.6518, 39.07119],
        [-94.65177, 39.07125],
        [-94.65168, 39.07134],
        [-94.65155, 39.07143],
        [-94.65127, 39.07153],
        [-94.64978, 39.07152],
        [-94.64983, 39.07171],
        [-94.64986, 39.07195],
        [-94.65002, 39.07218],
        [-94.65005, 39.0723],
        [-94.65002, 39.07236],
        [-94.6499, 39.07247],
        [-94.64987, 39.07271],
        [-94.6498, 39.07278],
        [-94.64979, 39.07286],
        [-94.65183, 39.07288],
        [-94.65179, 39.07196],
        [-94.6518, 39.07119],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "118k-89",
      route_short_name: "118",
      route_long_name: "18th Street",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.65181, 39.07091],
        [-94.65183, 39.07037],
        [-94.6514, 39.07036],
        [-94.65123, 39.07034],
        [-94.65105, 39.07025],
        [-94.65094, 39.07014],
        [-94.65087, 39.06998],
        [-94.65065, 39.06903],
        [-94.65054, 39.06875],
        [-94.65041, 39.06826],
        [-94.65018, 39.06766],
        [-94.64994, 39.06716],
        [-94.64967, 39.06666],
        [-94.64927, 39.06604],
        [-94.64904, 39.06573],
        [-94.64824, 39.06471],
        [-94.64775, 39.06404],
        [-94.64764, 39.06386],
        [-94.64728, 39.06313],
        [-94.64713, 39.06279],
        [-94.64691, 39.06213],
        [-94.64684, 39.0618],
        [-94.64675, 39.06122],
        [-94.64673, 39.06093],
        [-94.64674, 39.06034],
        [-94.64678, 39.05997],
        [-94.64699, 39.05841],
        [-94.64713, 39.05754],
        [-94.64724, 39.05667],
        [-94.64755, 39.05458],
        [-94.64762, 39.05373],
        [-94.64763, 39.05286],
        [-94.64759, 39.052],
        [-94.64754, 39.05157],
        [-94.64744, 39.05093],
        [-94.64719, 39.04987],
        [-94.64694, 39.04901],
        [-94.64677, 39.0485],
        [-94.64645, 39.04775],
        [-94.64607, 39.04701],
        [-94.64573, 39.04642],
        [-94.64518, 39.04561],
        [-94.64422, 39.04439],
        [-94.64399, 39.04412],
        [-94.64336, 39.04344],
        [-94.64291, 39.04293],
        [-94.64187, 39.04186],
        [-94.64159, 39.04155],
        [-94.64127, 39.04114],
        [-94.6404, 39.03997],
        [-94.64023, 39.03968],
        [-94.64006, 39.03926],
        [-94.63977, 39.03842],
        [-94.6397, 39.03804],
        [-94.6397, 39.03771],
        [-94.63983, 39.03682],
        [-94.63985, 39.03652],
        [-94.63979, 39.02873],
        [-94.63982, 39.02504],
        [-94.64029, 39.025],
        [-94.64046, 39.02496],
        [-94.64075, 39.02488],
        [-94.6416, 39.02462],
        [-94.6417, 39.02454],
        [-94.64396, 39.02391],
        [-94.64618, 39.02317],
        [-94.64594, 39.02273],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "118k-89",
      route_short_name: "118",
      route_long_name: "18th Street",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.64594, 39.02273],
        [-94.64568, 39.02225],
        [-94.64466, 39.02262],
        [-94.64308, 39.02315],
        [-94.64349, 39.02369],
        [-94.64369, 39.02399],
        [-94.6417, 39.02454],
        [-94.6416, 39.02462],
        [-94.64151, 39.02465],
        [-94.64046, 39.02496],
        [-94.64005, 39.02502],
        [-94.63967, 39.02504],
        [-94.63969, 39.02672],
        [-94.63966, 39.02776],
        [-94.63965, 39.02954],
        [-94.63972, 39.03556],
        [-94.63971, 39.03652],
        [-94.63968, 39.03682],
        [-94.63957, 39.03759],
        [-94.63956, 39.03795],
        [-94.63958, 39.0382],
        [-94.63967, 39.03858],
        [-94.63996, 39.03943],
        [-94.64005, 39.03963],
        [-94.6402, 39.03992],
        [-94.64122, 39.0413],
        [-94.64182, 39.04209],
        [-94.64245, 39.04276],
        [-94.64327, 39.04353],
        [-94.64385, 39.04411],
        [-94.64418, 39.04452],
        [-94.64508, 39.0457],
        [-94.64525, 39.04596],
        [-94.64599, 39.04724],
        [-94.64642, 39.04814],
        [-94.64683, 39.04912],
        [-94.64706, 39.04982],
        [-94.64715, 39.05015],
        [-94.64732, 39.05093],
        [-94.64738, 39.05131],
        [-94.64744, 39.0518],
        [-94.64749, 39.05245],
        [-94.64751, 39.05342],
        [-94.64744, 39.05454],
        [-94.64683, 39.05873],
        [-94.64665, 39.06029],
        [-94.64663, 39.0606],
        [-94.64665, 39.06118],
        [-94.64672, 39.06177],
        [-94.64678, 39.06206],
        [-94.64686, 39.06235],
        [-94.64706, 39.06292],
        [-94.6473, 39.06347],
        [-94.64743, 39.06372],
        [-94.64757, 39.06396],
        [-94.64787, 39.0644],
        [-94.64896, 39.06577],
        [-94.64941, 39.06642],
        [-94.64969, 39.06691],
        [-94.65003, 39.06758],
        [-94.65022, 39.06805],
        [-94.65032, 39.06838],
        [-94.65035, 39.06848],
        [-94.65036, 39.06881],
        [-94.65044, 39.06937],
        [-94.65044, 39.06967],
        [-94.65042, 39.06981],
        [-94.65038, 39.06996],
        [-94.65024, 39.07023],
        [-94.65017, 39.07035],
        [-94.64974, 39.07088],
        [-94.64967, 39.07103],
        [-94.64969, 39.07151],
        [-94.64978, 39.07152],
        [-94.64983, 39.07171],
        [-94.64986, 39.07195],
        [-94.65002, 39.07218],
        [-94.65005, 39.0723],
        [-94.65002, 39.07236],
        [-94.6499, 39.07247],
        [-94.64987, 39.07271],
        [-94.6498, 39.07278],
        [-94.64979, 39.07286],
        [-94.65183, 39.07288],
        [-94.65179, 39.07196],
        [-94.6518, 39.07152],
        [-94.65083, 39.07153],
        [-94.65011, 39.07152],
        [-94.64994, 39.07143],
        [-94.64984, 39.07133],
        [-94.6498, 39.07126],
        [-94.64976, 39.07111],
        [-94.64978, 39.071],
        [-94.64985, 39.07087],
        [-94.6499, 39.07082],
        [-94.64996, 39.07078],
        [-94.65009, 39.07073],
        [-94.65024, 39.07074],
        [-94.65031, 39.07076],
        [-94.65043, 39.07083],
        [-94.65048, 39.07088],
        [-94.65056, 39.07102],
        [-94.65067, 39.07144],
        [-94.65063, 39.07227],
        [-94.6506, 39.07274],
        [-94.65043, 39.07369],
        [-94.65023, 39.0746],
        [-94.65004, 39.07561],
        [-94.64969, 39.07735],
        [-94.64943, 39.07853],
        [-94.64937, 39.07891],
        [-94.64929, 39.07989],
        [-94.64926, 39.08055],
        [-94.64927, 39.08382],
        [-94.64929, 39.08497],
        [-94.64929, 39.08679],
        [-94.64932, 39.0936],
        [-94.64934, 39.09601],
        [-94.64934, 39.09855],
        [-94.64935, 39.09975],
        [-94.64941, 39.09984],
        [-94.6494, 39.10031],
        [-94.64942, 39.10227],
        [-94.64941, 39.10408],
        [-94.64943, 39.10598],
        [-94.64939, 39.1068],
        [-94.64937, 39.10827],
        [-94.64933, 39.11454],
        [-94.64934, 39.11553],
        [-94.64941, 39.11634],
        [-94.64943, 39.11695],
        [-94.6494, 39.11801],
        [-94.6494, 39.12117],
        [-94.64938, 39.12134],
        [-94.6493, 39.12167],
        [-94.64928, 39.12232],
        [-94.64929, 39.12407],
        [-94.64928, 39.12614],
        [-94.64927, 39.12677],
        [-94.64929, 39.12734],
        [-94.64932, 39.12762],
        [-94.64927, 39.12772],
        [-94.64931, 39.12796],
        [-94.64946, 39.1284],
        [-94.64967, 39.12898],
        [-94.65005, 39.12992],
        [-94.65013, 39.13017],
        [-94.65017, 39.13059],
        [-94.6502, 39.13064],
        [-94.65016, 39.13254],
        [-94.65024, 39.13271],
        [-94.65038, 39.13291],
        [-94.65046, 39.133],
        [-94.65097, 39.13337],
        [-94.65121, 39.13358],
        [-94.65134, 39.13377],
        [-94.65139, 39.13395],
        [-94.6514, 39.13409],
        [-94.65127, 39.13869],
        [-94.6511, 39.13866],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "402-134",
      route_short_name: "402",
      route_long_name: "Johnson-Quivira",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.71591, 38.95488],
        [-94.71608, 38.95504],
        [-94.71635, 38.95523],
        [-94.71665, 38.95541],
        [-94.71678, 38.95547],
        [-94.71705, 38.95555],
        [-94.71754, 38.9556],
        [-94.71757, 38.95661],
        [-94.72351, 38.95661],
        [-94.72356, 38.95906],
        [-94.72356, 38.95999],
        [-94.7236, 38.96005],
        [-94.72361, 38.9614],
        [-94.7236, 38.9639],
        [-94.72355, 38.96396],
        [-94.72353, 38.9648],
        [-94.72352, 38.96752],
        [-94.72355, 38.9686],
        [-94.72354, 38.96933],
        [-94.72356, 38.97079],
        [-94.72358, 38.97118],
        [-94.72355, 38.97974],
        [-94.72357, 38.98256],
        [-94.72358, 38.98785],
        [-94.72361, 38.98921],
        [-94.72362, 38.99264],
        [-94.72361, 38.99277],
        [-94.72213, 38.99278],
        [-94.72201, 38.99283],
        [-94.7216, 38.99284],
        [-94.72008, 38.99283],
        [-94.71656, 38.99287],
        [-94.71635, 38.99287],
        [-94.71626, 38.99291],
        [-94.71432, 38.99297],
        [-94.71436, 38.994],
        [-94.71441, 38.99408],
        [-94.71445, 38.99868],
        [-94.71445, 39.00216],
        [-94.71453, 39.00744],
        [-94.71456, 39.01524],
        [-94.71454, 39.01562],
        [-94.71456, 39.01693],
        [-94.71458, 39.02292],
        [-94.70993, 39.02297],
        [-94.70952, 39.02295],
        [-94.70907, 39.02289],
        [-94.70527, 39.02224],
        [-94.70451, 39.02214],
        [-94.70369, 39.02212],
        [-94.69828, 39.02217],
        [-94.69271, 39.02218],
        [-94.68933, 39.02226],
        [-94.6892, 39.02221],
        [-94.68585, 39.02219],
        [-94.68551, 39.0222],
        [-94.6854, 39.02224],
        [-94.68022, 39.02227],
        [-94.67235, 39.02223],
        [-94.66944, 39.02224],
        [-94.66934, 39.02219],
        [-94.6693, 39.02219],
        [-94.66601, 39.0222],
        [-94.6622, 39.02215],
        [-94.65979, 39.02215],
        [-94.65838, 39.02214],
        [-94.65838, 39.02013],
        [-94.65751, 39.01985],
        [-94.65724, 39.01979],
        [-94.65699, 39.01977],
        [-94.6567, 39.01976],
        [-94.65634, 39.01979],
        [-94.6554, 39.01994],
        [-94.65469, 39.02002],
        [-94.65444, 39.02001],
        [-94.6542, 39.01997],
        [-94.65357, 39.0198],
        [-94.65349, 39.0198],
        [-94.65288, 39.0199],
        [-94.65208, 39.02009],
        [-94.65187, 39.02016],
        [-94.65166, 39.02026],
        [-94.6514, 39.02043],
        [-94.65119, 39.02053],
        [-94.64998, 39.02125],
        [-94.6495, 39.02152],
        [-94.64935, 39.02155],
        [-94.64813, 39.02155],
        [-94.64778, 39.02157],
        [-94.64568, 39.02225],
        [-94.64618, 39.02317],
        [-94.64396, 39.02391],
        [-94.6417, 39.02454],
        [-94.6416, 39.02462],
        [-94.64075, 39.02488],
        [-94.64046, 39.02496],
        [-94.64021, 39.02501],
        [-94.63967, 39.02504],
        [-94.63969, 39.02672],
        [-94.63966, 39.02776],
        [-94.63965, 39.02954],
        [-94.63972, 39.03556],
        [-94.63971, 39.03652],
        [-94.63968, 39.03682],
        [-94.63957, 39.03759],
        [-94.63956, 39.03781],
        [-94.63957, 39.03811],
        [-94.63961, 39.03838],
        [-94.63996, 39.03943],
        [-94.64014, 39.03981],
        [-94.64036, 39.04015],
        [-94.64122, 39.0413],
        [-94.64168, 39.04191],
        [-94.64194, 39.04222],
        [-94.64245, 39.04276],
        [-94.64327, 39.04353],
        [-94.64385, 39.04411],
        [-94.64418, 39.04452],
        [-94.64508, 39.0457],
        [-94.64525, 39.04596],
        [-94.64571, 39.04673],
        [-94.64599, 39.04724],
        [-94.64621, 39.04768],
        [-94.64656, 39.04845],
        [-94.64692, 39.04938],
        [-94.64715, 39.05015],
        [-94.64732, 39.05093],
        [-94.64738, 39.05131],
        [-94.64744, 39.0518],
        [-94.64749, 39.05245],
        [-94.64751, 39.05342],
        [-94.64744, 39.05454],
        [-94.64683, 39.05873],
        [-94.64665, 39.06029],
        [-94.64663, 39.0606],
        [-94.64663, 39.06089],
        [-94.64665, 39.06118],
        [-94.64672, 39.06177],
        [-94.64686, 39.06235],
        [-94.64706, 39.06292],
        [-94.6473, 39.06347],
        [-94.64757, 39.06396],
        [-94.64777, 39.06426],
        [-94.64814, 39.06475],
        [-94.64896, 39.06577],
        [-94.64922, 39.06613],
        [-94.6496, 39.06673],
        [-94.65003, 39.06758],
        [-94.65022, 39.06805],
        [-94.65035, 39.06848],
        [-94.65046, 39.069],
        [-94.6506, 39.06994],
        [-94.65065, 39.07051],
        [-94.65069, 39.07122],
        [-94.6506, 39.07274],
        [-94.65043, 39.07369],
        [-94.65023, 39.0746],
        [-94.64969, 39.07735],
        [-94.64943, 39.07853],
        [-94.64937, 39.07891],
        [-94.64926, 39.08032],
        [-94.64926, 39.0816],
        [-94.64927, 39.08382],
        [-94.64929, 39.08497],
        [-94.64929, 39.08679],
        [-94.64932, 39.0936],
        [-94.64934, 39.09601],
        [-94.64934, 39.09855],
        [-94.64935, 39.09975],
        [-94.64941, 39.09984],
        [-94.6494, 39.10031],
        [-94.64942, 39.10227],
        [-94.64941, 39.10408],
        [-94.64943, 39.10598],
        [-94.64593, 39.10466],
        [-94.64246, 39.10341],
        [-94.63776, 39.10163],
        [-94.63562, 39.10086],
        [-94.63514, 39.10071],
        [-94.63463, 39.1005],
        [-94.63432, 39.10042],
        [-94.63418, 39.10041],
        [-94.63185, 39.10039],
        [-94.63154, 39.1004],
        [-94.63133, 39.10046],
        [-94.63097, 39.10064],
        [-94.62898, 39.10175],
        [-94.62828, 39.10216],
        [-94.62728, 39.10272],
        [-94.62718, 39.10301],
        [-94.62713, 39.10323],
        [-94.62706, 39.10339],
        [-94.62688, 39.1036],
        [-94.62666, 39.10375],
        [-94.62672, 39.10395],
        [-94.62676, 39.10452],
        [-94.6268, 39.1063],
        [-94.62679, 39.10723],
        [-94.6268, 39.10813],
        [-94.62679, 39.10986],
        [-94.62681, 39.11116],
        [-94.62679, 39.11459],
        [-94.6268, 39.11551],
        [-94.62884, 39.11554],
        [-94.64826, 39.11551],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "402-134",
      route_short_name: "402",
      route_long_name: "Johnson-Quivira",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.77941, 38.91792],
        [-94.77935, 38.92034],
        [-94.77935, 38.92258],
        [-94.77932, 38.9248],
        [-94.77932, 38.92728],
        [-94.76459, 38.92724],
        [-94.7585, 38.92719],
        [-94.7558, 38.92721],
        [-94.75449, 38.92723],
        [-94.75002, 38.92728],
        [-94.74206, 38.92731],
        [-94.73731, 38.9273],
        [-94.72941, 38.92733],
        [-94.72941, 38.92713],
        [-94.72937, 38.92698],
        [-94.72927, 38.92685],
        [-94.72919, 38.92678],
        [-94.72915, 38.92675],
        [-94.72849, 38.92661],
        [-94.72843, 38.92656],
        [-94.72822, 38.92656],
        [-94.72818, 38.92637],
        [-94.72807, 38.92612],
        [-94.72803, 38.9259],
        [-94.728, 38.92581],
        [-94.72789, 38.92575],
        [-94.72778, 38.92578],
        [-94.72774, 38.92584],
        [-94.72772, 38.92604],
        [-94.72786, 38.92605],
        [-94.72805, 38.92603],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "402-134",
      route_short_name: "402",
      route_long_name: "Johnson-Quivira",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.72941, 38.92733],
        [-94.72941, 38.92804],
        [-94.72945, 38.92863],
        [-94.72949, 38.92868],
        [-94.72948, 38.92928],
        [-94.72943, 38.92941],
        [-94.72932, 38.92954],
        [-94.72916, 38.92967],
        [-94.72898, 38.92975],
        [-94.72814, 38.93001],
        [-94.72779, 38.93008],
        [-94.72746, 38.93009],
        [-94.72342, 38.93008],
        [-94.72341, 38.9324],
        [-94.7235, 38.93361],
        [-94.7235, 38.93403],
        [-94.72352, 38.93412],
        [-94.72351, 38.93629],
        [-94.72344, 38.93809],
        [-94.72343, 38.9417],
        [-94.72346, 38.94613],
        [-94.72349, 38.9469],
        [-94.7235, 38.94849],
        [-94.7235, 38.95185],
        [-94.72271, 38.95183],
        [-94.72243, 38.95175],
        [-94.72202, 38.95156],
        [-94.72155, 38.9514],
        [-94.72098, 38.95127],
        [-94.72074, 38.95124],
        [-94.72048, 38.95123],
        [-94.72048, 38.95181],
        [-94.71949, 38.95182],
        [-94.71912, 38.95194],
        [-94.71778, 38.95194],
        [-94.71729, 38.95189],
        [-94.71697, 38.95188],
        [-94.7165, 38.95188],
        [-94.71631, 38.95191],
        [-94.71613, 38.952],
        [-94.71607, 38.95205],
        [-94.716, 38.95216],
        [-94.71591, 38.95251],
        [-94.71575, 38.95323],
        [-94.71558, 38.95378],
        [-94.71551, 38.95405],
        [-94.71551, 38.95422],
        [-94.71554, 38.95433],
        [-94.71559, 38.95448],
        [-94.71567, 38.95462],
        [-94.71591, 38.95488],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "402-134",
      route_short_name: "402",
      route_long_name: "Johnson-Quivira",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.68312, 39.02225],
        [-94.68257, 39.02226],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "402-134",
      route_short_name: "402",
      route_long_name: "Johnson-Quivira",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.66542, 39.02219],
        [-94.66514, 39.02219],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "402-134",
      route_short_name: "402",
      route_long_name: "Johnson-Quivira",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.65914, 39.02215],
        [-94.65869, 39.02215],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "402-134",
      route_short_name: "402",
      route_long_name: "Johnson-Quivira",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.65838, 39.02214],
        [-94.65766, 39.0221],
        [-94.65756, 39.02215],
        [-94.65371, 39.02214],
        [-94.6494, 39.02225],
        [-94.64903, 39.02229],
        [-94.64861, 39.02238],
        [-94.64751, 39.02272],
        [-94.64699, 39.02182],
        [-94.64568, 39.02225],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "402-134",
      route_short_name: "402",
      route_long_name: "Johnson-Quivira",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.77948, 38.89734],
        [-94.7795, 38.89847],
        [-94.77949, 38.90058],
        [-94.77956, 38.90393],
        [-94.77952, 38.9042],
        [-94.77945, 38.90443],
        [-94.77929, 38.90476],
        [-94.77911, 38.90499],
        [-94.77783, 38.90633],
        [-94.77684, 38.90729],
        [-94.77576, 38.90827],
        [-94.77542, 38.90856],
        [-94.7751, 38.9088],
        [-94.77474, 38.90903],
        [-94.77427, 38.90928],
        [-94.77387, 38.90946],
        [-94.77328, 38.90967],
        [-94.77277, 38.90981],
        [-94.77203, 38.90994],
        [-94.7715, 38.91],
        [-94.77084, 38.91001],
        [-94.76949, 38.91],
        [-94.769, 38.91004],
        [-94.76833, 38.91017],
        [-94.76779, 38.91036],
        [-94.76738, 38.91057],
        [-94.76701, 38.91078],
        [-94.76657, 38.91124],
        [-94.76629, 38.9117],
        [-94.76621, 38.91189],
        [-94.76592, 38.91284],
        [-94.77125, 38.91288],
        [-94.77322, 38.91292],
        [-94.77436, 38.91292],
        [-94.77535, 38.91291],
        [-94.77693, 38.91285],
        [-94.77956, 38.91285],
        [-94.77951, 38.91651],
        [-94.77947, 38.91732],
        [-94.77942, 38.91772],
        [-94.77941, 38.91792],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "402-134",
      route_short_name: "402",
      route_long_name: "Johnson-Quivira",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.64717, 39.10513],
        [-94.64593, 39.10466],
        [-94.64246, 39.10341],
        [-94.63776, 39.10163],
        [-94.63562, 39.10086],
        [-94.63514, 39.10071],
        [-94.63453, 39.10047],
        [-94.63432, 39.10042],
        [-94.63418, 39.10041],
        [-94.63185, 39.10039],
        [-94.63154, 39.1004],
        [-94.63133, 39.10046],
        [-94.63121, 39.10051],
        [-94.62898, 39.10175],
        [-94.62828, 39.10216],
        [-94.62728, 39.10272],
        [-94.62718, 39.10301],
        [-94.62713, 39.10323],
        [-94.62706, 39.10339],
        [-94.62688, 39.1036],
        [-94.62666, 39.10375],
        [-94.62672, 39.10395],
        [-94.62676, 39.10452],
        [-94.6268, 39.1063],
        [-94.62679, 39.10723],
        [-94.6268, 39.10813],
        [-94.62679, 39.10986],
        [-94.62681, 39.11116],
        [-94.62679, 39.11459],
        [-94.6268, 39.11551],
        [-94.62884, 39.11554],
        [-94.64801, 39.11551],
        [-94.64934, 39.11553],
        [-94.64933, 39.11454],
        [-94.64936, 39.1118],
        [-94.64936, 39.1088],
        [-94.64939, 39.1068],
        [-94.64943, 39.10598],
        [-94.64941, 39.10408],
        [-94.64942, 39.10227],
        [-94.6494, 39.10031],
        [-94.64941, 39.09984],
        [-94.64948, 39.09975],
        [-94.64949, 39.0996],
        [-94.64948, 39.09774],
        [-94.64944, 39.09625],
        [-94.64945, 39.09435],
        [-94.64944, 39.09405],
        [-94.6494, 39.08785],
        [-94.64939, 39.08698],
        [-94.6494, 39.08458],
        [-94.64937, 39.08173],
        [-94.64938, 39.08053],
        [-94.6494, 39.07997],
        [-94.64944, 39.07946],
        [-94.64957, 39.07858],
        [-94.65058, 39.07356],
        [-94.65072, 39.07259],
        [-94.65078, 39.07187],
        [-94.65079, 39.07132],
        [-94.65076, 39.07045],
        [-94.65066, 39.06954],
        [-94.65054, 39.06875],
        [-94.65047, 39.06848],
        [-94.65031, 39.06799],
        [-94.65002, 39.06731],
        [-94.64967, 39.06666],
        [-94.64927, 39.06604],
        [-94.64904, 39.06573],
        [-94.64824, 39.06471],
        [-94.64775, 39.06404],
        [-94.64764, 39.06386],
        [-94.64728, 39.06313],
        [-94.64713, 39.06279],
        [-94.64696, 39.06228],
        [-94.64684, 39.0618],
        [-94.64675, 39.06122],
        [-94.64673, 39.06093],
        [-94.64674, 39.06034],
        [-94.64678, 39.05997],
        [-94.64699, 39.05841],
        [-94.64713, 39.05754],
        [-94.64724, 39.05667],
        [-94.64755, 39.05458],
        [-94.64762, 39.05373],
        [-94.64763, 39.05329],
        [-94.64763, 39.05277],
        [-94.64759, 39.052],
        [-94.64754, 39.05157],
        [-94.64748, 39.05114],
        [-94.64739, 39.05072],
        [-94.64719, 39.04987],
        [-94.64684, 39.04871],
        [-94.64666, 39.04822],
        [-94.64645, 39.04775],
        [-94.64619, 39.04723],
        [-94.64573, 39.04642],
        [-94.64518, 39.04561],
        [-94.64422, 39.04439],
        [-94.64399, 39.04412],
        [-94.64336, 39.04344],
        [-94.64291, 39.04293],
        [-94.64187, 39.04186],
        [-94.64159, 39.04155],
        [-94.64127, 39.04114],
        [-94.6404, 39.03997],
        [-94.64023, 39.03968],
        [-94.64015, 39.03951],
        [-94.63992, 39.03887],
        [-94.63977, 39.03842],
        [-94.63973, 39.03824],
        [-94.6397, 39.03798],
        [-94.6397, 39.03771],
        [-94.63983, 39.03682],
        [-94.63985, 39.03652],
        [-94.63979, 39.02873],
        [-94.63982, 39.02504],
        [-94.64014, 39.02502],
        [-94.64046, 39.02496],
        [-94.64151, 39.02465],
        [-94.6416, 39.02462],
        [-94.6417, 39.02454],
        [-94.64396, 39.02391],
        [-94.64618, 39.02317],
        [-94.64614, 39.02308],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "402-134",
      route_short_name: "402",
      route_long_name: "Johnson-Quivira",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.64618, 39.02317],
        [-94.64751, 39.02272],
        [-94.6485, 39.02241],
        [-94.64871, 39.02235],
        [-94.64912, 39.02228],
        [-94.64915, 39.02155],
        [-94.64942, 39.02154],
        [-94.64956, 39.0215],
        [-94.65119, 39.02053],
        [-94.6514, 39.02043],
        [-94.65166, 39.02026],
        [-94.65187, 39.02016],
        [-94.65208, 39.02009],
        [-94.65288, 39.0199],
        [-94.65349, 39.0198],
        [-94.65357, 39.0198],
        [-94.6542, 39.01997],
        [-94.65444, 39.02001],
        [-94.65469, 39.02002],
        [-94.6554, 39.01994],
        [-94.65634, 39.01979],
        [-94.6567, 39.01976],
        [-94.65699, 39.01977],
        [-94.65738, 39.01982],
        [-94.65766, 39.01989],
        [-94.65838, 39.02013],
        [-94.65838, 39.02223],
        [-94.65852, 39.02224],
        [-94.66482, 39.02229],
        [-94.66719, 39.02229],
        [-94.66778, 39.02227],
        [-94.66886, 39.02229],
        [-94.66934, 39.02228],
        [-94.66944, 39.02224],
        [-94.67235, 39.02223],
        [-94.68022, 39.02227],
        [-94.6854, 39.02224],
        [-94.68552, 39.02229],
        [-94.68652, 39.02229],
        [-94.68853, 39.02233],
        [-94.68919, 39.02232],
        [-94.68933, 39.02226],
        [-94.69252, 39.02219],
        [-94.69828, 39.02217],
        [-94.70369, 39.02212],
        [-94.70451, 39.02214],
        [-94.70527, 39.02224],
        [-94.70907, 39.02289],
        [-94.70952, 39.02295],
        [-94.70993, 39.02297],
        [-94.71458, 39.02292],
        [-94.71456, 39.01693],
        [-94.71454, 39.01562],
        [-94.71456, 39.01524],
        [-94.71453, 39.00744],
        [-94.71445, 39.00216],
        [-94.71445, 38.99868],
        [-94.71441, 38.99408],
        [-94.71447, 38.994],
        [-94.71447, 38.99297],
        [-94.71626, 38.99291],
        [-94.71635, 38.99287],
        [-94.72008, 38.99283],
        [-94.7216, 38.99284],
        [-94.72201, 38.99283],
        [-94.72216, 38.99288],
        [-94.72377, 38.99288],
        [-94.72377, 38.99277],
        [-94.72375, 38.9917],
        [-94.7237, 38.97593],
        [-94.72371, 38.97469],
        [-94.72378, 38.97301],
        [-94.72378, 38.97244],
        [-94.72376, 38.97065],
        [-94.72369, 38.96928],
        [-94.72367, 38.96648],
        [-94.72367, 38.96481],
        [-94.72365, 38.96397],
        [-94.7236, 38.9639],
        [-94.72359, 38.96334],
        [-94.72361, 38.9614],
        [-94.7236, 38.96005],
        [-94.72365, 38.95999],
        [-94.72368, 38.95906],
        [-94.7237, 38.95743],
        [-94.72369, 38.95485],
        [-94.72371, 38.95185],
        [-94.72271, 38.95183],
        [-94.72243, 38.95175],
        [-94.72202, 38.95156],
        [-94.72155, 38.9514],
        [-94.72098, 38.95127],
        [-94.72074, 38.95124],
        [-94.72048, 38.95123],
        [-94.72048, 38.95181],
        [-94.71949, 38.95182],
        [-94.71912, 38.95194],
        [-94.71778, 38.95194],
        [-94.71729, 38.95189],
        [-94.71697, 38.95188],
        [-94.7165, 38.95188],
        [-94.71631, 38.95191],
        [-94.71613, 38.952],
        [-94.71607, 38.95205],
        [-94.716, 38.95216],
        [-94.71591, 38.95251],
        [-94.71575, 38.95323],
        [-94.71558, 38.95378],
        [-94.71551, 38.95405],
        [-94.71551, 38.95422],
        [-94.71554, 38.95433],
        [-94.71559, 38.95448],
        [-94.71567, 38.95462],
        [-94.71591, 38.95488],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "402-134",
      route_short_name: "402",
      route_long_name: "Johnson-Quivira",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.64526, 39.02348],
        [-94.64618, 39.02317],
        [-94.64568, 39.02225],
        [-94.64699, 39.02182],
        [-94.64751, 39.02272],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "402-134",
      route_short_name: "402",
      route_long_name: "Johnson-Quivira",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.64912, 39.02228],
        [-94.6494, 39.02225],
        [-94.65371, 39.02214],
        [-94.65756, 39.02215],
        [-94.65766, 39.0221],
        [-94.65869, 39.02215],
        [-94.6622, 39.02215],
        [-94.66601, 39.0222],
        [-94.6693, 39.02219],
        [-94.66934, 39.02219],
        [-94.66944, 39.02224],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "402-134",
      route_short_name: "402",
      route_long_name: "Johnson-Quivira",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.71591, 38.95488],
        [-94.71598, 38.95495],
        [-94.7159, 38.95505],
        [-94.71575, 38.95511],
        [-94.7156, 38.95512],
        [-94.71522, 38.95506],
        [-94.71511, 38.95509],
        [-94.71501, 38.95507],
        [-94.71562, 38.95268],
        [-94.71574, 38.95199],
        [-94.71578, 38.95191],
        [-94.71583, 38.95187],
        [-94.71593, 38.95172],
        [-94.71598, 38.95168],
        [-94.71611, 38.95158],
        [-94.71632, 38.95148],
        [-94.71649, 38.95142],
        [-94.71668, 38.95138],
        [-94.71724, 38.95132],
        [-94.71953, 38.95132],
        [-94.72048, 38.9513],
        [-94.72067, 38.95132],
        [-94.72096, 38.95136],
        [-94.72144, 38.95147],
        [-94.72189, 38.95162],
        [-94.72245, 38.95185],
        [-94.7226, 38.9519],
        [-94.72284, 38.95194],
        [-94.72305, 38.95195],
        [-94.72371, 38.95195],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "402-134",
      route_short_name: "402",
      route_long_name: "Johnson-Quivira",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.72371, 38.95185],
        [-94.72363, 38.94474],
        [-94.72361, 38.94198],
        [-94.72361, 38.93878],
        [-94.72376, 38.93709],
        [-94.72381, 38.93671],
        [-94.72373, 38.93628],
        [-94.72374, 38.9351],
        [-94.7237, 38.93361],
        [-94.72361, 38.93241],
        [-94.72363, 38.93008],
        [-94.72746, 38.93009],
        [-94.72779, 38.93008],
        [-94.72814, 38.93001],
        [-94.72898, 38.92975],
        [-94.72916, 38.92967],
        [-94.72932, 38.92954],
        [-94.72943, 38.92941],
        [-94.72948, 38.92928],
        [-94.72949, 38.92868],
        [-94.72945, 38.92863],
        [-94.72941, 38.92804],
        [-94.7294, 38.92751],
        [-94.72942, 38.92723],
        [-94.7294, 38.92706],
        [-94.72933, 38.92691],
        [-94.72927, 38.92685],
        [-94.72915, 38.92675],
        [-94.72849, 38.92661],
        [-94.72843, 38.92656],
        [-94.72822, 38.92656],
        [-94.72818, 38.92637],
        [-94.72807, 38.92612],
        [-94.72803, 38.9259],
        [-94.728, 38.92581],
        [-94.72789, 38.92575],
        [-94.72778, 38.92578],
        [-94.72774, 38.92584],
        [-94.72772, 38.92604],
        [-94.72786, 38.92605],
        [-94.72805, 38.92603],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "402-134",
      route_short_name: "402",
      route_long_name: "Johnson-Quivira",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.72941, 38.92733],
        [-94.72957, 38.92733],
        [-94.72956, 38.92751],
        [-94.73552, 38.9275],
        [-94.73782, 38.92748],
        [-94.73995, 38.92748],
        [-94.74224, 38.92751],
        [-94.75435, 38.92743],
        [-94.75865, 38.92738],
        [-94.77425, 38.92746],
        [-94.77955, 38.92747],
        [-94.77963, 38.92258],
        [-94.77962, 38.92099],
        [-94.77968, 38.91781],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "402-134",
      route_short_name: "402",
      route_long_name: "Johnson-Quivira",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.77968, 38.91781],
        [-94.77968, 38.9164],
        [-94.77976, 38.91285],
        [-94.77956, 38.91285],
        [-94.77956, 38.91274],
        [-94.77683, 38.91273],
        [-94.775, 38.91271],
        [-94.77342, 38.91273],
        [-94.76836, 38.91268],
        [-94.76674, 38.91269],
        [-94.76651, 38.91261],
        [-94.76643, 38.91256],
        [-94.76637, 38.91242],
        [-94.76635, 38.91219],
        [-94.76648, 38.91188],
        [-94.76659, 38.91166],
        [-94.76672, 38.91147],
        [-94.76704, 38.91112],
        [-94.76722, 38.91095],
        [-94.76771, 38.91064],
        [-94.76819, 38.91042],
        [-94.76869, 38.91025],
        [-94.76896, 38.9102],
        [-94.76923, 38.91016],
        [-94.76948, 38.91014],
        [-94.77138, 38.91011],
        [-94.772, 38.91006],
        [-94.77264, 38.90996],
        [-94.77328, 38.9098],
        [-94.77357, 38.90971],
        [-94.77406, 38.90952],
        [-94.77462, 38.90924],
        [-94.77493, 38.90907],
        [-94.77531, 38.90881],
        [-94.77621, 38.90806],
        [-94.77721, 38.90712],
        [-94.77847, 38.90588],
        [-94.77916, 38.90516],
        [-94.77938, 38.9049],
        [-94.77959, 38.90451],
        [-94.77969, 38.90421],
        [-94.77972, 38.904],
        [-94.77972, 38.90358],
        [-94.77966, 38.9023],
        [-94.77964, 38.90135],
        [-94.77964, 38.89741],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "595-134",
      route_short_name: "595",
      route_long_name: "Gardner-OP Express",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.96878, 38.77617],
        [-94.96963, 38.77747],
        [-94.96938, 38.78109],
        [-94.96709, 38.7815],
        [-94.96682, 38.78147],
        [-94.96594, 38.78173],
        [-94.96546, 38.78189],
        [-94.96502, 38.78198],
        [-94.96452, 38.78201],
        [-94.95917, 38.78194],
        [-94.95887, 38.78208],
        [-94.95264, 38.78208],
        [-94.95266, 38.78776],
        [-94.95263, 38.78847],
        [-94.95259, 38.78862],
        [-94.95254, 38.78871],
        [-94.95244, 38.78876],
        [-94.95226, 38.78877],
        [-94.94579, 38.78895],
        [-94.94577, 38.78619],
        [-94.94579, 38.78337],
        [-94.94579, 38.78054],
        [-94.94591, 38.77486],
        [-94.94592, 38.77197],
        [-94.94596, 38.77123],
        [-94.94532, 38.77122],
        [-94.94442, 38.77124],
        [-94.94369, 38.77147],
        [-94.94308, 38.77183],
        [-94.94265, 38.77219],
        [-94.94244, 38.77253],
        [-94.94234, 38.77288],
        [-94.94233, 38.77324],
        [-94.94229, 38.77706],
        [-94.94229, 38.77803],
        [-94.94221, 38.78202],
        [-94.93651, 38.78196],
        [-94.93114, 38.78196],
        [-94.93071, 38.78198],
        [-94.93041, 38.78206],
        [-94.93006, 38.78219],
        [-94.92993, 38.78226],
        [-94.92944, 38.78257],
        [-94.92863, 38.78319],
        [-94.92832, 38.78339],
        [-94.92812, 38.78347],
        [-94.92784, 38.78357],
        [-94.92739, 38.78366],
        [-94.92748, 38.7904],
        [-94.9275, 38.79303],
        [-94.92751, 38.79766],
        [-94.9275, 38.80219],
        [-94.92753, 38.80378],
        [-94.92753, 38.80692],
        [-94.9275, 38.80801],
        [-94.92744, 38.80916],
        [-94.92745, 38.81104],
        [-94.92671, 38.811],
        [-94.92452, 38.811],
        [-94.92299, 38.81105],
        [-94.92212, 38.81105],
        [-94.91331, 38.81098],
        [-94.91256, 38.81102],
        [-94.91199, 38.81108],
        [-94.9097, 38.81152],
        [-94.90628, 38.81211],
        [-94.90554, 38.81226],
        [-94.90512, 38.81238],
        [-94.90483, 38.81248],
        [-94.90443, 38.81267],
        [-94.90408, 38.81287],
        [-94.90386, 38.81301],
        [-94.90334, 38.81344],
        [-94.90304, 38.81375],
        [-94.90271, 38.81405],
        [-94.90207, 38.81466],
        [-94.90163, 38.81511],
        [-94.90152, 38.81529],
        [-94.90145, 38.81552],
        [-94.90144, 38.81564],
        [-94.90146, 38.81589],
        [-94.90149, 38.81601],
        [-94.9017, 38.81654],
        [-94.90173, 38.81667],
        [-94.90191, 38.81701],
        [-94.90203, 38.81731],
        [-94.90208, 38.81763],
        [-94.90207, 38.81793],
        [-94.90205, 38.81808],
        [-94.9018, 38.81806],
        [-94.90172, 38.81827],
        [-94.90157, 38.81853],
        [-94.90138, 38.81877],
        [-94.90114, 38.81897],
        [-94.90085, 38.81915],
        [-94.89995, 38.81956],
        [-94.89982, 38.81964],
        [-94.89963, 38.8198],
        [-94.89947, 38.81997],
        [-94.89933, 38.82018],
        [-94.89926, 38.82036],
        [-94.89923, 38.82053],
        [-94.89922, 38.82107],
        [-94.89923, 38.82271],
        [-94.89923, 38.82727],
        [-94.89919, 38.83026],
        [-94.8992, 38.83074],
        [-94.8992, 38.83461],
        [-94.89919, 38.83516],
        [-94.89916, 38.8355],
        [-94.89908, 38.83572],
        [-94.89894, 38.83595],
        [-94.89871, 38.83621],
        [-94.89842, 38.83644],
        [-94.89826, 38.83655],
        [-94.8979, 38.83674],
        [-94.89774, 38.83681],
        [-94.89693, 38.83708],
        [-94.89666, 38.8372],
        [-94.89646, 38.83733],
        [-94.89632, 38.83745],
        [-94.89618, 38.83761],
        [-94.89608, 38.8378],
        [-94.89604, 38.83796],
        [-94.89602, 38.83816],
        [-94.89602, 38.84],
        [-94.89599, 38.84101],
        [-94.89602, 38.84106],
        [-94.89599, 38.84196],
        [-94.89599, 38.84465],
        [-94.89598, 38.84532],
        [-94.89594, 38.84571],
        [-94.89583, 38.84613],
        [-94.89564, 38.84661],
        [-94.89488, 38.84836],
        [-94.89394, 38.85059],
        [-94.89378, 38.85101],
        [-94.89354, 38.85174],
        [-94.8935, 38.85177],
        [-94.89348, 38.85182],
        [-94.89329, 38.85286],
        [-94.89325, 38.85315],
        [-94.89319, 38.85442],
        [-94.89107, 38.85442],
        [-94.891, 38.85445],
        [-94.88737, 38.85448],
        [-94.88201, 38.85445],
        [-94.88007, 38.85448],
        [-94.87254, 38.85449],
        [-94.87194, 38.85445],
        [-94.87154, 38.85436],
        [-94.87138, 38.8543],
        [-94.87109, 38.85417],
        [-94.87067, 38.85391],
        [-94.87042, 38.85387],
        [-94.87018, 38.85389],
        [-94.86999, 38.85393],
        [-94.86974, 38.85408],
        [-94.86911, 38.85438],
        [-94.86873, 38.85449],
        [-94.868, 38.85458],
        [-94.86658, 38.8546],
        [-94.86019, 38.85462],
        [-94.85971, 38.85465],
        [-94.85921, 38.85474],
        [-94.85868, 38.8549],
        [-94.85819, 38.85512],
        [-94.85781, 38.85536],
        [-94.85745, 38.85567],
        [-94.85724, 38.85591],
        [-94.85705, 38.85618],
        [-94.85646, 38.85725],
        [-94.85631, 38.85752],
        [-94.85611, 38.85781],
        [-94.85591, 38.85805],
        [-94.85563, 38.85834],
        [-94.85543, 38.85851],
        [-94.85521, 38.85867],
        [-94.85495, 38.85882],
        [-94.85186, 38.86047],
        [-94.85099, 38.86097],
        [-94.84909, 38.86216],
        [-94.84814, 38.8628],
        [-94.84401, 38.86543],
        [-94.84318, 38.86591],
        [-94.84254, 38.86623],
        [-94.84194, 38.8665],
        [-94.84153, 38.86667],
        [-94.84112, 38.86681],
        [-94.84026, 38.86708],
        [-94.83971, 38.86722],
        [-94.83916, 38.86734],
        [-94.83835, 38.86749],
        [-94.83772, 38.86757],
        [-94.83704, 38.86764],
        [-94.83606, 38.86769],
        [-94.81921, 38.86769],
        [-94.81885, 38.86769],
        [-94.81874, 38.86764],
        [-94.81864, 38.86764],
        [-94.8049, 38.86764],
        [-94.8021, 38.86762],
        [-94.8005, 38.86768],
        [-94.80012, 38.86774],
        [-94.7999, 38.86778],
        [-94.79973, 38.86783],
        [-94.79914, 38.86804],
        [-94.79876, 38.86823],
        [-94.79842, 38.86848],
        [-94.79819, 38.86869],
        [-94.79792, 38.86899],
        [-94.79586, 38.87134],
        [-94.79566, 38.87164],
        [-94.79531, 38.87205],
        [-94.79503, 38.87244],
        [-94.79467, 38.87299],
        [-94.79439, 38.87356],
        [-94.79413, 38.8742],
        [-94.7925, 38.87943],
        [-94.78972, 38.88847],
        [-94.7886, 38.89208],
        [-94.78822, 38.89322],
        [-94.78803, 38.89366],
        [-94.78765, 38.89448],
        [-94.7872, 38.89529],
        [-94.78695, 38.89568],
        [-94.78413, 38.89974],
        [-94.78339, 38.90078],
        [-94.78257, 38.90181],
        [-94.78169, 38.90285],
        [-94.77945, 38.90531],
        [-94.77878, 38.90601],
        [-94.7781, 38.90671],
        [-94.77697, 38.90779],
        [-94.7754, 38.90919],
        [-94.77469, 38.90978],
        [-94.77324, 38.91095],
        [-94.77177, 38.91217],
        [-94.77095, 38.91288],
        [-94.76995, 38.91377],
        [-94.76868, 38.91495],
        [-94.76768, 38.91593],
        [-94.76671, 38.91691],
        [-94.76525, 38.91848],
        [-94.76432, 38.91953],
        [-94.76193, 38.92231],
        [-94.75751, 38.9275],
        [-94.74763, 38.93903],
        [-94.74736, 38.93933],
        [-94.74533, 38.94174],
        [-94.74219, 38.94541],
        [-94.73705, 38.9514],
        [-94.73627, 38.95228],
        [-94.73495, 38.95381],
        [-94.7346, 38.9541],
        [-94.7339, 38.95486],
        [-94.73354, 38.95515],
        [-94.7326, 38.95571],
        [-94.73176, 38.95616],
        [-94.73146, 38.95637],
        [-94.73123, 38.9565],
        [-94.73099, 38.95646],
        [-94.7261, 38.95648],
        [-94.7236, 38.95646],
        [-94.71896, 38.95646],
        [-94.71895, 38.95588],
        [-94.71783, 38.95563],
        [-94.71705, 38.95555],
        [-94.71678, 38.95547],
        [-94.71665, 38.95541],
        [-94.71665, 38.95521],
        [-94.71648, 38.95511],
        [-94.71608, 38.95478],
        [-94.71602, 38.95472],
        [-94.716, 38.95468],
        [-94.71584, 38.95467],
        [-94.71575, 38.95472],
        [-94.71598, 38.95495],
        [-94.71587, 38.95506],
        [-94.71579, 38.9551],
        [-94.71567, 38.95512],
        [-94.71522, 38.95506],
        [-94.71511, 38.95509],
        [-94.71501, 38.95507],
        [-94.71499, 38.95516],
        [-94.71503, 38.95526],
        [-94.715, 38.95556],
        [-94.71502, 38.95622],
        [-94.71501, 38.95633],
        [-94.71494, 38.95642],
        [-94.71491, 38.95649],
        [-94.7105, 38.95653],
        [-94.70883, 38.9565],
        [-94.70744, 38.95651],
        [-94.70721, 38.95658],
        [-94.70716, 38.95662],
        [-94.70713, 38.95673],
        [-94.70715, 38.95684],
        [-94.70921, 38.95815],
        [-94.71019, 38.95889],
        [-94.7111, 38.95977],
        [-94.7117, 38.96033],
        [-94.71239, 38.96107],
        [-94.71272, 38.9615],
        [-94.71301, 38.96194],
        [-94.71313, 38.96216],
        [-94.71332, 38.96258],
        [-94.71346, 38.96306],
        [-94.71362, 38.96382],
        [-94.71364, 38.96402],
        [-94.71365, 38.96452],
        [-94.71364, 38.965],
        [-94.71365, 38.96558],
        [-94.71363, 38.96597],
        [-94.71361, 38.97012],
        [-94.71362, 38.97102],
        [-94.71364, 38.97148],
        [-94.71364, 38.97432],
        [-94.71361, 38.97472],
        [-94.71351, 38.97535],
        [-94.71346, 38.97555],
        [-94.71335, 38.97591],
        [-94.7132, 38.97629],
        [-94.71285, 38.97694],
        [-94.71268, 38.97719],
        [-94.71166, 38.97841],
        [-94.71139, 38.9788],
        [-94.71033, 38.97993],
        [-94.70777, 38.98257],
        [-94.70747, 38.98293],
        [-94.70729, 38.98309],
        [-94.70516, 38.98535],
        [-94.70413, 38.98639],
        [-94.7021, 38.98851],
        [-94.70061, 38.99009],
        [-94.69877, 38.99198],
        [-94.69725, 38.9936],
        [-94.69683, 38.99409],
        [-94.69639, 38.9947],
        [-94.69594, 38.99545],
        [-94.69567, 38.99597],
        [-94.69536, 38.99669],
        [-94.69514, 38.99735],
        [-94.69495, 38.9981],
        [-94.69487, 38.99853],
        [-94.6948, 38.99901],
        [-94.694, 39.00603],
        [-94.69384, 39.00728],
        [-94.6937, 39.00865],
        [-94.69312, 39.01373],
        [-94.69289, 39.01682],
        [-94.69275, 39.01789],
        [-94.6926, 39.01874],
        [-94.69238, 39.01967],
        [-94.6918, 39.0216],
        [-94.69157, 39.02246],
        [-94.69126, 39.02387],
        [-94.69104, 39.02506],
        [-94.69083, 39.02593],
        [-94.69075, 39.02621],
        [-94.68939, 39.02988],
        [-94.68913, 39.03046],
        [-94.68898, 39.03076],
        [-94.68865, 39.03129],
        [-94.68829, 39.03177],
        [-94.68661, 39.03372],
        [-94.68634, 39.03407],
        [-94.68623, 39.03417],
        [-94.68563, 39.03489],
        [-94.68526, 39.03528],
        [-94.6847, 39.03576],
        [-94.68437, 39.03598],
        [-94.68398, 39.03622],
        [-94.68228, 39.03724],
        [-94.68152, 39.03768],
        [-94.68073, 39.03803],
        [-94.68017, 39.03822],
        [-94.6793, 39.03843],
        [-94.67846, 39.03856],
        [-94.67804, 39.0386],
        [-94.67763, 39.03862],
        [-94.67648, 39.03862],
        [-94.67554, 39.03866],
        [-94.67461, 39.03876],
        [-94.67431, 39.03882],
        [-94.67383, 39.03895],
        [-94.67349, 39.03905],
        [-94.67258, 39.03938],
        [-94.67203, 39.03963],
        [-94.66991, 39.04066],
        [-94.66925, 39.04094],
        [-94.66746, 39.04161],
        [-94.66699, 39.04176],
        [-94.66589, 39.04207],
        [-94.65881, 39.0439],
        [-94.65752, 39.04432],
        [-94.65429, 39.04552],
        [-94.65343, 39.04581],
        [-94.65146, 39.04632],
        [-94.64956, 39.04684],
        [-94.64412, 39.04821],
        [-94.64205, 39.04871],
        [-94.6414, 39.04892],
        [-94.64109, 39.04905],
        [-94.64084, 39.04918],
        [-94.64045, 39.04942],
        [-94.64005, 39.0497],
        [-94.63965, 39.05006],
        [-94.63878, 39.05094],
        [-94.63846, 39.05122],
        [-94.6379, 39.05166],
        [-94.63739, 39.052],
        [-94.63698, 39.05225],
        [-94.63647, 39.05253],
        [-94.63038, 39.05568],
        [-94.62976, 39.05606],
        [-94.62938, 39.05637],
        [-94.62894, 39.05684],
        [-94.62883, 39.05698],
        [-94.62855, 39.0574],
        [-94.62842, 39.05762],
        [-94.62824, 39.05806],
        [-94.62791, 39.0594],
        [-94.62781, 39.05971],
        [-94.62775, 39.05987],
        [-94.62761, 39.06014],
        [-94.62753, 39.06029],
        [-94.62735, 39.06056],
        [-94.62699, 39.06097],
        [-94.62503, 39.06306],
        [-94.6244, 39.06369],
        [-94.62404, 39.06401],
        [-94.62346, 39.0644],
        [-94.62191, 39.06559],
        [-94.62139, 39.06603],
        [-94.62095, 39.06649],
        [-94.62076, 39.06674],
        [-94.62041, 39.06722],
        [-94.62003, 39.068],
        [-94.61992, 39.06825],
        [-94.61977, 39.06871],
        [-94.61947, 39.06999],
        [-94.61932, 39.07052],
        [-94.61913, 39.071],
        [-94.61885, 39.07149],
        [-94.61852, 39.07193],
        [-94.61825, 39.07224],
        [-94.61775, 39.0727],
        [-94.61735, 39.07301],
        [-94.61692, 39.07329],
        [-94.61663, 39.07345],
        [-94.61612, 39.07368],
        [-94.61538, 39.07397],
        [-94.61516, 39.07402],
        [-94.61093, 39.07497],
        [-94.60762, 39.07575],
        [-94.60735, 39.07581],
        [-94.60705, 39.07585],
        [-94.60574, 39.07615],
        [-94.60522, 39.07625],
        [-94.60469, 39.07628],
        [-94.60409, 39.07626],
        [-94.60381, 39.07623],
        [-94.60031, 39.07563],
        [-94.59978, 39.07556],
        [-94.59924, 39.07554],
        [-94.59876, 39.07556],
        [-94.59815, 39.07563],
        [-94.59779, 39.0757],
        [-94.59737, 39.07581],
        [-94.59687, 39.07598],
        [-94.59663, 39.07609],
        [-94.5964, 39.0762],
        [-94.59597, 39.07645],
        [-94.59557, 39.07675],
        [-94.59517, 39.0771],
        [-94.59479, 39.07753],
        [-94.59438, 39.07804],
        [-94.59396, 39.07871],
        [-94.59372, 39.07915],
        [-94.59351, 39.07965],
        [-94.59343, 39.07988],
        [-94.59334, 39.08034],
        [-94.59318, 39.08201],
        [-94.59315, 39.08228],
        [-94.59309, 39.0826],
        [-94.59262, 39.0841],
        [-94.5922, 39.0858],
        [-94.59199, 39.08652],
        [-94.59089, 39.09098],
        [-94.5908, 39.09144],
        [-94.59078, 39.09172],
        [-94.5908, 39.0925],
        [-94.59079, 39.09301],
        [-94.59083, 39.09509],
        [-94.59085, 39.0954],
        [-94.5909, 39.0957],
        [-94.59102, 39.09612],
        [-94.59101, 39.09619],
        [-94.59107, 39.09659],
        [-94.59106, 39.09674],
        [-94.59104, 39.0969],
        [-94.59098, 39.09705],
        [-94.59088, 39.09718],
        [-94.5907, 39.09733],
        [-94.59029, 39.09756],
        [-94.59021, 39.09764],
        [-94.59018, 39.09773],
        [-94.59014, 39.09803],
        [-94.5901, 39.09866],
        [-94.59005, 39.10017],
        [-94.58917, 39.10014],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "595-134",
      route_short_name: "595",
      route_long_name: "Gardner-OP Express",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.96926, 38.77691],
        [-94.96838, 38.77555],
        [-94.96378, 38.77534],
        [-94.96379, 38.76759],
        [-94.96187, 38.76758],
        [-94.96014, 38.76755],
        [-94.95653, 38.76752],
        [-94.95072, 38.7675],
        [-94.95003, 38.76745],
        [-94.94942, 38.76737],
        [-94.94835, 38.76721],
        [-94.94778, 38.76717],
        [-94.94758, 38.76718],
        [-94.94722, 38.76722],
        [-94.94637, 38.76742],
        [-94.9459, 38.76748],
        [-94.94591, 38.76438],
        [-94.94585, 38.76211],
        [-94.94588, 38.76035],
        [-94.94583, 38.75836],
        [-94.94583, 38.75691],
        [-94.94577, 38.75404],
        [-94.94578, 38.75302],
        [-94.94837, 38.75304],
        [-94.94975, 38.75302],
        [-94.95048, 38.75303],
        [-94.95414, 38.75304],
        [-94.96118, 38.75304],
        [-94.9612, 38.75317],
        [-94.96129, 38.7533],
        [-94.96358, 38.75519],
        [-94.96389, 38.75551],
        [-94.96389, 38.75556],
        [-94.96395, 38.75565],
        [-94.96399, 38.75577],
        [-94.96402, 38.75601],
        [-94.96403, 38.75659],
        [-94.96399, 38.75714],
        [-94.96386, 38.75731],
        [-94.96367, 38.75746],
        [-94.96352, 38.75755],
        [-94.96298, 38.75779],
        [-94.96273, 38.75796],
        [-94.96249, 38.75817],
        [-94.96231, 38.75838],
        [-94.96165, 38.75931],
        [-94.96115, 38.75996],
        [-94.96054, 38.76066],
        [-94.95978, 38.76129],
        [-94.95932, 38.76164],
        [-94.9587, 38.76201],
        [-94.95828, 38.76234],
        [-94.95807, 38.76246],
        [-94.94976, 38.76692],
        [-94.94838, 38.76765],
        [-94.94593, 38.76898],
        [-94.9451, 38.76948],
        [-94.94428, 38.76998],
        [-94.94295, 38.77086],
        [-94.93475, 38.77644],
        [-94.93152, 38.77867],
        [-94.93061, 38.77929],
        [-94.92975, 38.77984],
        [-94.92505, 38.78304],
        [-94.92392, 38.78383],
        [-94.89545, 38.80441],
        [-94.89251, 38.80652],
        [-94.89157, 38.80718],
        [-94.89048, 38.80785],
        [-94.89016, 38.80799],
        [-94.88934, 38.80841],
        [-94.88905, 38.80853],
        [-94.88861, 38.80868],
        [-94.88824, 38.80878],
        [-94.88779, 38.80888],
        [-94.8874, 38.80894],
        [-94.8869, 38.80896],
        [-94.88146, 38.80899],
        [-94.88109, 38.80903],
        [-94.88087, 38.80908],
        [-94.88067, 38.80915],
        [-94.88039, 38.80931],
        [-94.88021, 38.80945],
        [-94.87998, 38.80969],
        [-94.87991, 38.8098],
        [-94.87982, 38.81001],
        [-94.87956, 38.81081],
        [-94.87953, 38.81102],
        [-94.88061, 38.81103],
        [-94.88144, 38.81109],
        [-94.88229, 38.81119],
        [-94.88464, 38.81152],
        [-94.8848, 38.81154],
        [-94.88487, 38.81152],
        [-94.88699, 38.81177],
        [-94.88826, 38.81186],
        [-94.88934, 38.81184],
        [-94.89008, 38.81184],
        [-94.89023, 38.81191],
        [-94.89032, 38.81191],
        [-94.89042, 38.81191],
        [-94.89042, 38.81177],
        [-94.89051, 38.81166],
        [-94.89051, 38.81146],
        [-94.89057, 38.81135],
        [-94.89068, 38.81122],
        [-94.89074, 38.81117],
        [-94.89089, 38.81108],
        [-94.8912, 38.81097],
        [-94.89282, 38.81099],
        [-94.89772, 38.81098],
        [-94.89773, 38.81199],
        [-94.89775, 38.81203],
        [-94.89781, 38.81206],
        [-94.89769, 38.81206],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "595-134",
      route_short_name: "595",
      route_long_name: "Gardner-OP Express",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.8831, 38.8113],
        [-94.88206, 38.811],
        [-94.88185, 38.81088],
        [-94.88168, 38.81074],
        [-94.88156, 38.81057],
        [-94.8815, 38.81038],
        [-94.88148, 38.81027],
        [-94.88149, 38.81007],
        [-94.88152, 38.80997],
        [-94.88163, 38.80979],
        [-94.88179, 38.80962],
        [-94.88189, 38.80953],
        [-94.88215, 38.80939],
        [-94.8823, 38.80932],
        [-94.88263, 38.80923],
        [-94.88294, 38.80918],
        [-94.88328, 38.80918],
        [-94.88359, 38.80921],
        [-94.88375, 38.80925],
        [-94.88404, 38.80935],
        [-94.88418, 38.80942],
        [-94.8844, 38.80956],
        [-94.88457, 38.80972],
        [-94.88468, 38.8099],
        [-94.88471, 38.81],
        [-94.88473, 38.8102],
        [-94.88469, 38.8104],
        [-94.88459, 38.81058],
        [-94.88441, 38.81078],
        [-94.88426, 38.8109],
        [-94.88376, 38.81122],
        [-94.88236, 38.81183],
        [-94.87635, 38.8145],
        [-94.87137, 38.81676],
        [-94.8707, 38.81708],
        [-94.8692, 38.81786],
        [-94.86829, 38.81838],
        [-94.86721, 38.81904],
        [-94.86615, 38.81971],
        [-94.86455, 38.82085],
        [-94.86278, 38.8222],
        [-94.8589, 38.82512],
        [-94.85649, 38.82696],
        [-94.8541, 38.82875],
        [-94.85268, 38.82973],
        [-94.84479, 38.83475],
        [-94.84368, 38.83549],
        [-94.83831, 38.83889],
        [-94.83691, 38.83976],
        [-94.83385, 38.84149],
        [-94.83145, 38.84289],
        [-94.8309, 38.84323],
        [-94.83018, 38.84372],
        [-94.82879, 38.8447],
        [-94.82812, 38.8452],
        [-94.8264, 38.8466],
        [-94.82216, 38.84992],
        [-94.82096, 38.85089],
        [-94.81801, 38.85323],
        [-94.81611, 38.85472],
        [-94.8159, 38.85485],
        [-94.81509, 38.85554],
        [-94.81275, 38.85742],
        [-94.81042, 38.85923],
        [-94.80484, 38.86365],
        [-94.80291, 38.86527],
        [-94.80122, 38.86675],
        [-94.80049, 38.86742],
        [-94.79687, 38.8705],
        [-94.79615, 38.87115],
        [-94.79566, 38.87164],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "595-134",
      route_short_name: "595",
      route_long_name: "Gardner-OP Express",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.76768, 38.91593],
        [-94.76727, 38.91634],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "595-134",
      route_short_name: "595",
      route_long_name: "Gardner-OP Express",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.58917, 39.10014],
        [-94.58558, 39.10002],
        [-94.58547, 39.10229],
        [-94.58365, 39.10224],
        [-94.58321, 39.1023],
        [-94.58304, 39.10236],
        [-94.58095, 39.10228],
        [-94.57961, 39.10225],
        [-94.5784, 39.1022],
        [-94.57856, 39.09979],
        [-94.57864, 39.09752],
        [-94.57875, 39.09597],
        [-94.57897, 39.09164],
        [-94.58133, 39.09171],
        [-94.58162, 39.08528],
        [-94.58169, 39.08445],
        [-94.58178, 39.08435],
        [-94.58182, 39.08349],
        [-94.58192, 39.08253],
        [-94.58195, 39.08239],
        [-94.58206, 39.08215],
        [-94.58241, 39.0815],
        [-94.58277, 39.08091],
        [-94.58276, 39.08075],
        [-94.58308, 39.08022],
        [-94.58313, 39.08011],
        [-94.58316, 39.07997],
        [-94.58316, 39.07941],
        [-94.58318, 39.0792],
        [-94.58325, 39.07901],
        [-94.58353, 39.07851],
        [-94.58361, 39.07832],
        [-94.58366, 39.07799],
        [-94.58368, 39.07756],
        [-94.58373, 39.07729],
        [-94.58379, 39.0771],
        [-94.58389, 39.07692],
        [-94.58409, 39.07668],
        [-94.58417, 39.07661],
        [-94.58427, 39.07658],
        [-94.58456, 39.07639],
        [-94.58464, 39.07637],
        [-94.58473, 39.0764],
        [-94.58481, 39.07644],
        [-94.58488, 39.07652],
        [-94.58491, 39.07659],
        [-94.58477, 39.07797],
        [-94.5847, 39.07822],
        [-94.58454, 39.07854],
        [-94.58443, 39.07883],
        [-94.58434, 39.07916],
        [-94.58428, 39.07953],
        [-94.58425, 39.07991],
        [-94.58422, 39.08118],
        [-94.58423, 39.0822],
        [-94.58421, 39.08281],
        [-94.58417, 39.08352],
        [-94.5841, 39.08411],
        [-94.58433, 39.08407],
        [-94.5849, 39.08405],
        [-94.58504, 39.08402],
        [-94.58514, 39.08398],
        [-94.58542, 39.08384],
        [-94.58563, 39.08376],
        [-94.58623, 39.08361],
        [-94.58674, 39.08351],
        [-94.58685, 39.08344],
        [-94.58851, 39.08341],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "595-134",
      route_short_name: "595",
      route_long_name: "Gardner-OP Express",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.58917, 39.10014],
        [-94.58558, 39.10002],
        [-94.58547, 39.10229],
        [-94.58365, 39.10224],
        [-94.58321, 39.1023],
        [-94.58304, 39.10236],
        [-94.58095, 39.10228],
        [-94.57961, 39.10225],
        [-94.5784, 39.1022],
        [-94.57856, 39.09979],
        [-94.57864, 39.09752],
        [-94.57875, 39.09597],
        [-94.57897, 39.09164],
        [-94.58133, 39.09171],
        [-94.58162, 39.08528],
        [-94.58169, 39.08445],
        [-94.58178, 39.08435],
        [-94.58182, 39.08349],
        [-94.58192, 39.08253],
        [-94.58195, 39.08239],
        [-94.58206, 39.08215],
        [-94.58241, 39.0815],
        [-94.58277, 39.08091],
        [-94.58276, 39.08075],
        [-94.58308, 39.08022],
        [-94.58313, 39.08011],
        [-94.58316, 39.07997],
        [-94.58316, 39.07941],
        [-94.58318, 39.0792],
        [-94.58325, 39.07901],
        [-94.58353, 39.07851],
        [-94.58361, 39.07832],
        [-94.58366, 39.07799],
        [-94.58368, 39.07756],
        [-94.58373, 39.07729],
        [-94.58379, 39.0771],
        [-94.58389, 39.07692],
        [-94.58409, 39.07668],
        [-94.58417, 39.07661],
        [-94.58427, 39.07658],
        [-94.58456, 39.07639],
        [-94.58464, 39.07637],
        [-94.58473, 39.0764],
        [-94.58481, 39.07644],
        [-94.58488, 39.07652],
        [-94.58491, 39.07659],
        [-94.58477, 39.07797],
        [-94.5847, 39.07822],
        [-94.58454, 39.07854],
        [-94.58443, 39.07883],
        [-94.58434, 39.07916],
        [-94.58428, 39.07953],
        [-94.58425, 39.07991],
        [-94.58422, 39.08118],
        [-94.58423, 39.0822],
        [-94.58421, 39.08281],
        [-94.58417, 39.08352],
        [-94.5841, 39.08411],
        [-94.58433, 39.08407],
        [-94.5849, 39.08405],
        [-94.58504, 39.08402],
        [-94.58514, 39.08398],
        [-94.58542, 39.08384],
        [-94.58563, 39.08376],
        [-94.58623, 39.08361],
        [-94.58674, 39.08351],
        [-94.58685, 39.08344],
        [-94.58868, 39.08341],
        [-94.58894, 39.0834],
        [-94.58924, 39.08335],
        [-94.58954, 39.08335],
        [-94.59157, 39.0834],
        [-94.59152, 39.08374],
        [-94.59139, 39.08409],
        [-94.59124, 39.08439],
        [-94.59108, 39.08459],
        [-94.59083, 39.08485],
        [-94.59002, 39.08549],
        [-94.58983, 39.08565],
        [-94.58953, 39.08598],
        [-94.58935, 39.08623],
        [-94.58925, 39.0864],
        [-94.58912, 39.0868],
        [-94.58906, 39.08745],
        [-94.59012, 39.08745],
        [-94.59045, 39.08743],
        [-94.59214, 39.08748],
        [-94.59243, 39.0865],
        [-94.59249, 39.08598],
        [-94.59256, 39.08562],
        [-94.59256, 39.08519],
        [-94.59263, 39.08494],
        [-94.59286, 39.0841],
        [-94.59345, 39.0821],
        [-94.59408, 39.08004],
        [-94.59436, 39.07917],
        [-94.59464, 39.07858],
        [-94.59477, 39.07835],
        [-94.59493, 39.07812],
        [-94.59515, 39.07786],
        [-94.59533, 39.07767],
        [-94.59554, 39.07748],
        [-94.59576, 39.0773],
        [-94.59601, 39.07712],
        [-94.59656, 39.07676],
        [-94.5971, 39.07646],
        [-94.59737, 39.07634],
        [-94.59777, 39.07616],
        [-94.59813, 39.07603],
        [-94.59864, 39.0759],
        [-94.5989, 39.07585],
        [-94.59916, 39.07581],
        [-94.59968, 39.07577],
        [-94.59994, 39.07577],
        [-94.60045, 39.0758],
        [-94.60106, 39.07589],
        [-94.60345, 39.07633],
        [-94.60379, 39.07637],
        [-94.60454, 39.07643],
        [-94.6051, 39.0764],
        [-94.60608, 39.07624],
        [-94.60947, 39.07545],
        [-94.61264, 39.07474],
        [-94.61466, 39.07433],
        [-94.61517, 39.07421],
        [-94.61564, 39.07406],
        [-94.61617, 39.07386],
        [-94.61658, 39.07367],
        [-94.61707, 39.07341],
        [-94.61755, 39.0731],
        [-94.61793, 39.07281],
        [-94.61811, 39.07265],
        [-94.61853, 39.0722],
        [-94.61871, 39.07199],
        [-94.61909, 39.07146],
        [-94.6193, 39.07108],
        [-94.61957, 39.0704],
        [-94.61996, 39.06872],
        [-94.62014, 39.06819],
        [-94.62025, 39.06794],
        [-94.62051, 39.06744],
        [-94.62082, 39.06697],
        [-94.621, 39.06674],
        [-94.62139, 39.06629],
        [-94.62184, 39.06587],
        [-94.62248, 39.06537],
        [-94.62394, 39.06428],
        [-94.62438, 39.06392],
        [-94.62479, 39.06355],
        [-94.62723, 39.06096],
        [-94.6275, 39.06063],
        [-94.62777, 39.06022],
        [-94.62792, 39.05993],
        [-94.62804, 39.0596],
        [-94.62816, 39.05922],
        [-94.62839, 39.05823],
        [-94.62848, 39.05795],
        [-94.62861, 39.05764],
        [-94.62882, 39.05728],
        [-94.62895, 39.05709],
        [-94.62919, 39.05678],
        [-94.62937, 39.05659],
        [-94.62954, 39.05643],
        [-94.62989, 39.05617],
        [-94.63052, 39.05578],
        [-94.63369, 39.05412],
        [-94.63672, 39.05257],
        [-94.63747, 39.05214],
        [-94.63812, 39.0517],
        [-94.63871, 39.05121],
        [-94.63899, 39.05097],
        [-94.63993, 39.05004],
        [-94.64013, 39.04985],
        [-94.64053, 39.04955],
        [-94.6409, 39.04933],
        [-94.64144, 39.04908],
        [-94.64183, 39.04893],
        [-94.64222, 39.04881],
        [-94.6528, 39.04613],
        [-94.65359, 39.04589],
        [-94.65413, 39.04571],
        [-94.65744, 39.04449],
        [-94.65833, 39.04419],
        [-94.66292, 39.04297],
        [-94.6648, 39.04251],
        [-94.66547, 39.04237],
        [-94.66623, 39.04227],
        [-94.6695, 39.04201],
        [-94.67035, 39.0419],
        [-94.67086, 39.04181],
        [-94.67274, 39.04135],
        [-94.67349, 39.04113],
        [-94.67455, 39.04084],
        [-94.67627, 39.04034],
        [-94.6773, 39.04],
        [-94.67832, 39.03961],
        [-94.67923, 39.03921],
        [-94.68, 39.03883],
        [-94.68038, 39.03863],
        [-94.68168, 39.03787],
        [-94.68456, 39.0361],
        [-94.68497, 39.0358],
        [-94.6852, 39.03561],
        [-94.68556, 39.03528],
        [-94.6859, 39.03491],
        [-94.68658, 39.03412],
        [-94.68682, 39.0338],
        [-94.68846, 39.03195],
        [-94.68885, 39.03144],
        [-94.68912, 39.03098],
        [-94.68948, 39.03029],
        [-94.68967, 39.02982],
        [-94.69091, 39.02645],
        [-94.69114, 39.02573],
        [-94.69131, 39.025],
        [-94.69136, 39.02462],
        [-94.69152, 39.02383],
        [-94.69179, 39.02262],
        [-94.69205, 39.02162],
        [-94.69267, 39.01953],
        [-94.69288, 39.0186],
        [-94.693, 39.01783],
        [-94.6931, 39.01712],
        [-94.69327, 39.0148],
        [-94.69342, 39.01332],
        [-94.69359, 39.01199],
        [-94.69365, 39.01131],
        [-94.69394, 39.00874],
        [-94.69404, 39.00767],
        [-94.69417, 39.0067],
        [-94.69464, 39.00246],
        [-94.69503, 38.99913],
        [-94.69512, 38.99842],
        [-94.69523, 38.9978],
        [-94.69548, 38.99697],
        [-94.69565, 38.99653],
        [-94.69609, 38.99561],
        [-94.69642, 38.99504],
        [-94.69683, 38.99446],
        [-94.69704, 38.9942],
        [-94.6976, 38.99355],
        [-94.70228, 38.98864],
        [-94.70512, 38.9857],
        [-94.70594, 38.98488],
        [-94.70744, 38.98326],
        [-94.7084, 38.9822],
        [-94.7103, 38.98029],
        [-94.71178, 38.9787],
        [-94.71454, 38.97578],
        [-94.71495, 38.97548],
        [-94.71547, 38.97499],
        [-94.71667, 38.97391],
        [-94.71815, 38.97249],
        [-94.71873, 38.972],
        [-94.7189, 38.97172],
        [-94.71894, 38.97155],
        [-94.71891, 38.97139],
        [-94.71883, 38.97128],
        [-94.71858, 38.97104],
        [-94.71516, 38.971],
        [-94.71457, 38.97093],
        [-94.71447, 38.9709],
        [-94.71436, 38.97081],
        [-94.71431, 38.97071],
        [-94.71429, 38.9706],
        [-94.7141, 38.96913],
        [-94.71402, 38.96825],
        [-94.71396, 38.96561],
        [-94.71388, 38.96423],
        [-94.71379, 38.96389],
        [-94.71377, 38.96358],
        [-94.7137, 38.96319],
        [-94.71361, 38.96281],
        [-94.71353, 38.96257],
        [-94.71339, 38.96223],
        [-94.71324, 38.96194],
        [-94.71288, 38.96136],
        [-94.71261, 38.96103],
        [-94.712, 38.96035],
        [-94.71156, 38.95992],
        [-94.7113, 38.95955],
        [-94.71072, 38.95891],
        [-94.71033, 38.95836],
        [-94.70985, 38.95759],
        [-94.70958, 38.95721],
        [-94.70937, 38.95694],
        [-94.70934, 38.95685],
        [-94.70934, 38.95679],
        [-94.70937, 38.95672],
        [-94.70949, 38.95665],
        [-94.71051, 38.95666],
        [-94.71488, 38.95662],
        [-94.71491, 38.95649],
        [-94.71605, 38.95648],
        [-94.71608, 38.95637],
        [-94.7161, 38.95619],
        [-94.71613, 38.95616],
        [-94.71615, 38.95586],
        [-94.71614, 38.95538],
        [-94.71616, 38.95532],
        [-94.71626, 38.95517],
        [-94.71665, 38.95541],
        [-94.71665, 38.95521],
        [-94.71648, 38.95511],
        [-94.71608, 38.95478],
        [-94.71602, 38.95472],
        [-94.716, 38.95468],
        [-94.71584, 38.95467],
        [-94.71575, 38.95472],
        [-94.71608, 38.95504],
        [-94.71626, 38.95517],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "595-134",
      route_short_name: "595",
      route_long_name: "Gardner-OP Express",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.71665, 38.95541],
        [-94.71678, 38.95547],
        [-94.71705, 38.95555],
        [-94.71767, 38.95561],
        [-94.71769, 38.95661],
        [-94.7261, 38.95661],
        [-94.7283, 38.95659],
        [-94.73101, 38.95663],
        [-94.73189, 38.95646],
        [-94.73367, 38.95644],
        [-94.73379, 38.95638],
        [-94.73407, 38.95597],
        [-94.7346, 38.95485],
        [-94.73475, 38.95457],
        [-94.73499, 38.95423],
        [-94.73523, 38.95378],
        [-94.73541, 38.95357],
        [-94.73785, 38.95077],
        [-94.74001, 38.94825],
        [-94.7455, 38.94183],
        [-94.74776, 38.93917],
        [-94.74796, 38.93894],
        [-94.7565, 38.92897],
        [-94.75804, 38.9272],
        [-94.75863, 38.92648],
        [-94.76322, 38.92111],
        [-94.76517, 38.91886],
        [-94.76627, 38.91767],
        [-94.76718, 38.91672],
        [-94.7685, 38.9154],
        [-94.76979, 38.91418],
        [-94.77183, 38.91237],
        [-94.77288, 38.91149],
        [-94.77486, 38.90989],
        [-94.77552, 38.90934],
        [-94.77671, 38.90826],
        [-94.77704, 38.90798],
        [-94.77824, 38.90684],
        [-94.77975, 38.90528],
        [-94.78167, 38.90316],
        [-94.78231, 38.90243],
        [-94.78287, 38.90176],
        [-94.78337, 38.90112],
        [-94.78382, 38.90051],
        [-94.78666, 38.89647],
        [-94.78726, 38.89559],
        [-94.7876, 38.895],
        [-94.78783, 38.89458],
        [-94.78821, 38.89377],
        [-94.78859, 38.89279],
        [-94.7888, 38.89214],
        [-94.79295, 38.8787],
        [-94.79438, 38.87413],
        [-94.79448, 38.87386],
        [-94.79473, 38.87332],
        [-94.79503, 38.8728],
        [-94.79531, 38.87238],
        [-94.79573, 38.87187],
        [-94.79623, 38.87137],
        [-94.79699, 38.87068],
        [-94.79758, 38.87031],
        [-94.79803, 38.86999],
        [-94.7987, 38.86953],
        [-94.79944, 38.86912],
        [-94.80005, 38.86882],
        [-94.80039, 38.86867],
        [-94.80127, 38.86835],
        [-94.80204, 38.86812],
        [-94.80315, 38.86791],
        [-94.80362, 38.86783],
        [-94.80396, 38.8678],
        [-94.80492, 38.86776],
        [-94.80631, 38.86774],
        [-94.81584, 38.86776],
        [-94.81874, 38.86774],
        [-94.81885, 38.86769],
        [-94.83606, 38.86769],
        [-94.83704, 38.86764],
        [-94.83772, 38.86757],
        [-94.83835, 38.86749],
        [-94.83916, 38.86734],
        [-94.83998, 38.86715],
        [-94.8407, 38.86694],
        [-94.84153, 38.86667],
        [-94.84254, 38.86623],
        [-94.84352, 38.86572],
        [-94.84455, 38.86509],
        [-94.84814, 38.8628],
        [-94.84909, 38.86216],
        [-94.85099, 38.86097],
        [-94.85186, 38.86047],
        [-94.85495, 38.85882],
        [-94.85521, 38.85867],
        [-94.85543, 38.85851],
        [-94.85563, 38.85834],
        [-94.85591, 38.85805],
        [-94.85611, 38.85781],
        [-94.85631, 38.85752],
        [-94.85646, 38.85725],
        [-94.85705, 38.85618],
        [-94.85724, 38.85591],
        [-94.85745, 38.85567],
        [-94.85776, 38.85539],
        [-94.85789, 38.8553],
        [-94.85813, 38.85515],
        [-94.85835, 38.85504],
        [-94.85868, 38.8549],
        [-94.85895, 38.85481],
        [-94.85949, 38.85468],
        [-94.85982, 38.85464],
        [-94.86032, 38.85462],
        [-94.86658, 38.8546],
        [-94.868, 38.85458],
        [-94.86873, 38.85449],
        [-94.86911, 38.85438],
        [-94.86974, 38.85408],
        [-94.86999, 38.85393],
        [-94.87018, 38.85389],
        [-94.87042, 38.85387],
        [-94.87067, 38.85391],
        [-94.87109, 38.85417],
        [-94.87138, 38.8543],
        [-94.87154, 38.85436],
        [-94.87194, 38.85445],
        [-94.87254, 38.85449],
        [-94.88007, 38.85448],
        [-94.88201, 38.85445],
        [-94.88737, 38.85448],
        [-94.891, 38.85445],
        [-94.89107, 38.85442],
        [-94.89319, 38.85442],
        [-94.89325, 38.85315],
        [-94.89329, 38.85286],
        [-94.89348, 38.85182],
        [-94.8935, 38.85177],
        [-94.89354, 38.85174],
        [-94.89378, 38.85101],
        [-94.89394, 38.85059],
        [-94.89488, 38.84836],
        [-94.89564, 38.84661],
        [-94.89583, 38.84613],
        [-94.89594, 38.84571],
        [-94.89598, 38.84532],
        [-94.89599, 38.84196],
        [-94.89601, 38.8412],
        [-94.89602, 38.84106],
        [-94.89606, 38.84101],
        [-94.89618, 38.84049],
        [-94.89619, 38.84001],
        [-94.89618, 38.83816],
        [-94.8962, 38.83798],
        [-94.89623, 38.83786],
        [-94.89632, 38.83766],
        [-94.8964, 38.83755],
        [-94.89653, 38.83743],
        [-94.89668, 38.83733],
        [-94.89694, 38.8372],
        [-94.89784, 38.83689],
        [-94.89823, 38.83671],
        [-94.89857, 38.83648],
        [-94.89886, 38.83625],
        [-94.89906, 38.83603],
        [-94.89921, 38.83579],
        [-94.89931, 38.83553],
        [-94.89934, 38.83539],
        [-94.89936, 38.83469],
        [-94.89933, 38.83024],
        [-94.89935, 38.82987],
        [-94.89937, 38.82823],
        [-94.89938, 38.82132],
        [-94.89941, 38.82118],
        [-94.8995, 38.82092],
        [-94.89957, 38.8208],
        [-94.89975, 38.82056],
        [-94.90003, 38.82032],
        [-94.90105, 38.81952],
        [-94.90144, 38.81919],
        [-94.90156, 38.81907],
        [-94.90177, 38.8188],
        [-94.90185, 38.81866],
        [-94.90198, 38.81835],
        [-94.90207, 38.81793],
        [-94.90208, 38.81763],
        [-94.90206, 38.81747],
        [-94.90198, 38.81716],
        [-94.90191, 38.81701],
        [-94.90173, 38.81667],
        [-94.9017, 38.81654],
        [-94.90149, 38.81601],
        [-94.90144, 38.81577],
        [-94.90144, 38.81564],
        [-94.90148, 38.8154],
        [-94.90157, 38.8152],
        [-94.90163, 38.81511],
        [-94.90207, 38.81466],
        [-94.90271, 38.81405],
        [-94.90304, 38.81375],
        [-94.90334, 38.81344],
        [-94.90386, 38.81301],
        [-94.90408, 38.81287],
        [-94.90443, 38.81267],
        [-94.90483, 38.81248],
        [-94.90512, 38.81238],
        [-94.90554, 38.81226],
        [-94.90628, 38.81211],
        [-94.9097, 38.81152],
        [-94.91199, 38.81108],
        [-94.91256, 38.81102],
        [-94.91331, 38.81098],
        [-94.92212, 38.81105],
        [-94.92299, 38.81105],
        [-94.92452, 38.811],
        [-94.92671, 38.811],
        [-94.92745, 38.81104],
        [-94.92744, 38.80916],
        [-94.9275, 38.80801],
        [-94.92753, 38.80692],
        [-94.92753, 38.80378],
        [-94.9275, 38.80219],
        [-94.92751, 38.79766],
        [-94.9275, 38.79303],
        [-94.92748, 38.7904],
        [-94.92739, 38.78366],
        [-94.92784, 38.78357],
        [-94.92812, 38.78347],
        [-94.92832, 38.78339],
        [-94.92863, 38.78319],
        [-94.92944, 38.78257],
        [-94.92993, 38.78226],
        [-94.93006, 38.78219],
        [-94.93041, 38.78206],
        [-94.93071, 38.78198],
        [-94.93114, 38.78196],
        [-94.93651, 38.78196],
        [-94.94221, 38.78202],
        [-94.94229, 38.77803],
        [-94.94229, 38.77706],
        [-94.94234, 38.77288],
        [-94.94244, 38.77253],
        [-94.94265, 38.77219],
        [-94.94308, 38.77183],
        [-94.94369, 38.77147],
        [-94.94442, 38.77124],
        [-94.94532, 38.77122],
        [-94.94596, 38.77123],
        [-94.94592, 38.77197],
        [-94.94591, 38.77486],
        [-94.94579, 38.78054],
        [-94.94579, 38.78337],
        [-94.94577, 38.78619],
        [-94.94579, 38.78895],
        [-94.95226, 38.78877],
        [-94.95244, 38.78876],
        [-94.95254, 38.78871],
        [-94.95259, 38.78862],
        [-94.95263, 38.78847],
        [-94.95266, 38.78776],
        [-94.95264, 38.78208],
        [-94.95887, 38.78208],
        [-94.95917, 38.78194],
        [-94.96452, 38.78201],
        [-94.96502, 38.78198],
        [-94.96546, 38.78189],
        [-94.96594, 38.78173],
        [-94.96682, 38.78147],
        [-94.96709, 38.7815],
        [-94.96938, 38.78109],
        [-94.96963, 38.77747],
        [-94.96926, 38.77691],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "595-134",
      route_short_name: "595",
      route_long_name: "Gardner-OP Express",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.79699, 38.87068],
        [-94.79782, 38.86994],
        [-94.79932, 38.86864],
        [-94.79985, 38.86824],
        [-94.8005, 38.86768],
        [-94.80122, 38.86703],
        [-94.80305, 38.8654],
        [-94.80399, 38.86459],
        [-94.80496, 38.8638],
        [-94.80894, 38.86064],
        [-94.8114, 38.85875],
        [-94.81329, 38.85726],
        [-94.81571, 38.85532],
        [-94.81903, 38.85273],
        [-94.8243, 38.84851],
        [-94.82916, 38.84474],
        [-94.83048, 38.84384],
        [-94.83161, 38.84312],
        [-94.83248, 38.8426],
        [-94.83736, 38.8398],
        [-94.83922, 38.83866],
        [-94.84381, 38.83572],
        [-94.84504, 38.83489],
        [-94.84578, 38.83443],
        [-94.84601, 38.83426],
        [-94.84764, 38.83324],
        [-94.85257, 38.8301],
        [-94.85344, 38.82952],
        [-94.85436, 38.82886],
        [-94.85699, 38.82689],
        [-94.86023, 38.82443],
        [-94.86279, 38.82252],
        [-94.8649, 38.82091],
        [-94.86553, 38.82044],
        [-94.86687, 38.81955],
        [-94.86816, 38.81874],
        [-94.86875, 38.8184],
        [-94.86935, 38.81805],
        [-94.87042, 38.8175],
        [-94.87137, 38.81703],
        [-94.87219, 38.81664],
        [-94.87677, 38.81458],
        [-94.87837, 38.81387],
        [-94.87869, 38.81378],
        [-94.87951, 38.81345],
        [-94.87993, 38.8133],
        [-94.88056, 38.81313],
        [-94.88118, 38.81304],
        [-94.88161, 38.81302],
        [-94.88522, 38.81297],
        [-94.88577, 38.81295],
        [-94.88603, 38.81291],
        [-94.88626, 38.81285],
        [-94.8864, 38.81279],
        [-94.88652, 38.81272],
        [-94.88661, 38.81262],
        [-94.88681, 38.81223],
        [-94.88699, 38.81207],
        [-94.88716, 38.81199],
        [-94.88752, 38.81193],
        [-94.8885, 38.81198],
        [-94.89008, 38.81197],
        [-94.89023, 38.81191],
        [-94.89042, 38.81191],
        [-94.89042, 38.81177],
        [-94.89051, 38.81166],
        [-94.89051, 38.81146],
        [-94.89062, 38.81129],
        [-94.89074, 38.81117],
        [-94.8908, 38.81112],
        [-94.8912, 38.81097],
        [-94.89282, 38.81099],
        [-94.89772, 38.81098],
        [-94.89773, 38.81199],
        [-94.89775, 38.81203],
        [-94.89781, 38.81206],
        [-94.89769, 38.81206],
      ],
    },
  },
  {
    type: "Feature",
    properties: {
      agency_name: "KCATA",
      route_id: "595-134",
      route_short_name: "595",
      route_long_name: "Gardner-OP Express",
      route_type: 3,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [-94.89023, 38.81191],
        [-94.89008, 38.81184],
        [-94.88934, 38.81184],
        [-94.88826, 38.81186],
        [-94.88699, 38.81177],
        [-94.88711, 38.81139],
        [-94.88756, 38.81051],
        [-94.88773, 38.81023],
        [-94.888, 38.80988],
        [-94.88827, 38.8096],
        [-94.88859, 38.80933],
        [-94.88919, 38.80894],
        [-94.88937, 38.80879],
        [-94.89143, 38.80756],
        [-94.89238, 38.80692],
        [-94.89663, 38.80387],
        [-94.92321, 38.78466],
        [-94.92356, 38.78447],
        [-94.92421, 38.78403],
        [-94.92454, 38.78384],
        [-94.92491, 38.78365],
        [-94.9252, 38.78353],
        [-94.92568, 38.78337],
        [-94.9261, 38.78325],
        [-94.92738, 38.78299],
        [-94.92736, 38.78078],
        [-94.92731, 38.77959],
        [-94.92725, 38.77723],
        [-94.92708, 38.76745],
        [-94.93403, 38.76745],
        [-94.9459, 38.76748],
        [-94.94637, 38.76742],
        [-94.94722, 38.76722],
        [-94.94758, 38.76718],
        [-94.94778, 38.76717],
        [-94.94835, 38.76721],
        [-94.94942, 38.76737],
        [-94.95003, 38.76745],
        [-94.95072, 38.7675],
        [-94.95653, 38.76752],
        [-94.96014, 38.76755],
        [-94.96187, 38.76758],
        [-94.96379, 38.76759],
        [-94.96378, 38.77534],
        [-94.96838, 38.77555],
        [-94.96878, 38.77617],
      ],
    },
  },
];

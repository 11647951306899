import React from "react";

import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toast = () => {

  return (
    <div>
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
    </div>
  );
};
export default Toast;

import React, { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

const UserInfo = ({
  homeAddress,
  setHomeAddress,
  setHomeMarker,
  toast,
  toastError,
  setTransitType,
}) => {
  const [editing, setEditing] = useState(false);
  const [address, setAddress] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .get(
        `https://www.mapquestapi.com/geocoding/v1/address?key=${process.env.REACT_APP_GEOCODE_API_KEY}&location=${address}`
      )
      .then((res) => {
        if (res.data.results[0].locations[0].latLng === undefined) {
          console.log("undefined");
        }
        setHomeMarker(res.data.results[0].locations[0].latLng);
        setEditing(false);
        setHomeAddress(address);
        setAddress("");
        toast.success(
          "Address changed successfully. If you have jobs in your list, directions may be inaccurate. Remove them and them again.",
          toastError
        );
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          "Invalid address. Please enter address, city, and state.",
          toastError
        );
      });
  };

  const handleChange = (e) => {
    setAddress(e.target.value);
  };

  const selectTransportation = (e) => {
    setTransitType(e.target.value);
  };

  return (
    <div className="user__info">
      {editing ? (
        <>
          <form
            className="address_modal--form"
            id="user__info--form"
            onSubmit={handleSubmit}
          >
            <input
              className="input input__user"
              name="home"
              value={address}
              onChange={handleChange}
              placeholder="Enter Home Address"
            ></input>
            <button className="btn " id="user__info--btn" type="submit">
              Submit
            </button>
          </form>
        </>
      ) : (
        <>
          <div className="user__info--edit">
            <h3 className="user__info--address">{homeAddress}</h3>
            <FontAwesomeIcon
              icon={faPencilAlt}
              onClick={() => setEditing(true)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default UserInfo;

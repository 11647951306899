import React from "react";


const UserIcon = ({ setOpenUser, openUser }) => {
  return (
    <div className="header-dropdown">
    </div>
  );
};

export default UserIcon;

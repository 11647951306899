import axios from "axios";
import { transitRoutes, includesAddress } from "../../utils";

export const FETCH_EDU_SERVICES_START = "FETCH_EDU_SERVICES_START";
export const FETCH_EDU_SERVICES_SUCCESS = "FETCH_EDU_SERVICES_SUCCESS";
export const FETCH_EDU_SERVICES_FAILURE = "FETCH_EDU_SERVICES_FAILURE";

export const FETCH_SOC_SERVICES_START = "FETCH_SOC_SERVICES_START";
export const FETCH_SOC_SERVICES_SUCCESS = "FETCH_SOC_SERVICES_SUCCESS";
export const FETCH_SOC_SERVICES_FAILURE = "FETCH_SOC_SERVICES_FAILURE";

export const FETCH_CHILD_SERVICES_START = "FETCH_CHILD_SERVICES_START";
export const FETCH_CHILD_SERVICES_SUCCESS = "FETCH_CHILD_SERVICES_SUCCESS";
export const FETCH_CHILD_SERVICES_FAILURE = "FETCH_CHILD_SERVICES_FAILURE";

export const FETCH_FOOD_SERVICES_START = "FETCH_FOOD_SERVICES_START";
export const FETCH_FOOD_SERVICES_SUCCESS = "FETCH_FOOD_SERVICES_SUCCESS";
export const FETCH_FOOD_SERVICES_FAILURE = "FETCH_FOOD_SERVICES_FAILURE";

export const FETCH_JOBS_START = "FETCH_JOBS_START";
export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS";
export const FETCH_JOBS_FAILURE = "FETCH_JOBS_FAILURE";

export const SET_TRANSIT = "SET_TRANSIT";

export const fetchEduSvcs = () => (dispatch) => {
  dispatch({ type: FETCH_EDU_SERVICES_START });
  axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/education_services`)
    .then((res) => {
      dispatch({ type: FETCH_EDU_SERVICES_SUCCESS, payload: res.data.data });
    })
    .catch((err) => {
      dispatch({ type: FETCH_EDU_SERVICES_FAILURE, payload: err });
    });
};

export const fetchChildSvcs = () => (dispatch) => {
  dispatch({ type: FETCH_CHILD_SERVICES_START });
  axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/child_care_services`)
    .then((res) => {
      dispatch({ type: FETCH_CHILD_SERVICES_SUCCESS, payload: res.data.data });
    })
    .catch((err) => {
      dispatch({ type: FETCH_CHILD_SERVICES_FAILURE, payload: err });
    });
};

export const fetchSocSvcs = () => (dispatch) => {
  dispatch({ type: FETCH_SOC_SERVICES_START });
  axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/social_services`)
    .then((res) => {
      dispatch({ type: FETCH_SOC_SERVICES_SUCCESS, payload: res.data.data });
    })
    .catch((err) => {
      dispatch({ type: FETCH_SOC_SERVICES_FAILURE, payload: err });
    });
};

export const fetchFoodSvcs = () => (dispatch) => {
  dispatch({ type: FETCH_FOOD_SERVICES_START });
  axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/food_pantry_services`)
    .then((res) => {
      dispatch({ type: FETCH_FOOD_SERVICES_SUCCESS, payload: res.data.data });
    })
    .catch((err) => {
      dispatch({ type: FETCH_FOOD_SERVICES_FAILURE, payload: err });
    });
};

export const fetchJobs = () => (dispatch) => {
  dispatch({ type: FETCH_JOBS_START });
  axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/jobs`)
    .then((res) => {
      let jobs = res.data.data.map((j) => {
        if (includesAddress(j.address)) {
          return { ...j, valid: false };
        } else {
          return { ...j, valid: true };
        }
      });
      dispatch({ type: FETCH_JOBS_SUCCESS, payload: jobs });
    })
    .catch((err) => {
      dispatch({ type: FETCH_JOBS_FAILURE, payload: err });
    });
};

export const setTransitRedux = () => {
  let coordArr = [];
  transitRoutes.map((tr) => {
    let arr = { name: tr.properties.route_short_name, coords: [] };
    const coordinates = tr.geometry.coordinates;
    coordinates.map((c) => arr.coords.push({ lat: c[1], lng: c[0] }));
    coordArr.push(arr);
  });
  return { type: SET_TRANSIT, payload: coordArr };
};

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import RadioButton from "./RadioButton";

const JobCart = ({
  jobCart,
  removeJob,
  homeAddress,
  visibleList,
  setFindTimes,
  setJobCart,
  setDirections,
  setRenderRoutes,
  setTimes,
  setAddresses,
  setVisibleList,
  setSelectedBiz,
  setBusiness,
  setViewModal,
  setTransitType,
}) => {
  const findCommuteTimes = (e) => {
    e.preventDefault();
    if (homeAddress) {
      setFindTimes(true);
      setRenderRoutes(true);
      setVisibleList(true);
    } else if (!homeAddress) {
      toast.error(
        "Please enter your home address to find transit routes and times.",
        {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        }
      );
    }
  };
  const clearCart = (e) => {
    e.preventDefault();
    setJobCart([]);
    setDirections([]);
    setRenderRoutes(false);
    setTimes({});
    setAddresses([]);
    setVisibleList(false);
  };
  // const selectTransportation = (e) => {
  //   setTransitType(e.target.value);
  // };
  return (
    <div className="jobcart">
      {jobCart.length === 0 ? (
        <h4 className="jobcart_empty">No Jobs Currently Selected</h4>
      ) : (
        <div>
          {jobCart.map((job) => {
            return (
              <div key={job._id} className="jobcart_job">
                <div className="jobcart_job--seljob">
                  <h4
                    className="job_title"
                    onClick={() => {
                      setSelectedBiz(job);
                      setBusiness(job);
                      setViewModal(true);
                    }}
                  >
                    {job.name}
                  </h4>
                  {job.commuteLength && (
                    <p className="job_time">
                      Commute Time: {job.commuteLength.duration.text}{" "}
                    </p>
                  )}
                </div>
                {!visibleList && (
                  <FontAwesomeIcon
                    onClick={(e) => {
                      removeJob(job);
                    }}
                    icon={faTrash}
                  />
                )}
              </div>
            );
          })}
          {/* {!visibleList && (
            <div className="user__info--transportation">
              <h4>How will you travel?</h4>
              <div className="user__info--transportation-options">
                <RadioButton
                  className=""
                  name="transit"
                  value="DRIVING"
                  htmlFor="Car"
                  onChange={selectTransportation}
                  defaultChecked="true"
                />
                <RadioButton
                  name="transit"
                  value="TRANSIT"
                  htmlFor="Bus"
                  onChange={selectTransportation}
                />
              </div>
            </div>
          )} */}

          <div className="btn_row">
            <button className="btn btn_job" onClick={findCommuteTimes}>
              Find Commute Length
            </button>
            <button className="btn btn_job" onClick={clearCart}>
              Remove All
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobCart;

import {
  FETCH_EDU_SERVICES_START,
  FETCH_EDU_SERVICES_SUCCESS,
  FETCH_EDU_SERVICES_FAILURE,
  FETCH_CHILD_SERVICES_FAILURE,
  FETCH_CHILD_SERVICES_SUCCESS,
  FETCH_CHILD_SERVICES_START,
  FETCH_FOOD_SERVICES_FAILURE,
  FETCH_FOOD_SERVICES_SUCCESS,
  FETCH_FOOD_SERVICES_START,
  FETCH_SOC_SERVICES_FAILURE,
  FETCH_SOC_SERVICES_START,
  FETCH_SOC_SERVICES_SUCCESS,
  FETCH_JOBS_START,
  FETCH_JOBS_FAILURE,
  FETCH_JOBS_SUCCESS,
  SET_TRANSIT,
} from "../actions";


const initialState = {
  eduSvcs: [],
  foodSvcs: [],
  childSvcs: [],
  socSvcs: [],
  jobs: [],
  initialTransit: [],
  isLoading: false,
  err: "",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EDU_SERVICES_START:
      return { ...state, isFetching: true, err: "" };
    case FETCH_EDU_SERVICES_SUCCESS:
      return { ...state, isFetching: false, eduSvcs: action.payload };
    case FETCH_EDU_SERVICES_FAILURE:
      return { ...state, isFetching: false, err: action.payload };
    case FETCH_CHILD_SERVICES_START:
      return { ...state, isFetching: true, err: "" };
    case FETCH_CHILD_SERVICES_SUCCESS:
      return { ...state, isFetching: false, childSvcs: action.payload };
    case FETCH_CHILD_SERVICES_FAILURE:
      return { ...state, isFetching: false, err: action.payload };
    case FETCH_FOOD_SERVICES_START:
      return { ...state, isFetching: true, err: "" };
    case FETCH_FOOD_SERVICES_SUCCESS:
      return { ...state, isFetching: false, foodSvcs: action.payload };
    case FETCH_FOOD_SERVICES_FAILURE:
      return { ...state, isFetching: false, err: action.payload };
    case FETCH_SOC_SERVICES_START:
      return { ...state, isFetching: true, err: "" };
    case FETCH_SOC_SERVICES_SUCCESS:
      return { ...state, isFetching: false, socSvcs: action.payload };
    case FETCH_SOC_SERVICES_FAILURE:
      return { ...state, isFetching: false, err: action.payload };
    case FETCH_JOBS_START:
      return { ...state, isFetching: true, err: "" };
    case FETCH_JOBS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        jobs: action.payload
      };
    case FETCH_JOBS_FAILURE:
      return { ...state, isFetching: false, err: action.payload };
    case SET_TRANSIT:
      return { ...state, initialTransit: action.payload };
    default:
      return state;
  }
};

export const getDistanceFromLatLon = (
  latitude1,
  longitude1,
  latitude2,
  longitude2
) => {
  const p = 0.017453292519943295; //This is  Math.PI / 180
  const c = Math.cos;
  const a =
    0.5 -
    c((latitude2 - latitude1) * p) / 2 +
    (c(latitude1 * p) *
      c(latitude2 * p) *
      (1 - c((longitude2 - longitude1) * p))) /
      2;
  const R = 6371; //  Earth distance in km so it will return the distance in km
  const dist = 2 * R * Math.asin(Math.sqrt(a));
  const miles = dist / 1.609;
  return miles;
};

export const routeColors = {
  101: "#7fcdbb",
  102: "#41b6c4",
  103: "#1d91c0",
  104: "#225ea8",
  106: "#253494",
  107: "#081d58",
  113: "#feb24c",
  115: "#fd8d3c",
  116: "#fc4e2a",
  118: "#e31a1c",
  402: "#bd0026",
  595: "#800026",
};

import React, { useState, useEffect } from "react";
import RadioButton from "./RadioButton";
import Employer from "./Employer";
import { circleRadii, getDistanceFromLatLon } from "../utils";

const EmployerList = ({
  employers,
  setBusiness,
  setSelectedBiz,
  setViewModal,
  homeMarker,
  jobs,
  setEmployers,
}) => {
  const [search, setSearch] = useState("");

  const filterDistance = (e) => {
    let locArr = [];
    if (homeMarker) {
      jobs.forEach((b) => {
        if (
          getDistanceFromLatLon(
            homeMarker.lat,
            homeMarker.lng,
            b.location.lat,
            b.location.lng
          ) <= e.target.value
        ) {
          locArr.push(b);
        }
      });
      setEmployers(locArr);
    }
  };

  useEffect(() => {
    setEmployers(jobs);
  }, [jobs]);

  let filteredEmployers = employers.filter(
    (emp) => emp.description.toLowerCase().indexOf(search.toLowerCase()) !== -1
  );

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <div className="radius-filter">
        {circleRadii.map((r, i) => {
          return (
            <RadioButton
              key={i}
              type="radio"
              name="radius"
              htmlFor={r.name}
              value={r.value}
              onChange={filterDistance}
            ></RadioButton>
          );
        })}
      </div>
      <input
        className="input input__list"
        name="home"
        value={search}
        onChange={handleSearch}
        placeholder="Search jobs by keyword"
      ></input>
      {filteredEmployers.length === 0 ? (
        <h3>No jobs fit these parameters</h3>
      ) : (
        <>
            {filteredEmployers.map((e) => {
              return (
                <Employer
                  key={e._id}
                  employer={e}
                  setSelectedBiz={setSelectedBiz}
                  setBusiness={setBusiness}
                  setViewModal={setViewModal}
                />
              );
            })}
        </>
      )}
    </>
  );
};

export default EmployerList;

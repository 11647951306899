import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Modal = ({
  setViewModal,
  setSelectedBiz,
  setBusinessAddress,
  selectedBiz,
  setIsFetching,
  selectJob,
  visibleList,
}) => {
  // If user clicks on address, have an on click function that sets state to the address
  // The business address and home address are plugged into the Google Directions API call to get a route
  // Render that route to the map through the App component

  const addToList = (e) => {
    e.preventDefault();
    selectJob(selectedBiz);
    if (selectedBiz.valid) {
      setBusinessAddress(selectedBiz.address);
      setIsFetching(true);
    }
  };

  const close = () => {
    setViewModal(false);
    setSelectedBiz(null);
  };

  return (
    <div className="modal">
      <FontAwesomeIcon
        className="modal--close"
        onClick={close}
        icon={faTimes}
      />
      <div>
        <h1>{selectedBiz.name}</h1>
        {!selectedBiz.valid && (
          <div className="invalidaddress">
            This may be an invalid address. Please contact employer or look
            through job decription to find address.
          </div>
        )}
        <div dangerouslySetInnerHTML={{ __html: selectedBiz.description }} />
        <a href={selectedBiz.url} target="_blank" rel="noopener noreferrer">
          {selectedBiz.url}
        </a>
        <p className="address">{selectedBiz.address}</p>

        {!visibleList && (
          <button className="btn  btn--block" onClick={addToList}>
            Add to Job List
          </button>
        )}
        <button className="btn btn--block" onClick={close}>
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;

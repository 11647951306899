import React, { useState } from "react";
import axios from "axios";
import RadioButton from "./RadioButton";

const AddressModal = ({
  homeAddress,
  setHomeAddress,
  setHomeMarker,
  setTransitType,
  toast,
  toastError,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .get(
        `https://www.mapquestapi.com/geocoding/v1/address?key=${process.env.REACT_APP_GEOCODE_API_KEY}&location=${homeAddress}`
      )
      .then((res) => {
        const results = res.data.results[0].locations[0].latLng;
        if (
          results.lat < 38.7 ||
          results.lat > 39.5 ||
          results.lng < -95.5 ||
          results.lng > -94
        ) {
          toast.error(
            "Invalid entry. Please enter a valid address, city, and state.",
            toastError
          );
        } else {
          setHomeMarker(res.data.results[0].locations[0].latLng);
          setIsVisible(false);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(
          "Invalid entry. Please enter a valid address, city, and state.",
          toastError
        );
      });
  };
  const handleChange = (e) => {
    setHomeAddress(e.target.value);
  };
  const selectTransportation = (e) => {
    setTransitType(e.target.value);
  };
  return (
    isVisible && (
      <div className="address_modal">
        <h1 className="address_modal--title">
          To get started, please enter your address
        </h1>
        <form className="address_modal--form" onSubmit={handleSubmit}>
          <input
            className="input"
            name="home"
            value={homeAddress}
            onChange={handleChange}
            placeholder="Enter Home Address"
          ></input>
          <button className="btn" type="submit">
            go
          </button>
        </form>
        <div className="user__info--transportation">
          <h4>How will you travel?</h4>
          <div className="user__info--transportation-options">
            <RadioButton
              className=""
              name="transit"
              value="DRIVING"
              htmlFor="Car"
              onChange={selectTransportation}
              defaultChecked="true"
            />
            <RadioButton
              name="transit"
              value="TRANSIT"
              htmlFor="Bus"
              onChange={selectTransportation}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default AddressModal;

import React from "react";

const RadioButton = (props) => {
  return (
    <label className="rad-btn" htmlFor={props.name}>
      <input type="radio" {...props} />
      {props.htmlFor}
    </label>
  );
};

export default RadioButton;

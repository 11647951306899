import React from "react";

const Burger = ({ open, setOpen }) => {
  const handleClick = (e) => {
    e.preventDefault();
    setOpen(!open);
  };
  return (
    <button
      className={`burger ${open ? "burger--open" : ""}`}
      onClick={handleClick}
      type="button"
      aria-label="Menu"
      aria-controls="menu"
    >
      <span className="burger__box">
        <span className="burger__line" />
      </span>
      <span className="burger__label">menu</span>
    </button>
  );
};

export default Burger;

export const invalidAddresses = [
  "kansas city, ks 66102",
  "basehor, ks 66102",
  "kansas city, usa, ks, kansas city, ks 66118",
  "9135 barton, kansas city, ks 66101",
  "company worksite, edwardsville, ks 66111",
  "kansas city, ks, kansas city ks 66118",
  "bonner springs, ks 66012",
  "st. joseph, mo 66102",
  "edwardsville, ks 66113",
  "kansas city, mo 66102",
  "company worksite, kansas city, ks 66102",
  "see job description for address, kansas city, ks 66102",
  "roeland park, kansas, roeland park, ks 66103",
  "roeland park, us, ks, roeland park, ks 66103",
  "apply online, kansas city, ks 66101",
  "overland park, ks 66102"
];

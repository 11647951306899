import React, { useState, useEffect } from "react";

const Checkbox = (props) => {
  const [numJobs, setNumJobs] = useState(0);
  useEffect(() => {
    if (props.employers) {
      let jobArr = props.employers.filter(
        (e) => e.category === props.children.toLowerCase().trim()
      );
      setNumJobs(jobArr.length);
    }
  }, []);
  return (
    <div>
      <label className="checkbox">
        <span className="checkbox__input">
          <input
            type="checkbox"
            id={props.children}
            value={props.value}
            onChange={props.onChange}
            defaultChecked={props.defaultChecked}
          />
          <span className="checkbox__control">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
              <path fill='none' stroke='currentColor' strokeWidth='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' />
            </svg>
          </span>
        </span>
        <span className="checkbox__label">
          {props.employers ? (
            <label htmlFor={props.children}>
              {props.children} ({numJobs})
            </label>
          ) : (
            <label htmlFor={props.children}>{props.children}</label>
          )}
        </span>
      </label>
    </div>
  );
};

export default Checkbox;
